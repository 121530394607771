// Chakra Imports
import {
  FaUndo,
  FaEraser,
  FaTimes,
  FaBrush,
  FaDrawPolygon,
  FaWindowMinimize,
  FaDelicious,
  FaCheck,
} from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Button,
  HStack,
  Tooltip,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useToast,
} from "@chakra-ui/react";

// Component Imports
import MaskedImageWrapper from "./MaskedImageWrapper";
import { maskRegionColors } from "../../../constants/Workflow";
import { defaultThemeColor, greyBgColor } from "../../../constants";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import {
  convertBasicRLEToRawData,
  convertCocolibRLEToBasicRLE,
  cloneAndScaleContext,
} from "../ProjectManagement/ImageWithMasksUtils";

// Api Services
import { fetchImageMask } from "../../../services/businessServices";

function ImageWithMasks({
  masks,
  setMasks,
  setImageDimensions,
  ctxRefs,
  imgUrl,
  onDataFromChild,
  handleUndo,
}) {
  const dispatch = useDispatch();
  const toast = useToast();
  const divRef = useRef(null);
  const polygonCtxRef = useRef(null);
  const canvasRefs = useRef(new Array());
  const segmentCtxRefs = useRef([]);
  const [currentMaskIndex, setCurrentMaskIndex] = useState(0);
  const [drawMaskMode, setDrawMaskMode] = useState(false);
  const [isDrawing, setIsDrawing] = useState(false);
  const [lineWidth, setLineWidth] = useState(50);
  const [lineOpacity, setLineOpacity] = useState(1.0);
  const [opacityValue, setOpacityValue] = useState(0.5);
  const [lockAspectRatioFlag, setLockAspectRatioFlag] = useState(true);
  const [rotateImageValue, setRotateValue] = useState(null);
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [eraserMode, setEraserMode] = useState(false);
  const workflowDetails = useSelector((store) => store.workflowDesignHeader);
  const workflowDetailsData = workflowDetails?.workflowObject;
  const [canvasWidth, setCanvasWidth] = useState(300);
  const [canvasHeight, setCanvasHeight] = useState(100);
  const [sourceWidth, setSourceWidth] = useState(300);
  const [sourceHeight, setSourceHeight] = useState(100);
  const [loading, isLoading] = useState(false);

  // Polygon variables
  const [polygons, setPolygons] = useState((masks || [[]]).map(() => []));
  const [polygonMasking, setPolygonMasking] = useState(false);

  useEffect(() => {
    if (workflowDetails?.workflowObject?.maskData?.length === 0) {
      ctxRefs.current.forEach((item, index) => {
        ctxRefs.current[index].clearRect(0, 0, canvasWidth, canvasHeight);
      });
    }
  }, [workflowDetails?.workflowObject?.maskData]);

  const [selectedSegment, setSelectedSegment] = useState(null);
  const [segmentMasks, setSegmentMasks] = useState([]);
  const [segmentMasksData, setSegmentMasksData] = useState([]);

  useEffect(() => {
    if (segmentMasksData.length > 0) {
      const rawImageCanvases = segmentMasksData.map((data, index) => {
        if (!data) {
          return null;
        }
        return convertBasicRLEToRawData(
          convertCocolibRLEToBasicRLE(data.counts),
          Math.round(data.size[0]),
          Math.round(data.size[1]),
          segmentMasks[index].color
        );
      });
      segmentCtxRefs.current.map((context, index) => {
        if (!rawImageCanvases[index]) {
          return context;
        } else {
          context.putImageData(
            cloneAndScaleContext(
              rawImageCanvases[index],
              Math.round(context.canvas.width) / rawImageCanvases[index].width
            )?.getImageData(
              0,
              0,
              Math.round(context.canvas.width),
              Math.round(context.canvas.height)
            ),
            0,
            0
          );
        }
      });
    }
  }, [segmentMasksData, segmentMasks]);

  useEffect(() => {
    if (selectedSegment !== null && segmentMasks.length > selectedSegment) {
      changeSegmentCanvasColor(selectedSegment, masks[currentMaskIndex].color);
    }
  }, [selectedSegment, currentMaskIndex]);

  // Todo:Clear Mask Data When New Workflow Selected
  useEffect(() => {
    ctxRefs.current.forEach((item, index) => {
      clearMaskCanvas(index);
    });
    setPolygons(masks.map(() => []));
    setPolygonMasking(false);
    setEraserMode(false);
    setDrawMaskMode(false);
  }, [workflowDetailsData?.workflow_uuid]);

  const toggleDrawMaskMode = () => {
    setDrawMaskMode(!drawMaskMode);
    setPolygons(masks.map(() => []));
    setPolygonMasking(false);
    setEraserMode(false);
  };

  // Function to toggle the eraser mode
  const toggleEraserMode = () => {
    setEraserMode(!eraserMode);
  };

  const togglePolygonMode = () => {
    setPolygonMasking(!polygonMasking);
    setPolygons(masks.map(() => []));
  };

  // Function for starting the drawing
  const startDrawing = (index, e) => {
    e.preventDefault();
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    ctxRefs.current[index].beginPath();
    ctxRefs.current[index].moveTo(x, y);
    setIsDrawing(true);
  };

  // Function for ending the drawing
  const endDrawing = (index, e) => {
    e.preventDefault();
    ctxRefs.current[index].closePath();
    setIsDrawing(false);
    onDataFromChild(index);
  };

  const invertMaskCanvas = (index) => {
    ctxRefs.current[index].globalCompositeOperation = "source-out";
    ctxRefs.current[index].fillStyle = masks[index].color;
    ctxRefs.current[index].fillRect(0, 0, canvasWidth, canvasHeight);
    onDataFromChild(index);
  };

  const clearMaskCanvas = (index) => {
    ctxRefs.current[index].clearRect(0, 0, canvasWidth, canvasHeight);
    onDataFromChild(index);
  };

  //  Todo:Draw Mask And Erase Mask From Canvas
  const draw = (index, e) => {
    e.preventDefault();
    if (!isDrawing) {
      return;
    }

    ctxRefs.current[index].lineCap = "round";
    ctxRefs.current[index].lineJoin = "round";
    ctxRefs.current[index].globalAlpha = lineOpacity;

    if (eraserMode) {
      ctxRefs.current[index].globalCompositeOperation = "destination-out"; // Set composite operation to erase
      ctxRefs.current[index].lineWidth = lineWidth; // Increase line width for eraser effect
    } else {
      ctxRefs.current[index].globalCompositeOperation = "source-over"; // Set default composite operation
      ctxRefs.current[index].strokeStyle = masks[index].color;
      ctxRefs.current[index].lineWidth = lineWidth; // Increase line width for mask brush
    }

    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    ctxRefs.current[index].lineTo(x, y);
    ctxRefs.current[index].stroke();
  };

  const handleMaskSelect = (index) => {
    clearPolygonData(currentMaskIndex);
    setCurrentMaskIndex(index);
  };

  const handleImageLoad = (e) => {
    if (divRef.current) {
      const { width, height } = divRef.current.getBoundingClientRect();
      setCanvasWidth(width);
      setCanvasHeight(height);
      const { naturalWidth, naturalHeight } = e.target;
      setSourceWidth(naturalWidth);
      setSourceHeight(naturalHeight);
      setImageDimensions({ width: naturalWidth, height: naturalHeight });
    }
  };

  useEffect(() => {
    // Update canvas width and height after the component mounts and whenever the window resizes
    const updateCanvasDimensions = () => {
      if (divRef.current) {
        const { width, height } = divRef.current.getBoundingClientRect();
        setCanvasWidth(width);
        setCanvasHeight(height);
      }
    };

    updateCanvasDimensions();
    window.addEventListener("resize", updateCanvasDimensions);
    return () => {
      window.removeEventListener("resize", updateCanvasDimensions);
    };
  }, []);

  const drawPolygonPreview = (e, canvasIndex) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const points = polygons[canvasIndex] || [];
    // Draw polygon
    if (polygonCtxRef.current) {
      polygonCtxRef.current.clearRect(0, 0, canvasWidth, canvasHeight);
      polygonCtxRef.current.lineWidth = 1;
      polygonCtxRef.current.lineOpacity = lineOpacity;
      polygonCtxRef.current.globalAlpha = lineOpacity;
      polygonCtxRef.current.globalCompositeOperation = "source-over"; // Set default composite operation
      polygonCtxRef.current.fillStyle = "black";
      polygonCtxRef.current.strokeStyle = "black";
      for (let i = 0; i < points.length; i++) {
        polygonCtxRef.current.beginPath();
        polygonCtxRef.current.arc(points[i].x, points[i].y, 2, 0, Math.PI * 2);
        i === 0 ? polygonCtxRef.current.fill() : polygonCtxRef.current.stroke();
      }

      if (points.length > 0) {
        polygonCtxRef.current.beginPath();
        polygonCtxRef.current.moveTo(points[0].x, points[0].y);
        for (let i = 1; i < points.length; i++) {
          polygonCtxRef.current.lineTo(points[i].x, points[i].y);
        }
        if (
          (points[0].x - x) * (points[0].x - x) +
            (points[0].y - y) * (points[0].y - y) >
          16
        ) {
          polygonCtxRef.current.lineTo(x, y);
        }
        polygonCtxRef.current.closePath();

        polygonCtxRef.current.lineWidth = 1;
        polygonCtxRef.current.lineOpacity = lineOpacity;
        polygonCtxRef.current.globalCompositeOperation = "source-over"; // Set default composite operation
        polygonCtxRef.current.strokeStyle = "black";
        polygonCtxRef.current.stroke();
      }
    }
  };

  const clearPolygonData = (index) => {
    let polygon = [...polygons];
    polygon[index] = [];
    if (polygonCtxRef.current) {
      polygonCtxRef.current.clearRect(0, 0, canvasWidth, canvasHeight);
    }
    setPolygons(polygon);
  };

  const addPolygonPoint = (e, canvasIndex) => {
    e.preventDefault();
    const rect = divRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    const points = polygons[canvasIndex];
    if (
      points.length > 1 &&
      (points[0].x - x) * (points[0].x - x) +
        (points[0].y - y) * (points[0].y - y) <=
        16
    ) {
      completePolygon(canvasIndex);
    } else {
      const updatedPolygons = [...polygons];
      updatedPolygons[canvasIndex] = [...points, { x, y }];
      setPolygons(updatedPolygons);
    }
  };

  const completePolygon = (index) => {
    const points = polygons[index];
    if (points.length >= 3) {
      ctxRefs.current[index].beginPath();
      ctxRefs.current[index].moveTo(points[0].x, points[0].y);
      for (let i = 1; i < points.length; i++) {
        ctxRefs.current[index].lineTo(points[i].x, points[i].y);
      }
      ctxRefs.current[index].closePath();

      ctxRefs.current[index].lineCap = "round";
      ctxRefs.current[index].lineJoin = "round";
      ctxRefs.current[index].globalAlpha = lineOpacity;
      ctxRefs.current[index].lineWidth = 0;

      if (eraserMode) {
        ctxRefs.current[index].globalCompositeOperation = "destination-out"; // Set composite operation to erase
      } else {
        ctxRefs.current[index].globalCompositeOperation = "source-over"; // Set default composite operation
        ctxRefs.current[index].fillStyle = masks[index].color;
      }

      ctxRefs.current[index].fill();
    }
    clearPolygonData(index);
    onDataFromChild(index);
  };

  const segmentImage = () => {
    isLoading(true);
    fetchImageMask({
      objectKey: workflowDetailsData?.object_name,
    })
      .then((res) => {
        const masksRles = JSON.parse(res[0]?.json)?.rle_masks;
        setSegmentMasks(
          masksRles.map((_, index) => ({
            name: `mask${maskRegionColors.length + index + 1}`,
            color: "purple",
          }))
        );
        setSegmentMasksData(masksRles);
      })
      .catch((err) => {
        if (err) {
          toast(toastFunctionToaster("Auto Segmentation Failed", "error"));
        }
      })
      .finally(() => {
        isLoading(false);
      });
  };

  const clearSegmentMasks = () => {
    setSegmentMasks([]);
    setSegmentMasksData([]);
    setSelectedSegment(null);
  };

  const changeSegmentCanvasColor = (index, newColor) => {
    let ctx = segmentCtxRefs.current[index];
    const canvasData = ctx?.getImageData(
      0,
      0,
      ctx.canvas.width,
      ctx.canvas.height
    );
    const pixels = canvasData.data;

    let newCanvas = document.createElement("canvas");
    newCanvas.width = 1;
    newCanvas.height = 1;
    let newContext = newCanvas.getContext("2d");
    newContext.fillStyle = newColor;
    newContext.fillRect(0, 0, 1, 1);
    const newColorData = newContext?.getImageData(0, 0, 1, 1).data;

    for (let i = 0; i < pixels.length; i += 4) {
      if (pixels[i + 3] > 0) {
        for (let j = 0; j < 4; j++) {
          pixels[i + j] = newColorData[j];
        }
      }
    }

    // Update the canvas with the new pixel data
    ctx.putImageData(canvasData, 0, 0);
  };

  const addSelectedSegmentToCanvas = (destIndex) => {
    const segmentCtx = segmentCtxRefs?.current[selectedSegment];
    const segmentCanvasData = segmentCtx?.getImageData(
      0,
      0,
      segmentCtx.canvas.width,
      segmentCtx.canvas.height
    ).data;

    const destCtx = ctxRefs.current[destIndex];
    const destCanvasData = destCtx?.getImageData(
      0,
      0,
      segmentCtx.canvas.width,
      segmentCtx.canvas.height
    );
    const destArr = destCanvasData.data;

    for (let i = 0; i < destArr.length; i += 4) {
      if (segmentCanvasData[i + 3] > 0) {
        for (let j = 0; j < 4; j++) {
          destArr[i + j] = segmentCanvasData[i + j];
        }
      }
    }

    // Update the canvas with the new pixel data
    destCtx.putImageData(destCanvasData, 0, 0);
    onDataFromChild(destIndex);
  };

  const selectCursorSegment = (e) => {
    e.preventDefault();
    const canvas = e.target;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    // Check if selected segment is still valid first
    if (selectedSegment !== null) {
      const selectedCtx = segmentCtxRefs.current[selectedSegment];
      const selectedCanvasData = selectedCtx?.getImageData(x, y, 1, 1).data;
      if (selectedCanvasData[3] > 0) {
        return;
      }
    }

    for (let index = 0; index < segmentCtxRefs.current.length; index++) {
      const ctx = segmentCtxRefs.current[index];
      const canvasData = ctx?.getImageData(x, y, 1, 1).data;
      const isFilled = canvasData[3] > 0; // Check if the alpha value is greater than 0
      if (isFilled) {
        if (selectedSegment !== null) {
          changeSegmentCanvasColor(
            selectedSegment,
            segmentMasks[selectedSegment].color
          );
        }
        setSelectedSegment(index);
        return;
      }
    }
    if (selectedSegment !== null) {
      changeSegmentCanvasColor(
        selectedSegment,
        segmentMasks[selectedSegment].color
      );
    }
    setSelectedSegment(null);
  };

  // Todo:Undo Masking
  const undoMasking = (value) => {
    handleUndo(currentMaskIndex, value);
  };

  // Todo:Undo Mask By Keyboard Events (ctrl+Z || ctrl+Y)
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.key === "z") {
        event.preventDefault();
        handleUndo(currentMaskIndex, "undo");
      } else if (event.ctrlKey && event.key === "y") {
        event.preventDefault();
        handleUndo(currentMaskIndex, "redo");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Cleanup
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentMaskIndex, handleUndo]);

  return (
    <Box position="relative" w={"100%"} h="100%">
      <Box
        className="draw-area"
        style={{ position: "relative", width: "fit-content" }}
        h="90%"
        mx="auto"
        ref={divRef}
        overflow={"hidden"}
      >
        <img
          alt="Source"
          src={imgUrl}
          onLoad={handleImageLoad}
          style={{
            width: `100%`,
            height: `100%`,
            userSelect: "none",
            userdrag: "none",
            pointerEvents: "none",
          }}
        />
        {workflowDetailsData?.modelEntity === "inpaint_controlnet" &&
        workflowDetails?.superImposeFlag &&
        workflowDetailsData?.control_guidance_image?.length ? (
          <Box position={"absolute"} top="0" left="0" h="100%" w="100%">
            <MaskedImageWrapper
              url={workflowDetailsData?.control_guidance_image}
              opacity={opacityValue}
              drawMaskMode={drawMaskMode}
              canvasHeight={canvasHeight}
              canvasWidth={canvasWidth}
              sourceHeight={sourceHeight}
              sourceWidth={sourceWidth}
              rotateImage={rotateImageValue}
              lockAspectRatio={lockAspectRatioFlag}
            />
          </Box>
        ) : null}
        {masks.map((mask, index) => (
          <canvas
            key={mask.name}
            ref={(element) => {
              if (element) {
                canvasRefs.current[index] = element;
                ctxRefs.current[index] = element.getContext("2d");
              }
            }}
            onPointerDown={(e) => {
              if (segmentMasks.length === 0 && !polygonMasking) {
                startDrawing(currentMaskIndex, e);
              }
            }}
            onPointerUp={(e) => {
              if (segmentMasks.length > 0) {
                addSelectedSegmentToCanvas(currentMaskIndex);
              } else if (polygonMasking) {
                addPolygonPoint(e, currentMaskIndex);
              } else {
                endDrawing(currentMaskIndex, e);
              }
            }}
            onPointerMove={(e) => {
              if (segmentMasks.length > 0) {
                selectCursorSegment(e);
              } else if (polygonMasking) {
                drawPolygonPreview(e, currentMaskIndex);
              } else {
                draw(currentMaskIndex, e);
              }
            }}
            onTouchStart={(e) => {
              if (segmentMasks.length === 0 && !polygonMasking) {
                startDrawing(currentMaskIndex, e);
              }
            }}
            onTouchMove={(e) => {
              if (segmentMasks.length > 0) {
                selectCursorSegment(e);
              } else if (polygonMasking) {
                drawPolygonPreview(e, currentMaskIndex);
              } else {
                draw(currentMaskIndex, e);
              }
            }}
            onTouchEnd={(e) => {
              if (segmentMasks.length > 0) {
                addSelectedSegmentToCanvas(currentMaskIndex);
              } else if (polygonMasking) {
                addPolygonPoint(e, currentMaskIndex);
              } else {
                endDrawing(currentMaskIndex, e);
              }
            }}
            width={`${canvasWidth}px`}
            height={`${canvasHeight}px`}
            style={{
              opacity: 0.5,
              position: "absolute",
              left: "0",
              top: "0",
              zIndex: index + 1,
              pointerEvents:
                index === currentMaskIndex &&
                (drawMaskMode || segmentMasks.length > 0)
                  ? "auto"
                  : "none",
              touchAction: "none",
            }}
          />
        ))}
        <canvas
          key="polygon"
          ref={(element) => {
            if (element) {
              polygonCtxRef.current = element.getContext("2d");
            }
          }}
          width={`${canvasWidth}px`}
          height={`${canvasHeight}px`}
          style={{
            opacity: 1,
            position: "absolute",
            left: "0",
            top: "0",
            zIndex: "0",
            pointerEvents: "none",
          }}
        />
        {segmentMasks.map((mask, index) => (
          <canvas
            key={mask.name}
            ref={(element) => {
              if (element) {
                segmentCtxRefs.current[index] = element.getContext("2d");
              }
            }}
            width={`${canvasWidth}px`}
            height={`${canvasHeight}px`}
            style={{
              opacity: 0.5,
              position: "absolute",
              left: "0",
              top: "0",
              zIndex: index + maskRegionColors.length + 1,
              pointerEvents: "none",
            }}
          />
        ))}
      </Box>
      <HStack
        justifyContent={"space-between"}
        h="10%"
        width={"100%"}
        bg={greyBgColor}
      >
        <Flex alignItems={"center"}>
          <Text
            fontSize={"12px"}
            color="#fff"
            justifyContent="left"
            fontWeight={"600"}
            p="1"
            w="100px"
          >
            {sourceWidth} x {sourceHeight}
          </Text>
        </Flex>
        {segmentMasks.length > 0 ? (
          <Box p={1} w="20%">
            <Flex alignItems={"left"}>
              {masks.map((mask, index) => (
                <Tooltip
                  label={`Select ${mask.color} Mask`}
                  textTransform={"capitalize"}
                >
                  <Button
                    size={"xs"}
                    mr="1"
                    colorScheme={mask.color}
                    key={index + currentMaskIndex}
                    onClick={() => {
                      handleMaskSelect(index);
                    }}
                  >
                    <Text fontSize={"2xl"}>
                      {currentMaskIndex === index ? "*" : ""}
                    </Text>
                  </Button>
                </Tooltip>
              ))}
            </Flex>
          </Box>
        ) : null}
        {workflowDetailsData?.modelEntity === "inpaint_controlnet" &&
        workflowDetails?.superImposeFlag &&
        workflowDetailsData?.control_guidance_image_name?.length ? (
          <Box p={1} w={drawMaskMode ? "30%" : "80%"}>
            <Flex alignItems={"center"}>
              <Flex
                w={drawMaskMode ? "100%" : "50%"}
                ml="2"
                alignItems={"center"}
              >
                <Text color="#fff" w="50%">
                  Opacity
                </Text>
                <Slider
                  ml="2"
                  aria-label="slider-ex-1"
                  defaultValue={opacityValue}
                  min={0}
                  max={1.0}
                  step={0.1}
                  onChange={(val) => setOpacityValue(val)}
                >
                  <SliderTrack>
                    <SliderFilledTrack />
                  </SliderTrack>
                  <SliderThumb />
                </Slider>
              </Flex>
              {drawMaskMode ? null : (
                <>
                  <Flex w="50%" ml="2" alignItems={"center"}>
                    <Text color="#fff" w="50%">
                      Rotation
                    </Text>
                    <Slider
                      ml="2"
                      aria-label="slider-rotate-value"
                      defaultValue={rotateImageValue}
                      min={0}
                      max={360}
                      step={0.1}
                      onChange={(val) => setRotateValue(val)}
                    >
                      <SliderTrack>
                        <SliderFilledTrack />
                      </SliderTrack>
                      <SliderThumb />
                    </Slider>
                  </Flex>
                  <Flex w="35%" ml="2" alignItems={"center"}>
                    <Button
                      aria-label="button-lock-aspect-ratio"
                      size={"xs"}
                      colorScheme="blue"
                      onClick={(e) => {
                        setLockAspectRatioFlag(!lockAspectRatioFlag);
                      }}
                    >
                      {lockAspectRatioFlag ? "Unlock " : "Lock "}
                      Aspect Ratio
                    </Button>
                  </Flex>
                  <Flex w="15%" ml="1" alignItems={"center"}>
                    <Button
                      aria-label="button-reset-control-guidance"
                      size={"xs"}
                      colorScheme="teal"
                      onClick={(e) => {
                        setRotateValue(0);
                        const right = Math.round(
                          Math.min(
                            sourceWidth,
                            workflowDetailsData?.controlAspectRatio *
                              sourceHeight
                          )
                        );
                        const bottom = Math.round(
                          Math.min(
                            sourceHeight,
                            sourceWidth /
                              workflowDetailsData?.controlAspectRatio
                          )
                        );
                        dispatch({
                          type: "SET_WORKFLOW_OBJECT",
                          payload: {
                            key: "controlBoundingBox",
                            value: [0, 0, right, bottom],
                          },
                        });
                      }}
                    >
                      Reset
                    </Button>
                  </Flex>
                </>
              )}
            </Flex>
          </Box>
        ) : null}
        {drawMaskMode ? (
          <Box p={1} w="80%">
            <Flex alignItems={"center"}>
              {masks.map(
                (mask, index) =>
                  index <= 3 && (
                    <Tooltip
                      label={`Select ${mask.color} Mask`}
                      textTransform={"capitalize"}
                    >
                      <Button
                        size={"xs"}
                        mr="1"
                        colorScheme={mask.color}
                        key={index + currentMaskIndex}
                        onClick={() => {
                          handleMaskSelect(index);
                        }}
                      >
                        <Text fontSize={"2xl"}>
                          {currentMaskIndex === index ? "*" : ""}
                        </Text>
                      </Button>
                    </Tooltip>
                  )
              )}
              <Flex ml="2" w="75%">
                {polygonMasking ? (
                  <>
                    <Button
                      onClick={(e) => {
                        clearPolygonData(currentMaskIndex);
                      }}
                      size={"xs"}
                      colorScheme="purple"
                      mr="1"
                      isDisabled={!polygons[currentMaskIndex]?.length}
                    >
                      Reset Polygon
                    </Button>
                  </>
                ) : (
                  <>
                    {!eraserMode ? (
                      <Text color="#fff" w="60%">
                        Brush Size
                      </Text>
                    ) : null}
                    <Slider
                      id="slider"
                      defaultValue={lineWidth}
                      min={5}
                      max={100}
                      onChange={(v) => {
                        setLineWidth(v);
                      }}
                      mr="2"
                      onMouseEnter={() => setShowTooltip(true)}
                      onMouseLeave={() => setShowTooltip(false)}
                    >
                      <SliderTrack>
                        <SliderFilledTrack bg={defaultThemeColor} />
                      </SliderTrack>
                      <Tooltip
                        hasArrow
                        bg={defaultThemeColor}
                        color="white"
                        placement="top"
                        isOpen={showTooltip}
                        label={`${lineWidth}%`}
                      >
                        <SliderThumb />
                      </Tooltip>
                    </Slider>
                    {eraserMode ? (
                      <Text color="#fff" ml="1" w="60%">
                        Eraser Size
                      </Text>
                    ) : null}
                  </>
                )}
              </Flex>
              <Tooltip label="Toggle Add / Erase Mode">
                <Button
                  size={"xs"}
                  mr="1"
                  onClick={toggleEraserMode}
                  bg={eraserMode ? defaultThemeColor : "#d7d7d7"}
                >
                  <FaEraser />
                </Button>
              </Tooltip>
              <Tooltip label="Toggle Brush / Polygon Mode">
                <Button
                  size={"xs"}
                  mr="1"
                  onClick={togglePolygonMode}
                  colorScheme="orange"
                >
                  {polygonMasking ? <FaBrush /> : <FaDrawPolygon />}
                </Button>
              </Tooltip>
              <Tooltip label="Undo">
                <Button
                  size={"xs"}
                  mr="1"
                  onClick={() => {
                    undoMasking("undo");
                  }}
                  colorScheme="yellow"
                >
                  <FaUndo />
                </Button>
              </Tooltip>
              <Tooltip label="Redo">
                <Button
                  size={"xs"}
                  mr="1"
                  onClick={() => {
                    undoMasking("redo");
                  }}
                  colorScheme="blue"
                  style={{
                    transform: `rotateY(180deg)`,
                  }}
                >
                  <FaUndo />
                </Button>
              </Tooltip>
              <Tooltip label="Invert">
                <Button
                  size={"xs"}
                  mr="1"
                  onClick={(e) => {
                    invertMaskCanvas(currentMaskIndex);
                  }}
                  colorScheme="teal"
                >
                  <FaDelicious />
                </Button>
              </Tooltip>
              <Tooltip label="Clear">
                <Button
                  size={"xs"}
                  mr="1"
                  onClick={(e) => {
                    clearMaskCanvas(currentMaskIndex);
                  }}
                  colorScheme="red"
                >
                  <FaTimes />
                </Button>
              </Tooltip>
              <Tooltip label="Finish">
                <Button
                  size={"xs"}
                  mr="1"
                  onClick={toggleDrawMaskMode}
                  colorScheme="green"
                >
                  <FaWindowMinimize />
                </Button>
              </Tooltip>
            </Flex>
          </Box>
        ) : (
          <HStack
            w={segmentMasks.length > 0 ? "20%" : "40%"}
            justifyContent={"right"}
          >
            <Box>
              {segmentMasks.length > 0 ? (
                <Tooltip label="Confirm">
                  <Button
                    colorScheme="green"
                    size={"xs"}
                    m="1"
                    onClick={clearSegmentMasks}
                  >
                    <FaCheck />
                  </Button>
                </Tooltip>
              ) : (
                <>
                  <Button
                    colorScheme="green"
                    size={"xs"}
                    onClick={toggleDrawMaskMode}
                  >
                    Add Mask
                  </Button>
                  <Button
                    colorScheme="yellow"
                    size={"xs"}
                    m="1"
                    onClick={() => {
                      segmentImage();
                    }}
                    isDisabled={
                      loading ||
                      workflowDetailsData?.modelEntity === "text_to_image"
                    }
                    isLoading={loading}
                  >
                    Auto Segment
                  </Button>
                </>
              )}
            </Box>
          </HStack>
        )}
      </HStack>
    </Box>
  );
}

export default ImageWithMasks;
