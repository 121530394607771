import * as actionType from "../actionTypes";

const initialState = {
  workflowObject: {
    designType: "",
    modelEntity: "",
    project_uuid: "",
    resource_uuid: "",
    workflow_uuid: "",
    source_image: "",
    reference_image: ["", "", "", "", ""],
    reference_name: ["", "", "", "", ""],
    control_guidance_image: "",
    control_guidance_image_name: "",
    generate: 1,
    inferenceSteps: 4,
    guidanceScale: 1.2,
    imageSharpness: 0.002,
    guidanceType: "canny",
    conditionScale: 0.8,
    inpaintStrength: 1.0,
    maskCropPadding: 0.6,
    refAdainScale: 0.5,
    refAttnScale: 0.5,
    imageHeight: 1024,
    imageWidth: 1024,
    imageSeed: null,
    object_name: "",
    promptData: ["", "", "", "", ""],
    negativePrompt:
      "worst quality, lowres, warped, distorted, cropped, blurry, ugly",
    maskData: [],
    controlBoundingBox: null,
    controlAspectRatio: 1,
    negativeRefImage: "",
    previousGeneratedData: [],
    generatedImageCount: 1,
  },
  workflowImageData: [],
  workflowEngineLoader: false,
  workflowEngineQueue: 0,
  superImposeFlag: true,
  workflowImgCount: null,
  selectedImageType: "",
  lineDrawFlag: false,
  tabFlag: 0,
  currentSelectedImage: "",
  stabilityValue: false,
  copyToClip: "",
  designProjectObj: {
    projectId: "",
    currentPage: 0,
  },
};

const workflowDesignHeader = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SET_WORKFLOW_OBJECT: {
      return {
        ...state,
        workflowObject: {
          ...state.workflowObject,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case actionType.WORK_FLOW_IMAGE_DATA: {
      return {
        ...state,
        workflowImageData: action.value,
      };
    }

    case actionType.WORKFLOW_ENGINE_LOADER: {
      return {
        ...state,
        workflowEngineLoader: action.value,
      };
    }

    case actionType.WORKFLOW_ENGINE_QUEUE: {
      return {
        ...state,
        workflowEngineQueue: action.value,
      };
    }

    case actionType.REFERENCE_IMAGE_IMPOSE_FLAG: {
      return {
        ...state,
        superImposeFlag: action.value,
      };
    }

    case actionType.WORKFLOW_IMG_COUNTER: {
      return {
        ...state,
        workflowImgCount: action.value,
      };
    }

    case actionType.SELECTED_IMG_TYPE: {
      return {
        ...state,
        selectedImageType: action.value,
      };
    }

    case actionType.LINEDRAW_FLAG: {
      return {
        ...state,
        lineDrawFlag: action.value,
      };
    }

    case actionType.SELECTED_TAB_FLAG: {
      return {
        ...state,
        tabFlag: action.value,
      };
    }

    case actionType.SELECTED_CURRENT_IMAGE: {
      return {
        ...state,
        currentSelectedImage: action.value,
      };
    }

    case actionType.TEXT_IMAGE_STABILITY: {
      return {
        ...state,
        stabilityValue: action.value,
      };
    }

    case actionType.COPY_TO_CLIP: {
      return {
        ...state,
        copyToClip: action.value,
      };
    }

    case actionType.STORE_DESIGN_PROJECT_OBJ: {
      return {
        ...state,
        designProjectObj: action.value,
      };
    }

    default:
      return state;
  }
};
export default workflowDesignHeader;
