import React from "react";
import { AiOutlinePicRight } from "react-icons/ai";
import { Flex, Box, Text, Icon, Image } from "@chakra-ui/react";
import { greyBgColor } from "../../../constants";

function PromptResult({ imageUrl }) {
  return (
    <Box bg="#151117" h="100%" borderRadius={"10px"}>
      <Flex justifyContent="space-between" borderBottom="1px" w="100%" p="4">
        <Text color="#fff" fontWeight="500" fontSize="18px">
          Example Images
        </Text>
        <Icon
          w={8}
          h={8}
          cursor={"pointer"}
          color={"#fff"}
          as={AiOutlinePicRight}
          mr="10px"
        ></Icon>
      </Flex>
      <Box p="4">
        {imageUrl?.length
          ? <Image
            objectFit={"contain"}
            src={imageUrl}
            bg={greyBgColor}
            borderRadius={"10px"}
            height={"200px"}
            width="100%"
          ></Image>
          : <Flex
            height="200px"
            width="100%"
            bg={greyBgColor}
            borderRadius={"10px"}
            flexWrap={"wrap"}
            color="#fff"
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Text w="70%" textAlign={"center"}>
              {imageUrl === null ? "Please Select a Prompt" : "No Example Image Found"}
            </Text>
          </Flex>
        }
      </Box>
    </Box>
  );
}

export default PromptResult;
