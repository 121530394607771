// React Chakra Imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillLeftCircle, AiFillRightCircle } from "react-icons/ai";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Box,
  Flex,
  useToast,
  Button,
} from "@chakra-ui/react";

// Component Imports
import { defaultThemeColor, greyBgColor } from "../../../../../constants";
import {
  maskRegionColors,
  renderWorkflowTitle,
} from "../../../../../constants/Workflow";

import ProjectWorkspace from "../ProjectWrokspace/ProjectWorkspace";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import PreviousImageConfig from "../PreviousImageConfig/PreviousImageConfig";
import CurrentImageConfiguration from "./CurrentImageConf/CurrentImageConfiguration";

// Api Imports
import {
  getPlaygroundWorkflowInfo,
  getProjectActiveWorkflow,
} from "../../../../../services/projectTemplateService";
import GenerateAIImage from "../ProjectWrokspace/GenerateAIImage";
import { useLocation } from "react-router";
import LikedImage from "../LikedImage/LikedImage";
import LineDraw from "./LineDraw/LineDraw";
import { setTabFlag } from "../../../../../store/actions/workFlowAction";
import EditWorkflow from "../../../EditWorkflow/EditWorkflow";

function ProjectConfiguration(props) {
  // Todo:Pass project details to previous image component
  const projectDetails = props?.projectDetails;
  const modelDetails = projectDetails?.modelDetails;
  const parent_workflow_uuid = projectDetails?.parent_workflow_uuid;
  const enableLineDrawComponent = modelDetails?.modelCode === 3 ? true : false;
  const toast = useToast();
  const location = useLocation();
  const dispatch = useDispatch();
  const [lineFlag, setLineFlag] = useState(false);
  const randomPromptGeneratorArr = [
    "beautiful architecture, incredible design, highly detailed, best quality, photorealistic",
    "beautiful architecture, incredible design, best quality, highly detailed, photorealistic",
    "incredible design, beautiful architecture, highly detailed, best quality, photorealistic",
    "incredible design, beautiful architecture, best quality, highly detailed, photorealistic",
    "beautiful architecture, incredible design, professional drawing, highly detailed, best quality",
    "beautiful architecture, incredible design, professional drawing, best quality, highly detailed",
    "incredible design, beautiful architecture, professional drawing, highly detailed, best quality",
    "incredible design, beautiful architecture, professional drawing, best quality, highly detailed",
  ];
  const [projectId, setProjectId] = useState(
    projectDetails?.project_uuid ? projectDetails?.project_uuid : ""
  );
  const [workflowId, setWorkflowId] = useState("");
  const [resourceId, setResourceId] = useState(
    projectDetails?.resource_uuid ? projectDetails?.resource_uuid : ""
  );
  const currentWorkflowType = location?.pathname?.split("/")[2];
  const [workflowCreateFlag, setWorkflowCreateFlag] = useState(1);
  const [configurationCollapseFlag, setConfiguationCollapseFlag] =
    useState(true);

  // Todo:Fetch details from react store
  const workflowDetails = useSelector((store) => store?.workflowDesignHeader);
  const workflowData = workflowDetails?.workflowObject;
  const [activeTab, setActiveTab] = useState(
    workflowDetails?.tabFlag ? workflowDetails?.tabFlag : 0
  );
  const [workflowName, setWorkflowName] = useState(
    projectDetails?.workflow_name
  );

  useEffect(() => {
    setLineFlag(workflowDetails?.lineDrawFlag);
  }, [workflowDetails?.lineDrawFlag]);

  useEffect(() => {
    if (projectDetails?.workflow_uuid?.length) {
      setWorkflowId(projectDetails?.workflow_uuid);
    }
  }, [props]);

  useEffect(() => {
    if (workflowId) {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "workflow_uuid",
          value: workflowId,
        },
      });
    }
  }, [workflowId]);

  useEffect(() => {
    setActiveTab(workflowDetails?.tabFlag);
    dispatch(setTabFlag(activeTab));
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(workflowDetails?.tabFlag);
  }, [workflowDetails?.tabFlag]);

  // Todo:Create new workflow when source image is assigned if required.
  useEffect(() => {
    if (
      projectDetails?.launcher === "create" &&
      workflowCreateFlag === 1 &&
      (workflowData?.source_image?.length || modelDetails?.modelCode === 1) &&
      (currentWorkflowType === "designProject" || projectId)
    ) {
      setupNewWorkflow();
    }
  }, [workflowData?.source_image, workflowCreateFlag, projectId]);

  // Todo:When new workflow is selected dispatch details to react store.
  useEffect(() => {
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "project_uuid",
        value: projectId,
      },
    });
  }, [projectId]);

  useEffect(() => {
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "resource_uuid",
        value: resourceId,
      },
    });
  }, [resourceId]);

  useEffect(() => {
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "modelEntity",
        value: modelDetails?.modelEntity ? modelDetails?.modelEntity : "",
      },
    });
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "modelCode",
        value: modelDetails?.modelCode ? modelDetails?.modelCode : "",
      },
    });

    if (currentWorkflowType === "playgroundDesign") {
      playgroundWorkflowDetails();
    }

    if (projectDetails?.launcher === "create") {
      if (projectDetails?.object_name && projectDetails?.object_url) {
        setWorkflowCreateFlag(1);
        if (modelDetails?.modelCode === 1) {
          dispatch({
            type: "SET_WORKFLOW_OBJECT",
            payload: {
              key: "reference_name",
              value: ["", "", "", "", projectDetails?.object_name],
            },
          });
          dispatch({
            type: "SET_WORKFLOW_OBJECT",
            payload: {
              key: "reference_image",
              value: ["", "", "", "", projectDetails?.object_url],
            },
          });
        } else {
          dispatch({
            type: "SET_WORKFLOW_OBJECT",
            payload: {
              key: "object_name",
              value: projectDetails?.object_name,
            },
          });
          dispatch({
            type: "SET_WORKFLOW_OBJECT",
            payload: {
              key: "source_image",
              value: projectDetails?.object_url,
            },
          });
        }
        dispatch({
          type: "SET_WORKFLOW_OBJECT",
          payload: {
            key: "project_uuid",
            value: projectId,
          },
        });
        dispatch({
          type: "SET_WORKFLOW_OBJECT",
          payload: {
            key: "resource_uuid",
            value: resourceId,
          },
        });
      }
    } else if (projectDetails?.launcher === "relaunch") {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "source_image",
          value: projectDetails?.object_url ? projectDetails?.object_url : "",
        },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "object_name",
          value: projectDetails?.object_name,
        },
      });
    }

    const randomNumber = Math.floor(
      Math.random() * randomPromptGeneratorArr.length
    );
    if (
      modelDetails?.modelEntity === "controlnet" ||
      modelDetails?.modelEntity === "text_to_image"
    ) {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "promptData",
          value: [
            ...workflowData?.promptData.slice(0, maskRegionColors.length),
            randomPromptGeneratorArr[randomNumber],
          ],
        },
      });
    } else if (modelDetails?.modelCode === 5) {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "promptData",
          value: [
            "masterpiece, best quality, highres",
            ...workflowData?.promptData.slice(1),
          ],
        },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "inpaintStrength",
          value: 0.35,
        },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "conditionScale",
          value: 0.6,
        },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "inferenceSteps",
          value: 6,
        },
      });
    } else {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "promptData",
          value: [
            randomPromptGeneratorArr[randomNumber],
            ...workflowData?.promptData.slice(1),
          ],
        },
      });
    }

    if (modelDetails?.modelCode === 3) {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "guidanceType",
          value: "sketch",
        },
      });
    }
  }, [projectDetails]);

  // Todo:Create New Playground Details
  const playgroundWorkflowDetails = () => {
    if (projectDetails && modelDetails?.modelCode > 0) {
      let objBody = {
        project_uuid: "",
        workflow_uuid: "",
        object_info: "",
      };
      getPlaygroundWorkflowInfo(objBody)
        .then((res) => {
          if (res?.result && res?.data?.length > 0) {
            setProjectId(res?.data[0]?.project_uuid);
            setResourceId(res?.data[0]?.resource_uuid);
            toast(toastFunctionToaster(res.message, "success"));
          } else {
            toast(toastFunctionToaster(res.message, "error"));
            return false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
    }
  };

  // Todo:Call Play ground object api after playground is created
  const setupNewWorkflow = () => {
    setWorkflowCreateFlag(workflowCreateFlag + 1);
    let objBody = {
      project_uuid: projectId,
      workflow_state: "new",
      workflow_model_code: modelDetails?.modelCode,
      workflow_model_type: modelDetails?.modelEntity,
      object_info:
        modelDetails?.modelCode === 1
          ? "generateXobj"
          : workflowData?.object_name,
    };
    if (parent_workflow_uuid?.length) {
      objBody["parent_workflow_uuid"] = parent_workflow_uuid;
    }
    getProjectActiveWorkflow(objBody)
      .then((res) => {
        if (res?.result && res?.data?.length) {
          if (modelDetails?.modelCode === 1) {
            dispatch({
              type: "SET_WORKFLOW_OBJECT",
              payload: {
                key: "source_image",
                value: res?.data[0]?.object[0]?.url,
              },
            });
          }
          dispatch({
            type: "SET_WORKFLOW_OBJECT",
            payload: {
              key: "object_name",
              value: res?.data[0]?.object[0]?.object_name,
            },
          });
          setWorkflowId(res?.data[0]?.workflow_uuid);
          toast(toastFunctionToaster(res?.message, "success"));
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        // toast(toastFunctionToaster("Something Went Wrong", "error"));
      });
  };

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  // Todo:Handle Workflow Name
  const handleWorkflowName = (name) => {
    if (name) {
      setWorkflowName(name);
    } else {
      setWorkflowName(projectDetails?.workflow_name);
    }
  };

  return (
    <Tabs
      w="100%"
      borderBottom={"0px"}
      mx="auto"
      onChange={handleTabChange}
      index={activeTab}
    >
      <TabList border="0px" w="100%" h="5%">
        <Flex justifyContent={"space-between"} w="100%">
          <Flex>
            <Tab
              _selected={{
                color: defaultThemeColor,
                fontWeight: 600,
                borderBottomColor: defaultThemeColor,
              }}
              fontSize="16px"
              px="6"
              py="2"
              color="#fff"
            >
              Current Design
            </Tab>
            {workflowId?.length ? (
              <>
                <Tab
                  _selected={{
                    color: defaultThemeColor,
                    fontWeight: 600,
                    borderBottomColor: defaultThemeColor,
                  }}
                  isDisabled={workflowId?.length ? false : true}
                  fontSize="16px"
                  px="6"
                  py="2"
                  color="#fff"
                >
                  Previous Design
                </Tab>
                <Tab
                  _selected={{
                    color: defaultThemeColor,
                    fontWeight: 600,
                    borderBottomColor: defaultThemeColor,
                  }}
                  isDisabled={workflowId?.length ? false : true}
                  fontSize="16px"
                  px="6"
                  py="2"
                  color="#fff"
                >
                  Liked Images
                </Tab>
              </>
            ) : null}
            {enableLineDrawComponent && lineFlag ? (
              <Tab
                _selected={{
                  color: defaultThemeColor,
                  fontWeight: 600,
                  borderBottomColor: defaultThemeColor,
                }}
                fontSize="16px"
                px="6"
                py="2"
                color="#fff"
              >
                Line Draw
              </Tab>
            ) : null}
          </Flex>
          <Flex>
            <Flex
              p="2"
              px="4"
              mr="3"
              bg={"#151117"}
              borderRadius={"10px"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text color={"#fff"} fontSize={"14px"} mb="0" mr="2">
                Name:
                <Text
                  as="span"
                  ml="3"
                  color={defaultThemeColor}
                  fontWeight={"600"}
                >
                  {workflowName ? workflowName : "Untitled Workflow"}
                </Text>
              </Text>
              <EditWorkflow
                workflowData={workflowData}
                project_uuid={projectId}
                workflowName={workflowName}
                returnWorkflowName={handleWorkflowName}
                size="sm"
                returnName={true}
              />
            </Flex>
            <Flex
              p="2"
              px="4"
              bg={"#151117"}
              borderRadius={"10px"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <Text color={"#fff"} fontSize={"14px"} mb="0">
                Design Type:
                <Text
                  as="span"
                  ml="3"
                  color={defaultThemeColor}
                  fontWeight={"600"}
                >
                  {modelDetails?.subTitle ||
                    renderWorkflowTitle(
                      modelDetails.modelCode,
                      modelDetails.modelEntity
                    )}
                </Text>
              </Text>
            </Flex>
          </Flex>
          {}
        </Flex>
      </TabList>
      <TabPanels mt="1%" h="94%">
        <TabPanel p="0" h="100%">
          <Flex h="100%">
            <Box
              w={!configurationCollapseFlag ? "95%" : "70%"}
              bg={"#151117"}
              p="3"
              borderRadius={"10px"}
              overflow="hidden"
            >
              <CurrentImageConfiguration />
            </Box>
            {configurationCollapseFlag ? (
              <Box
                w="30%"
                position="relative"
                p="3"
                borderRadius="15px"
                bg="#151117"
                ml="2"
              >
                <Box
                  position="absolute"
                  top="0"
                  right="0"
                  mr="2"
                  mt="3"
                  zIndex="999"
                >
                  <AiFillRightCircle
                    color="#fff"
                    cursor="pointer"
                    size="20px"
                    bg={greyBgColor}
                    onClick={() => {
                      setConfiguationCollapseFlag(!configurationCollapseFlag);
                    }}
                  />
                </Box>
                <Box
                  w="100%"
                  h="95%"
                  overflow={"hidden"}
                  overflowY="auto"
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#151117",
                      borderRadius: "24px",
                    },
                  }}
                >
                  <ProjectWorkspace
                    currentWorkflowType={currentWorkflowType}
                    currentModelEntity={modelDetails?.modelEntity}
                    currentModelCode={modelDetails?.modelCode}
                    launchType={projectDetails?.launcher}
                  />
                </Box>
                <GenerateAIImage />
              </Box>
            ) : (
              <Box
                w="5%"
                p="3"
                borderRadius="10px"
                bg="#151117"
                h="100%"
                ml="2"
                pl="5"
              >
                <Box w={"100%"} h="100%">
                  <AiFillLeftCircle
                    cursor="pointer"
                    color="#fff"
                    onClick={() => {
                      setConfiguationCollapseFlag(!configurationCollapseFlag);
                    }}
                    size="20px"
                  />
                  <Text
                    fontSize="16px"
                    color="#fff"
                    mt="2"
                    css={{
                      writingMode: "vertical-lr",
                      textOrientation: "mixed",
                    }}
                  >
                    Expand Configuration Settings
                  </Text>
                </Box>
              </Box>
            )}
          </Flex>
        </TabPanel>
        {workflowId?.length ? (
          <TabPanel p="0">
            <PreviousImageConfig
              projectId={projectId}
              workflowId={workflowId}
              tabFlag={activeTab}
              workFlowImage={workflowData?.source_image}
            />
          </TabPanel>
        ) : null}
        {workflowId?.length ? (
          <TabPanel p="0" h="100%">
            <LikedImage
              projectId={projectId}
              workflowId={workflowId}
              tabFlag={activeTab}
              projectType={modelDetails?.modelEntity}
            />
          </TabPanel>
        ) : null}
        {enableLineDrawComponent && lineFlag ? (
          <TabPanel p="0" h="100%">
            <LineDraw
              projectId={projectId}
              workflowId={workflowId}
              tabFlag={activeTab}
              projectType={modelDetails?.modelEntity}
            />
          </TabPanel>
        ) : null}
      </TabPanels>
    </Tabs>
  );
}

export default ProjectConfiguration;
