// React Chakra Import
import { React } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Flex, Box } from "@chakra-ui/react";

// Component Import
import SideMenu from "../SideMenu/SideMenu";

import { greyBgColor } from "../../constants";

function Dashboard() {
  const sideMenuStatus = useSelector((store) => store.notificationHeader);
  const { sideMenuFlag } = sideMenuStatus;
  return (
    <Flex bg="#151117" fontSize="13px" height="100vh" overflow="hidden">
      <Box w={sideMenuFlag ? "15%" : "5%"} color="#f1f0f5" overflow="hidden">
        <SideMenu></SideMenu>
      </Box>
      <Box
        w={sideMenuFlag ? "85%" : "95%"}
        bg={greyBgColor}
        m="0.5%"
        borderRadius="10px"
        p="10px"
        h="98%"
        overflowY="scroll"
        css={{
          "&::-webkit-scrollbar": {
            width: "4px",
          },
          "&::-webkit-scrollbar-track": {
            width: "6px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: greyBgColor,
            borderRadius: "24px",
          },
        }}
      >
        <Outlet></Outlet>
      </Box>
    </Flex>
  );
}

export default Dashboard;
