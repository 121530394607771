import axios from "./axios";
import { serviceUrls } from "./serviceUrls";
import { getEmailId } from "../utils/localStorageIndex";

export const loginPrev = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.get(
    serviceUrls().loginApi +
      "?email=" +
      data.email +
      "&password=" +
      data.password,
    config
  );
};

export const login = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().loginApi, data, config);
};

export const getToken = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().tokenApi, data, config);
};

export const updateUserInfo = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.put(serviceUrls().updateInfo, body, config);
};

export const logout = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().logoutApi, data, config);
};

export const createTeamMember = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().createTeamMember, body, config);
};

export const changePassword = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(
    serviceUrls().changePassword +
      "?email=" +
      data.email +
      "&password=" +
      data.new_password,
    config
  );
};

export const updatePassword = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().updatePassword, data, config);
};

export const updateDefaultPassword = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateDefaultPassword, data, config);
};

export const enableDisableUsers = (
  data,
  config = null,
  axiosInstance = axios
) => {
  const enableDisableData = !data.is_enable;
  return axiosInstance.patch(
    serviceUrls().enableDisableUser +
      "?user_uuid=" +
      data.uuid +
      "&email=" +
      getEmailId() +
      "&disabled=" +
      enableDisableData,
    config
  );
};

export const enableDisableTeamMember = (
  data,
  config = null,
  axiosInstance = axios
) => {
  const body = {
    user_uuid: data.uuid,
    email: getEmailId(),
    disabled: !data.is_enable,
  };
  return axiosInstance.patch(serviceUrls().statusTeamMember, body, config);
};

export const resetPassword = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().resetEmailPassword, data, config);
};

export const fetchPersonalInfo = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().userInfo, data, config);
};

export const getAIEngineInfo = (body, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().engineInfo, body, config);
};

export const updateUserProfilePhoto = (
  body,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().userHeadshot, body, config);
};
