// Chakra Imports
import React, { useEffect, useState } from "react";
import { AiOutlineSave } from "react-icons/ai";
import {
  Box,
  Text,
  Button,
  Select,
  Input,
  FormLabel,
  FormControl,
  Modal,
  IconButton,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Textarea,
  useToast,
  Tooltip,
} from "@chakra-ui/react";

// Api Imports
import {
  addPromptData,
  fetchPromptDetails,
} from "../../../services/promptServices";

// Component Imports
import { errorColor, greyBgColor } from "../../../constants";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { useSelector } from "react-redux";

function PromptModal(props) {
  const finalRef = React.useRef(null);
  const toast = useToast();
  const [loading, isLoading] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [groupName, setGroupName] = useState("");
  const [promptData, setPromptData] = useState([]);
  const [promptName, setPromptName] = useState("");
  const [promptTitle, setPromptTitle] = useState("");
  const [groupValue, selectedGroupValue] = useState("");
  const [promptNameErr, setPromptNameErr] = useState("");
  const [promptTitleErr, setPrompTitleErr] = useState("");
  const [updatePromptData, setUpdatePromptData] = useState({});
  const [promptGroupNameErr, setPrompGroupNameErr] = useState("");
  const [currentSelectedImage, setCurrentSelectedImage] = useState("");
  const workflowDetails = useSelector((store) => store.workflowDesignHeader);

  useEffect(() => {
    promptDetails();
  }, []);

  // Todo:Set Config Details To Prompt
  useEffect(() => {
    if (workflowDetails?.currentSelectedImage?.length) {
      setCurrentSelectedImage(workflowDetails?.currentSelectedImage)
    }
    else {
      setCurrentSelectedImage('')
    }
  }, [workflowDetails]);

  //   Todo:Fetch Prompt Data List
  const promptDetails = () => {
    isLoading(true);
    let obj = {
      records_per_page: 100,
      page_id: 1,
    };
    fetchPromptDetails(obj)
      .then((res) => {
        if (res?.data[0]?.records?.length) {
          setPromptData(res?.data[0]?.records);
        } else {
          setPromptData([]);
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      })
      .finally(() => {
        isLoading(false);
      });
  };

  const openPopupModal = () => {
    onOpen();
    setPromptTitle(props?.promptTitleData);
  };

  const closeModal = () => {
    onClose();
    selectedGroupValue("");
    setUpdatePromptData({});
    setPromptName("");
    setPromptTitle("");
    setGroupName("");
    setPrompTitleErr("");
    setPromptNameErr("");
    setPrompGroupNameErr("");
  };

  // validate Fields
  const validateFieldForm = () => {
    let formIsValid = true;
    if (!promptName || promptName.length <= 0) {
      formIsValid = false;
      setPromptNameErr("*Prompt Name Cannot Be Empty.");
    } else {
      setPromptNameErr("");
    }
    if (!promptTitle || promptTitle.length <= 0) {
      formIsValid = false;
      setPrompTitleErr("*Prompt Title Cannot Be Empty.");
    } else {
      setPrompTitleErr("");
    }
    if (groupValue === "Add New Group" && groupName.length <= 0) {
      formIsValid = false;
      setPrompGroupNameErr("*Group Name Cannot Be Empty.");
    } else {
      setPrompGroupNameErr("");
    }

    return formIsValid;
  };

  // Todo:Save Prompt Data
  const saveData = () => {
    if (validateFieldForm()) {
      isLoading(true);
      let obj = {
        prompt_group: groupName?.length ? groupName : groupValue,
        prompt_name: promptName,
        prompt_text: promptTitle,
        prompt_example: currentSelectedImage
      };
      addPromptData(obj)
        .then((res) => {
          isLoading(false);
          if (res?.result && res?.data?.length) {
            closeModal();
            toast(toastFunctionToaster(res?.message, "success"));
          } else {
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          isLoading(false);
        });
    }
  };

  const renderGroupList = () => {
    if (promptData?.length) {
      const uniquePromptGroups = [
        ...new Set(promptData.map((item) => item.prompt_group)),
      ];
      return uniquePromptGroups?.map((value, index) => (
        <option key={index} color="#000" value={value}>
          {value}
        </option>
      ));
    }
  };

  return (
    <Box mr="2">
      <Tooltip placement="top-start" label={
        currentSelectedImage?.length
          ? "Save to Prompt Library"
          : "Please Generate or Select an Output Image"
        }>
        <IconButton
          colorScheme="green"
          size="sm"
          icon={<AiOutlineSave />}
          onClick={() => {
            openPopupModal();
          }}
          isDisabled={currentSelectedImage?.length ? false : true}
        />
      </Tooltip>
      <Modal
        finalFocusRef={finalRef}
        size="xl"
        isOpen={isOpen}
        onClose={() => {
          closeModal();
        }}
      >
        <ModalOverlay />
        <ModalContent bg={greyBgColor} borderRadius="20px">
          <ModalHeader color="#fff">
            {Object.keys(updatePromptData).length ? "Update" : "Save"} Prompt
          </ModalHeader>
          <ModalCloseButton color="#d7d7d7" />
          <ModalBody>
            <FormControl>
              <FormLabel fontSize={"14px"} fontWeight={"normal  "} color="#fff">
                Group
              </FormLabel>
              {groupValue === "Add New Group" ? (
                <Input
                  border="none"
                  bg="#151117"
                  fontSize="14px"
                  h="45px"
                  borderRadius={"10px"}
                  placeholder="Enter Group Name"
                  value={groupName}
                  onChange={(e) => {
                    setGroupName(e.target.value);
                  }}
                  _focus={{ boxShadow: "none" }}
                  color="#606c7f"
                />
              ) : (
                <Select
                  _focus={{ boxShadow: "none" }}
                  border="none"
                  bg="#151117"
                  fontSize="14px"
                  h="45px"
                  borderRadius={"10px"}
                  value={groupValue}
                  onChange={(e) => {
                    selectedGroupValue(e.target.value);
                  }}
                  color="#606c7f"
                >
                  <option color="#000" value="" selected disabled>
                    Select Group
                  </option>

                  {renderGroupList()}
                  <option value="Add New Group">Add New Group</option>
                </Select>
              )}
              {promptGroupNameErr?.length ? (
                <Text fontSize="sm" color={errorColor} mt="1">
                  {promptGroupNameErr}
                </Text>
              ) : null}
            </FormControl>
            <FormControl mt="4">
              <FormLabel fontSize={"14px"} fontWeight={"normal  "} color="#fff">
                Prompt Name
              </FormLabel>

              <Input
                border="none"
                bg="#151117"
                fontSize="14px"
                h="45px"
                borderRadius={"10px"}
                value={promptName}
                onChange={(e) => {
                  setPromptName(e.target.value);
                }}
                placeholder="Enter Prompt Name"
                _focus={{ boxShadow: "none" }}
                color="#606c7f"
              />
              {promptNameErr?.length ? (
                <Text fontSize="sm" color={errorColor} mt="1">
                  {promptNameErr}
                </Text>
              ) : null}
            </FormControl>
            <FormControl mt="4">
              <FormLabel fontSize={"14px"} fontWeight={"normal  "} color="#fff">
                Prompt
              </FormLabel>
              <Textarea
                border="none"
                bg="#151117"
                fontSize="14px"
                h="45px"
                borderRadius={"10px"}
                placeholder="Enter Prompt "
                value={promptTitle}
                onChange={(e) => {
                  setPromptTitle(e.target.value);
                }}
                _focus={{ boxShadow: "none" }}
                color="#606c7f"
              />
              {promptTitleErr?.length ? (
                <Text fontSize="sm" color={errorColor} mt="1">
                  {promptTitleErr}
                </Text>
              ) : null}
            </FormControl>
          </ModalBody>

          <ModalFooter pb="25px">
            <Button
              w="100%"
              colorScheme="green"
              size="sm"
              onClick={() => {
                saveData();
              }}
              isDisabled={loading || groupValue === "" ? true : false}
              isLoading={loading ? true : false}
            >
              {Object.keys(updatePromptData).length ? "Update" : "Save"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default PromptModal;
