// Chakra Imports
import { AiOutlineSearch } from "react-icons/ai";
import React, { useState, useEffect } from "react";
import {
  Box,
  useToast,
  Button,
  Flex,
  Image,
  Select,
  Input,
  Text,
  Drawer,
  IconButton,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

// Api Import
import {
  fetchPromptDetails,
  searchPromptLibrary,
} from "../../../../../../services/promptServices";

// Component Import
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import { greyBgColor } from "../../../../../../constants";

function PromptSearch(props) {
  const toast = useToast();
  const btnRef = React.useRef();
  const [loading, isLoading] = useState(false);
  const [promptName, setPromptName] = useState("");
  const [promptData, setPromptData] = useState([]);
  const [filteredGroupName, setFilteredGroupName] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [promptSearchName, setPromptSearchName] = useState("");
  const [groupValue, selectedGroupValue] = useState("");

  useEffect(() => {
    promptDetails();
  }, []);

  //   Todo:Fetch Prompt Data List
  const promptDetails = () => {
    isLoading(true);
    let obj = {
      records_per_page: 100,
      page_id: 1,
    };
    fetchPromptDetails(obj)
      .then((res) => {
        if (res?.data[0]?.records?.length) {
          setPromptData(res?.data[0]?.records);
          setFilteredGroupName(res?.data[0]?.records); //Todo:To maintain dropdown data
        } else {
          setPromptData([]);
          setFilteredGroupName([]);
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      })
      .finally(() => {
        isLoading(false);
      });
  };

  const renderPromptDetails = () => {
    if (promptData?.length) {
      return promptData?.map((data, index) => (
        <Box
          key={data?.prompt_uuid}
          borderWidth={"0.5px"}
          p="2"
          mb="3"
          borderRadius={"5px"}
          borderColor={"#585858"}
        >
          <Flex w="100%">
            {data?.prompt_example_image?.[0]?.url ? (
              <Box>
                <Image
                  h="100px"
                  w="150px"
                  borderRadius={"5px"}
                  objectFit={"cover"}
                  src={data?.prompt_example_image?.[0]?.url}
                />
              </Box>
            ) : null}
            <Box ml={data?.prompt_example_image?.[0]?.url ? 3 : 0} w="100%">
              <Text color="#fff" fontSize={"16px"} fontWeight={"bold"}>
                {data?.prompt_name}{" "}
                <Text as="span" fontSize={"14px"}>
                  ({data?.prompt_group})
                </Text>
              </Text>
              <Flex justifyContent={"space-between"} w="100%">
                <Text color="#fff" fontSize={"12px"} w="80%">
                  {data?.prompt_text}{" "}
                </Text>
                <Button
                  size="xs"
                  colorScheme="green"
                  onClick={() => {
                    passPromptData(data);
                  }}
                >
                  Apply
                </Button>
              </Flex>
            </Box>
          </Flex>
        </Box>
      ));
    }
  };

  // Todo:Pass selected prompt value to Base Config Component
  const passPromptData = (data) => {
    props?.sendPromptDetails(data?.prompt_text);
    onClose();
  };

  // Filter Prompt Data
  const renderGroupList = () => {
    if (filteredGroupName?.length) {
      const uniquePromptGroups = [
        ...new Set(filteredGroupName.map((item) => item.prompt_group)),
      ];
      return uniquePromptGroups?.map((value, index) => (
        <option key={index} color="#000" value={value}>
          {value}
        </option>
      ));
    }
  };

  // Todo:Filter prompt Api call
  const filterPromptData = () => {
    isLoading(true);
    let obj = {
      filter_key: groupValue?.length ? "group" : promptName,
      filter_value: groupValue?.length ? groupValue : promptSearchName,
    };
    searchPromptLibrary(obj)
      .then((res) => {
        isLoading(false);
        if (res?.result && res?.data?.length) {
          toast(toastFunctionToaster(res?.message, "success"));
          setPromptData(res?.data);
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        isLoading(false);
        toast(toastFunctionToaster(err?.message, "error"));
      });
  };
  return (
    <Box>
      <Flex
        alignItems={"center"}
        borderWidth={"0.5px"}
        borderColor={"#494552"}
        p="2"
        borderRadius={"5px"}
      >
        <Input
          placeholder="Search Prompt Library..."
          size="sm"
          color="white"
          borderRadius={"5px"}
          borderColor={"#494552"}
          mr="2"
          _focus={{ boxShadow: "none" }}
          value={promptName}
          onChange={(e) => {
            setPromptName(e.target.value);
          }}
        />
        <IconButton
          colorScheme="yellow"
          size="sm"
          icon={<AiOutlineSearch />}
          onClick={() => {
            onOpen();
          }}
          isDisabled={!promptName?.length ? true : false}
        />
      </Flex>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={() => {
          onClose();
          promptDetails();
          selectedGroupValue("");
        }}
        finalFocusRef={btnRef}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent bg={greyBgColor}>
          <DrawerCloseButton color="#585858" />
          <DrawerHeader color="#fff" fontSize="18px">
            Select Prompt
          </DrawerHeader>

          <DrawerBody
            p="3"
            borderTopWidth={"1px"}
            borderBottomWidth={"1px"}
            borderColor={"#585858"}
            h="calc(100vh - 200px)"
            overflow="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "6px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#fff",
                borderRadius: "24px",
              },
            }}
          >
            {renderPromptDetails()}
          </DrawerBody>

          <DrawerFooter>
            <Flex alignItems="center" w="100%" justifyContent={"space-between"}>
              <Select
                _focus={{ boxShadow: "none" }}
                border="none"
                bg="#151117"
                fontSize="14px"
                borderRadius={"10px"}
                value={groupValue}
                onChange={(e) => {
                  selectedGroupValue(e.target.value);
                }}
                color="#606c7f"
                w="38%"
                isDisabled={loading ? true : false}
              >
                <option color="#000" value="" selected disabled>
                  Select Group
                </option>

                {renderGroupList()}
              </Select>
              <Input
                type="text"
                size="sm"
                mr="2"
                color="#fff"
                placeholder="Enter Prompt Details..."
                w="38%"
                borderRadius={"5px"}
                value={promptSearchName}
                onChange={(e) => {
                  setPromptSearchName(e.target.value);
                }}
                isDisabled={loading ? true : false}
              ></Input>
              <Button
                colorScheme="yellow"
                size="sm"
                w="20%"
                onClick={() => {
                  filterPromptData();
                }}
                isDisabled={loading ? true : false}
                isLoading={loading ? true : false}
              >
                Search
              </Button>
            </Flex>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
}

export default PromptSearch;
