// Chakra React Imports
import { useSelector } from "react-redux";
import { TiMediaPlay } from "react-icons/ti";
import { React, useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { logout } from "../../services/sharedServices";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { FaRegClipboard, FaSearch } from "react-icons/fa";
import { useNavigate, useLocation } from "react-router-dom";
import { MdLocalLibrary, MdDashboard } from "react-icons/md";
import { Box, Text, Icon, Link, Flex, useToast } from "@chakra-ui/react";
import {
  AiFillBuild,
  AiOutlineDown,
  AiOutlineRight,
  AiFillSetting,
  AiOutlineBuild,
  AiOutlineMoneyCollect,
} from "react-icons/ai";

// Componemt Imports
import Logout from "../Logout/Logout";
import {
  getEmailId,
  getGroupId,
  clearLocalStorage,
} from "../../utils/localStorageIndex";
import {
  greyBgColor,
  themeFontSizeMediumHeading,
  themeFontSizeLargeHeading,
  defaultThemeColor,
} from "../../constants/index";

function MenuDetails() {
  const toast = useToast();
  const emailId = getEmailId();
  const groupId = getGroupId();
  const navigate = useNavigate();
  const location = useLocation();

  const sideMenuStatus = useSelector((store) => store.notificationHeader);
  const { sideMenuFlag } = sideMenuStatus;

  useEffect(() => {
    let newArr = [...sideMenuArr];
    newArr.map((data) => {
      if (location.pathname === data?.path) {
        setCurrentSelectedMenu(data?.label);
      }
    });
  }, [location.pathname]);

  const [sideMenuArr, setSideMenuArr] = useState([
    {
      label: "Dashboard",
      icon: MdDashboard,
      group: ["200", "300"],
      isSelected: true,
      path: "/business/dashboard",
    },
    {
      label: "Design Projects",
      icon: HiOutlineChartSquareBar,
      group: ["200", "300"],
      isSelected: true,
      path: "/business/projectslist",
    },
    {
      label: "Playground",
      icon: TiMediaPlay,
      group: ["200", "300"],
      isSelected: true,
      path: "/business/playground",
    },
    {
      label: "Resources",
      icon: FaRegClipboard,
      group: ["200", "300"],
      isSelected: true,
      path: "/business/resourceslist",
    },
    {
      label: "Prompt Library",
      icon: MdLocalLibrary,
      group: ["200", "300"],
      isSelected: true,
      path: "/business/promptLibrary",
    },
    {
      label: "Settings",
      icon: AiFillSetting,
      group: ["200"],
      isSelected: true,
      path: "/business/settings",
    },
    // {
    //   label: "Pipeline",
    //   icon: AiFillBuild,
    //   group: ["200"],
    //   isSelected: true,
    //   path: "/business/pipeline",
    // },
    {
      label: "Admin",
      icon: AiOutlineMoneyCollect,
      group: ["100"],
      isSelected: true,
      subMenu: [
        {
          label: "Dashboards",
          icon: AiOutlineBuild,
          path: "/admin/dashboards",
        },
        {
          label: "Corbu Users",
          icon: AiOutlineBuild,
          path: "/admin/corbuteam/AllTeamMembers",
        },
        {
          label: "Business",
          icon: AiOutlineBuild,
          path: "/admin/business/AllBusinesses",
        },
      ],
    },
  ]);

  const [currentSelectedMenu, setCurrentSelectedMenu] =
    useState("Design Projects");

  const openDropdownMenu = (menuData) => {
    let newArr = [...sideMenuArr];
    newArr.map((data) => {
      if (data.label === menuData.label) {
        data.isSelected = !data.isSelected;
      }
    });
    setSideMenuArr(newArr);
  };

  // const btnRef = React.useRef();
  // logout function

  const logoutHandler = () => {
    systemLogout();
  };
  const systemLogout = () => {
    var apiData = { email: emailId };
    logout(apiData)
      .then((res) => {
        if (res.result) {
          toast({
            title: res?.message,
            status: "success",
            isClosable: true,
            duration: 3000,
          });
          navigate("/login");
          clearLocalStorage();
        }
      })
      .catch((err) => {
        toast({
          title: "Error",
          status: "error",
          isClosable: true,
          duration: 3000,
        });
      });
  };

  const SideMenuUI = sideMenuArr?.map((data, index) => {
    if (data?.group?.includes(groupId)) {
      return (
        <Box key={index}>
          <Flex
            alignItems="center"
            onClick={() => {
              openDropdownMenu(data);
            }}
            cursor="pointer"
            w="100%"
          >
            {data?.subMenu ? (
              data.isSelected === true ? (
                <AiOutlineDown style={{ cursor: "pointer" }} />
              ) : (
                <AiOutlineRight style={{ cursor: "pointer" }} />
              )
            ) : (
              <Link
                as={RouterLink}
                to={data?.path}
                _focus={{ boxShadow: "none" }}
                style={{ textDecoration: "none" }}
                w="100%"
                onClick={() => {
                  setCurrentSelectedMenu(data.label);
                }}
              >
                <Flex
                  alignItems="center"
                  bg={currentSelectedMenu === data.label ? greyBgColor : null}
                  p="2"
                  borderRadius="5px"
                >
                  <Icon
                    mx="5px"
                    mr="5"
                    fontSize={sideMenuFlag ? "25" : "36"}
                    fontWeight="600"
                    as={data.icon}
                    _groupHover={{ color: "white" }}
                    color={
                      currentSelectedMenu === data.label
                        ? defaultThemeColor
                        : null
                    }
                  />
                  {sideMenuFlag ? (
                    <Text fontSize="14px">{data.label}</Text>
                  ) : null}
                </Flex>
              </Link>
            )}

            {/* Main Menu Header text */}
            {sideMenuFlag && data?.subMenu?.length ? (
              <Text fontSize={themeFontSizeLargeHeading}>{data.label}</Text>
            ) : null}
          </Flex>
          {data?.subMenu?.length ? (
            <Box>
              {data?.subMenu.map((subMenuData, index) => (
                <Box
                  key={index}
                  onClick={() => {
                    setCurrentSelectedMenu(subMenuData.label);
                  }}
                  display={data.isSelected === true ? "block" : "none"}
                >
                  <Link
                    as={RouterLink}
                    to={subMenuData?.path}
                    _focus={{ boxShadow: "none" }}
                    style={{ textDecoration: "none" }}
                  >
                    <Flex
                      alignItems="center"
                      p="5px"
                      // pl={sideMenuFlag ? "45px" : "25px"}
                      color={"white"}
                      bg={
                        currentSelectedMenu === subMenuData.label
                          ? greyBgColor
                          : null
                      }
                    >
                      <Icon
                        mr={sideMenuFlag ? "4" : "0px"}
                        fontSize="25px"
                        _groupHover={{ color: "white" }}
                        as={subMenuData.icon}
                      />
                      {/* Main Menu label text */}
                      {sideMenuFlag ? (
                        <Flex alignItems="center">
                          <Text
                            textTransform="capitalize"
                            fontSize={themeFontSizeMediumHeading}
                          >
                            {subMenuData.label}
                          </Text>
                        </Flex>
                      ) : null}
                    </Flex>
                  </Link>
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
      );
    }
  });

  return (
    <Box m={0} p={0}>
      {SideMenuUI}
      <Logout
        userEmailId={emailId}
        triggerLogoutFunction={logoutHandler}
        sideMenuStatus={sideMenuFlag}
      />
    </Box>
  );
}

export default MenuDetails;
