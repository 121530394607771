// Chakra React Imports
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Flex,
  Icon,
  Menu,
  Text,
  Grid,
  Tabs,
  Button,
  HStack,
  Input,
  VStack,
  TabList,
  Spinner,
  Heading,
  useToast,
  GridItem,
  MenuList,
  MenuButton,
  InputGroup,
  TabPanels,
  InputLeftElement,
} from "@chakra-ui/react";

// Component Imports
import "react-tagsinput/react-tagsinput.css";
import { BsGrid, BsList } from "react-icons/bs";
import { BiDotsVerticalRounded } from "react-icons/bi";
import demoSofa from "../../../image/demo-sofa-image-2.jpg";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { AiOutlineArrowLeft, AiOutlineSearch } from "react-icons/ai";
import {
  borderBottomColor,
  defaultThemeColor,
  themeFontSizeSmallHeading,
} from "../../../constants";

// Api Services Imports
import {
  getResourceEntity,
  deleteResourceObject,
  listResourceFolders,
} from "../../../services/resourceTemplateService";
import ResourceFolderImage from "./ResourceFolderImage";

function ResourcesView() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const toast = useToast();
  const [gridColumn, setGridColumn] = useState(state?.gridColumn);
  const [loading, isLoading] = useState(false);
  const [fullResourceDetails, setFullResourceDetails] = useState([]);
  const [deleteAlert, isDeleteAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [objToDelete, setObjToDelete] = useState(null);
  const [resourceFolders, setResourceFolders] = useState([]);
  const [filteredResourceFolders, setFilteredResourceFolders] = useState([]);
  const [filterResourceFolderName, setFilterResourceFolderName] = useState("");
  const [imageFolderName, setImageFolderName] = useState(null);
  const emptyResourceFoldersErr =
    'No files found for this resource. Please select "Bulk Upload" to add files to this resource.';

  useEffect(() => {
    resourceFoldersList();
  }, []);

  function renderFolderName(folderName) {
    if (folderName === "/") {
      return "User Uploaded Images";
    } else if (folderName === "output/") {
      return "Corbu Generated Images";
    } else {
      return folderName;
    }
  }

  // Get all Folders
  function resourceFoldersList() {
    if (state && state.resource_uuid) {
      let objBody = {
        resource_uuid: state && state.resource_uuid ? state.resource_uuid : "",
      };
      isLoading(true);
      listResourceFolders(objBody)
        .then((res) => {
          if (res?.data?.length > 0) {
            setResourceFolders(res.data);
            setFilteredResourceFolders(res.data);
          } else {
            setErrorMessage(emptyResourceFoldersErr);
            setResourceFolders([]);
          }
          isLoading(false);
        })
        .catch((err) => {
          toast(
            toastFunctionToaster("Error listing resource folders...", "error")
          );
          setResourceFolders([]);
          isLoading(false);
        });
    }
  }

  // Todo:Filter Search Data From Resource Folders
  const filterResourceFolders = () => {
    let cloneResourceFolders = [...resourceFolders];
    let filteredData = [];
    if (filterResourceFolderName?.length) {
      filteredData = cloneResourceFolders.filter((item) =>
        item?.folder
          ?.toLowerCase()
          .includes(filterResourceFolderName.toLowerCase())
      );
    } else {
      filteredData = cloneResourceFolders;
    }
    setFilteredResourceFolders(filteredData);
  };

  useEffect(() => {
    filterResourceFolders();
  }, [filterResourceFolderName]);

  const renderAllFolders = () => {
    if (filteredResourceFolders && filteredResourceFolders.length > 0) {
      var objList = filteredResourceFolders.map((data, index) => (
        <GridItem
          key={data?.folder}
          display={gridColumn === 4 ? "block" : "flex"}
          alignItems="center"
          justifyContent="space-between"
          background={gridColumn === 4 ? null : "#151117"}
          borderRadius={"10px"}
        >
          <Box
            onClick={() => {
              // navigateToFolderImage(data?.folder);
              setImageFolderName(data?.folder);
            }}
            bgImage={demoSofa}
            bgSize={"cover"}
            bgRepeat={"no-repeat"}
            _hover={{ cursor: "pointer" }}
            h={gridColumn === 4 ? "20vh" : "100px"}
            w={gridColumn === 4 ? "100%" : "200px"}
            borderTopLeftRadius={gridColumn === 4 ? "10px" : "0"}
            border={data?.folder === imageFolderName ? "1px" : "0px"}
            borderColor={
              data?.folder === imageFolderName ? "green.200" : "null"
            }
            borderTopRightRadius={gridColumn === 4 ? "10px" : "0"}
            borderBottomWidth={"0px"}
          ></Box>
          <Flex
            w={gridColumn === 4 ? null : "20%"}
            p="2"
            bg="#151117"
            alignItems={"center"}
            borderBottomLeftRadius={"10px"}
            borderBottomRightRadius={"10px"}
            border={data?.folder === imageFolderName ? "1px" : "0px"}
            borderColor={
              data?.folder === imageFolderName ? "green.200" : "null"
            }
            borderTopWidth={"0px"}
          >
            <Text fontSize={themeFontSizeSmallHeading} fontWeight="600">
              {renderFolderName(data.folder)}
            </Text>
          </Flex>
        </GridItem>
      ));
      return objList;
    } else {
      return errorMessage ? (
        <Box
          border="1px"
          borderRadius="10px"
          bg="#151117"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          p="10"
        >
          <Text
            color={defaultThemeColor}
            fontWeight="bold"
            textAlign={"center"}
          >
            {errorMessage}
          </Text>
        </Box>
      ) : null;
    }
  };

  // const navigateToFolderImage = (data) => {
  //   navigate("folderImage", {
  //     state: {
  //       resource_uuid: state.resource_uuid,
  //       folderName: data,
  //       resource_name: fullResourceDetails?.[0]?.resource_name,
  //       resource_type: fullResourceDetails?.[0]?.resource_type,
  //     },
  //   });
  // };

  // Delete objects Item for the resource
  function deleteObjectFinal() {
    if (state && state.resource_uuid && objToDelete != null) {
      let objBody = {
        resource_uuid: state && state.resource_uuid ? state.resource_uuid : "",
        objectKey: objToDelete,
      };
      isLoading(true);
      deleteResourceObject(objBody)
        .then((res) => {
          isLoading(false);
          if (res?.data?.length > 0) {
            // resourceObjectsList();
            setObjToDelete(null);
            isDeleteAlert(false);
          } else {
            setObjToDelete(null);
            isDeleteAlert(false);
          }
        })
        .catch((err) => {
          isLoading(false);
          setObjToDelete(null);
          isDeleteAlert(false);
        });
    }
  }

  function objectDeleteSetup(objId) {
    isDeleteAlert(true);
    setObjToDelete(objId);
  }

  // Fetch Resource Details
  const projectTemplateList = () => {
    if (state && state.resource_uuid) {
      let objBody = {
        resource_uuid: state && state.resource_uuid ? state.resource_uuid : "",
      };
      isLoading(true);
      getResourceEntity(objBody)
        .then((res) => {
          isLoading(false);
          if (res?.data?.length > 0) {
            setFullResourceDetails(res.data);
          } else {
            setErrorMessage(emptyResourceFoldersErr);
            setFullResourceDetails([]);
          }
        })
        .catch((err) => {
          toast(
            toastFunctionToaster("Error fetching resource details...", "error")
          );
          setFullResourceDetails([]);
          isLoading(false);
        });
    }
  };

  useEffect(() => {
    projectTemplateList();
  }, [state]);

  function oldrenderResourceContent() {
    if (fullResourceDetails.length > 0) {
      var resource = fullResourceDetails[0];
      return (
        <VStack w="100%">
          {/* <Flex w="100%" p={1} borderBottomWidth="0.5px" borderColor="#494552">
            <VStack
              w={"200px"}
              borderLeft={"1px"}
              borderLeftColor={"gray.600"}
              pl={"10px"}
            >
              <Text>Projects Count</Text>
              <Badge>{resource.projects_count}</Badge>
            </VStack>
            <VStack
              w={"200px"}
              borderLeft={"1px"}
              borderLeftColor={"gray.600"}
              pl={"10px"}
            >
              <Text>Objects Count</Text>
              <Badge>{resource.objects_count}</Badge>
            </VStack>
            <VStack
              w={"200px"}
              borderLeft={"1px"}
              borderLeftColor={"gray.600"}
              pl={"10px"}
            >
              <Text>Objects Added</Text>
              <Badge>{resource.objects_added ? "Yes" : "No"}</Badge>
            </VStack>
          </Flex> */}
          <Flex w={"100%"}>
            <Tabs w={"100%"}>
              <TabList color="#d7d7d7">
                {/* <Tab
                                    fontSize="16px"
                                    _selected={{
                                        color: '#fff',
                                        borderBottomColor: defaultHighlightedMenuOption,
                                    }}
                                    _focus={{ boxShadow: 'none' }}>
                                    Objects Overview
                                </Tab> */}
              </TabList>
              <TabPanels>
                {/* <TabPanel id={'Objects Overview'}>
                                    <Flex
                                        w={'100%'}
                                        p="5"
                                        borderWidth="0.5px"
                                        borderColor="#494552"
                                        borderRadius="10px"
                                        color="white"
                                        alignItems="center"
                                        justifyContent="space-between"
                                    >
                                        <VStack w={'100%'}>
                                            <Box w={'100%'} borderBottom={'1px'} pb={5} borderBottomColor={'gray.600'}>
                                                <Button size={'sm'} bg={'gray.200'} color={'gray.700'}
                                                    alignSelf={'end'}
                                                    onClick={() => resourceObjectsList()}>
                                                    Get Objects
                                                </Button>
                                            </Box>
                                            <Flex w={'100%'} p={2} alignContent={'top'}>
                                                <Box w={'100%'}>
                                                    <TableContainer>
                                                        <Table size="sm">
                                                            <Tbody>
                                                            {renderObjectsList()}
                                                            </Tbody>
                                                        </Table>
                                                    </TableContainer>
                                                    <AlertDialog isOpen={deleteAlert}>
                                                        <AlertDialogOverlay>
                                                        <AlertDialogContent>
                                                            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                                                            Delete Object
                                                            </AlertDialogHeader>
                                                            <AlertDialogBody>
                                                            Are you sure? You can't recover the object from the resource and storage!!
                                                            </AlertDialogBody>
                                                            <AlertDialogFooter>
                                                            <Button onClick={() => isDeleteAlert(false)}>
                                                                Cancel
                                                            </Button>
                                                            <Button colorScheme='red'
                                                                onClick={() => deleteObjectFinal()}
                                                                ml={3}>
                                                                Delete
                                                            </Button>
                                                            </AlertDialogFooter>
                                                        </AlertDialogContent>
                                                        </AlertDialogOverlay>
                                                    </AlertDialog>
                                                </Box>
                                                <Box w={'600px'} alignContent={'end'} p={10}>
                                                    {renderObjectImage()}
                                                </Box>
                                            </Flex>
                                            <Flex w={'100%'}>
                                                <Box w={'100%'}>
                                                    <Text mb="15px" fontSize="16px">
                                                     Total Objects:
                                                     <Badge ml={5} p={1}>
                                                    {resourceObjects && resourceObjects.length > 0
                                                     ? resourceObjects.length
                                                     : 0}
                                                     </Badge>
                                                    </Text>
                                                    <Text fontSize="12px">
                                                    </Text>
                                                </Box>
                                            </Flex>
                                        </VStack>
                                    </Flex>
                                </TabPanel> */}
              </TabPanels>
            </Tabs>
          </Flex>
        </VStack>
      );
    }
  }

  const routeToAdvanceSettings = () => {
    navigate("advance", {
      state: {
        resource_uuid: state.resource_uuid,
        resource_name: fullResourceDetails?.[0]?.resource_name,
        resource_type: fullResourceDetails?.[0]?.resource_type,
      },
    });
  };

  const renderResourceContent = () => {
    if (fullResourceDetails?.length > 0) {
      return <>{renderAllFolders()}</>;
    }
  };

  return (
    <Box>
      <Flex
        w="100%"
        color="white"
        justifyContent="space-between"
        alignItems="center"
      >
        {state === null || state.resource_uuid === undefined ? (
          <Text color={"red.400"}> Error loading the page.. </Text>
        ) : (
          <Box w="100%">
            {loading ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                mt="10"
                h="50vh"
              >
                <Spinner size="xl" color="#fff" />
              </Flex>
            ) : (
              <>
                <Flex
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  borderBottomWidth={"1px"}
                  borderColor={borderBottomColor}
                  mb="2"
                  pb="4"
                >
                  <Heading color="#fff" fontWeight="600" fontSize="24px">
                    Resource Folders
                  </Heading>
                  {/* <Flex>
                    <Box mr="2">
                      <Button
                        size="sm"
                        borderRadius={"0px"}
                        bg={gridColumn === 4 ? {defaultThemeColor} : "#ffff"}
                        fontSize={"20px"}
                        onClick={() => {
                          setGridColumn(4);
                        }}
                      >
                        <Icon as={BsGrid} />
                      </Button>
                      <Button
                        size="sm"
                        borderRadius={"0px"}
                        bg={gridColumn === 1 ? {defaultThemeColor} : "#ffff"}
                        fontSize={"20px"}
                        onClick={() => {
                          setGridColumn(1);
                        }}
                      >
                        <Icon as={BsList} />
                      </Button>
                    </Box>
                 
                  </Flex> */}
                  <Box>
                    <Menu as={Box} position="relative" zIndex={10}>
                      <MenuButton
                        position={"absolute"}
                        right="10"
                        top="5"
                        bg={"white"}
                        color="#000"
                        fontSize={"20px"}
                        borderRadius={"10px"}
                        fontWeight={"500"}
                        zIndex={1}
                      >
                        <BiDotsVerticalRounded />
                      </MenuButton>
                      <MenuList
                        border="none"
                        minW="100px !important"
                        position="absolute"
                        left="-90px"
                      >
                        <Flex
                          justifyContent={"space-between"}
                          alignItems={"center"}
                          px="2"
                        >
                          <Button
                            size="sm"
                            borderRadius={"0px"}
                            bg={gridColumn === 4 ? {defaultThemeColor} : "#ffff"}
                            fontSize={"20px"}
                            onClick={() => {
                              setGridColumn(4);
                            }}
                          >
                            <Icon as={BsGrid} />
                          </Button>

                          <Button
                            size="sm"
                            borderRadius={"0px"}
                            bg={gridColumn === 1 ? {defaultThemeColor} : "#ffff"}
                            fontSize={"20px"}
                            onClick={() => {
                              setGridColumn(1);
                            }}
                          >
                            <Icon as={BsList} />
                          </Button>
                        </Flex>
                      </MenuList>
                    </Menu>
                  </Box>
                </Flex>
                <Flex alignItems={"center"} justifyContent={"space-between"}>
                  <Flex alignItems={"center"}>
                    <AiOutlineArrowLeft
                      fontSize={"20px"}
                      cursor={"pointer"}
                      onClick={() => {
                        navigate(-1);
                      }}
                    />
                    <Heading
                      color={"#fff"}
                      fontWeight="600"
                      fontSize="18px"
                      ml={"3"}
                    >
                      {fullResourceDetails[0]?.resource_name}
                    </Heading>
                    <Text
                      bg={"green.400"}
                      ml="3"
                      p="2"
                      fontWeight={"600"}
                      borderRadius={"10px"}
                      color="#000"
                    >
                      {fullResourceDetails[0]?.resource_type}
                    </Text>
                  </Flex>
                  <Flex alignItems={"center"} w="60%">
                    <InputGroup border="none">
                      <InputLeftElement pointerEvents="none">
                        <AiOutlineSearch color="#fff" />
                      </InputLeftElement>
                      <Input
                        type="text"
                        color="#fff"
                        background={"#151117"}
                        border="none"
                        w="50%"
                        placeholder="Search Folders By Name"
                        borderColor={"#606060"}
                        _hover={{ boxShadow: "none" }}
                        onChange={(e) => {
                          setFilterResourceFolderName(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </Flex>
                  <Button
                    colorScheme="gray"
                    size="sm"
                    borderRadius={"10px"}
                    onClick={() => {
                      routeToAdvanceSettings();
                    }}
                  >
                    Bulk Upload
                  </Button>
                </Flex>
                <Grid
                  templateColumns={`repeat(${gridColumn}, 1fr)`}
                  gap={2}
                  mt="2"
                  h={resourceFolders?.length > 3 ? "40vh" : "auto"}
                  overflow="auto"
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#fff",
                      borderRadius: "24px",
                    },
                  }}
                >
                  {renderAllFolders()}
                </Grid>
                {imageFolderName ? (
                  <ResourceFolderImage
                    resource_uuid={state.resource_uuid}
                    folderName={imageFolderName}
                    resource_name={fullResourceDetails?.[0]?.resource_name}
                    resource_type={fullResourceDetails?.[0]?.resource_type}
                  />
                ) : null}
              </>
            )}
          </Box>
        )}
      </Flex>
    </Box>
  );
}

export default ResourcesView;
