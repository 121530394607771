// Chakra Imports
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { AiOutlineDelete, AiFillFolder } from "react-icons/ai";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Stack,
  Heading,
  Icon,
  Text,
  Image,
  Spinner,
  useToast,
  Tabs,
  TabPanel,
  TabPanels,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";

// Component Imports
import CreateWorkflow from "./CreateWorkflow";
import EditWorkflow from "../../EditWorkflow/EditWorkflow";
import HistoryWorkflow from "./HistoryWorkflow";
import LikedImage from "../DesignProjects/LikedImage/LikedImage";
import {
  renderWorkflowTitle,
  wizardTitles,
} from "../../../../constants/Workflow";
import ResponsivePagination from "react-responsive-pagination";
import { defaultThemeColor, greyBgColor } from "../../../../constants";
import { toastFunctionToaster } from "../../../../utils/toastFunction";

// Api Imports
import {
  CollectProjectWorkflows,
  DeleteProjectWorkflow,
  updateProjectTemplateDetails,
} from "../../../../services/projectTemplateService";
import AddProjectFolder from "./AddProjectFolder";

function ProjectWorkflow(props) {
  const navigate = useNavigate();
  const toast = useToast();
  const project_uuid = props?.project_uuid;
  const project_details = props?.projectDetails;
  const [projectName, setProjectName] = useState(project_details?.project_name);
  const onlyFetchOnCurrentPageChangeRef = useRef(false);
  const [loading, isLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterValue, setFilterValue] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [deleteAlert, isDeleteAlert] = useState(false);
  const [workFlowName, setWorkflowName] = useState(null);
  const [workflowToDelete, setWorkflowToDelete] = useState(null);
  const [fullProjectWorkflows, setFullProjectWorkflows] = useState([]);
  const [resourceId, setResourceId] = useState(
    props?.project_details?.project_resources?.length
      ? props?.project_details?.project_resources[
          props?.project_details?.project_resources?.length - 1
        ]?.resource_uuid
      : null
  );
  const [resourceName, setResourceName] = useState(
    props?.project_details?.project_resources?.length
      ? props?.project_details?.project_resources[
          props?.project_details?.project_resources?.length - 1
        ]?.resource_name
      : ""
  );

  useEffect(() => {
    if (project_details?.project_resources?.length) {
      setResourceId(
        project_details?.project_resources[
          project_details?.project_resources?.length - 1
        ]?.resource_uuid
      );
      setResourceName(
        project_details?.project_resources[
          project_details?.project_resources?.length - 1
        ]?.resource_name
      );
    } else {
      setResourceId();
      setResourceName("");
    }
    setProjectName(project_details?.project_name);
  }, [project_details]);

  useEffect(() => {
    if (project_uuid.length) {
      loadWorkflowData();
    }
  }, [project_uuid]);

  useEffect(() => {
    if (!onlyFetchOnCurrentPageChangeRef.current) {
      onlyFetchOnCurrentPageChangeRef.current = true;
      return;
    }
    loadWorkflowData();
  }, [currentPage, filterValue]);

  const loadWorkflowData = () => {
    if (project_uuid) {
      let objBody = {
        project_uuid: project_uuid,
        records_per_page: 10,
        page_id: currentPage,
        filter_child_workflows: filterValue === 0,
        searchKey: "",
        searchValue: "",
        filterEnabled: "",
      };
      if (filterValue > 0) {
        objBody = {
          ...objBody,
          workflow_model_code: wizardTitles[filterValue - 1].modelCode,
          workflow_model_type: wizardTitles[filterValue - 1].modelEntity,
        };
      }
      isLoading(true);
      CollectProjectWorkflows(objBody)
        .then((res) => {
          isLoading(false);
          if (res?.data?.length > 0) {
            setFullProjectWorkflows(
              res?.data[0]?.pagination_data?.[0]?.records
            );
            setTotalPages(res.data[0].pagination_data[0].total_pages);
            if (res.data[0].pagination_data[0].total_pages < currentPage) {
              setCurrentPage(
                Math.max(1, res.data[0].pagination_data[0].total_pages)
              );
            }
          } else {
            setFullProjectWorkflows([]);
          }
        })
        .catch((err) => {
          isLoading(false);
          setFullProjectWorkflows([]);
        });
    }
  };

  const routeToDesignGround = (data) => {
    if (project_details?.project_type === "Playground") {
      navigate("/business/playgroundDesign", {
        state: {
          launcher: "relaunch",
          project_uuid: "",
          workflow_uuid: data.workflow_uuid,
          modelDetails: {
            modelCode: data.model_code,
            modelEntity: data.model_type,
          },
          object_name: data.info[0].object_name,
          object_url: data.info[0].url,
          workflow_state: "existing",
          workflow_name: data?.name,
        },
      });
    } else {
      navigate("/business/designProject", {
        state: {
          launcher: "relaunch",
          resource_uuid: resourceId,
          project_uuid: project_uuid,
          workflow_uuid: data.workflow_uuid,
          modelDetails: {
            modelCode: data.model_code,
            modelEntity: data.model_type,
          },
          object_name: data.info[0].object_name,
          object_url: data.info[0].url,
          workflow_state: "existing",
          workflow_name: data?.name,
        },
      });
    }
  };

  const renderProjectWorkflow = () => {
    if (fullProjectWorkflows?.length) {
      return fullProjectWorkflows?.map((data, index) => {
        return (
          <GridItem
            bg={"#151117"}
            borderRadius={"10px"}
            cursor={"pointer"}
            borderWidth={"0.5px"}
            borderColor={greyBgColor}
            key={index}
          >
            <Box>
              <Image
                src={data.info[0].url}
                height={"150px"}
                w={"100%"}
                objectFit={"cover"}
                borderTopRightRadius={"10px"}
                borderTopLeftRadius={"10px"}
                onClick={() => routeToDesignGround(data)}
              ></Image>
            </Box>
            <Box>
              <Box p="10px" borderTopWidth={"0.5px"} borderColor={greyBgColor}>
                <Text
                  color="#fff"
                  fontWeight={"600"}
                  mb="1"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  w="150px"
                  textAlign={"left"}
                >
                  {data?.name ? data?.name : "Untitled Workflow"}
                </Text>
                <Text color={defaultThemeColor} fontWeight={"600"} mb="1">
                  {renderWorkflowTitle(data.model_code, data.model_type)}
                </Text>

                <Text color="#fff">
                  Created:{" "}
                  <Text as="span" ml="4" color={defaultThemeColor}>
                    {data?.started}
                  </Text>
                </Text>
                <Text color="#fff">
                  Updated:{" "}
                  <Text as="span" ml="3" color="green.200">
                    {data?.updated}
                  </Text>
                </Text>
              </Box>
              <Flex
                p="10px"
                borderTopWidth={"0.5px"}
                borderColor={greyBgColor}
                textAlign={"center"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Button
                  size={"xs"}
                  alignSelf={"end"}
                  colorScheme="green"
                  onClick={() => routeToDesignGround(data)}
                >
                  Select
                </Button>
                <Box
                  onClick={() => {
                    setWorkflowName(data?.name);
                  }}
                >
                  <HistoryWorkflow
                    projectId={project_uuid}
                    resourceId={resourceId}
                    workflowId={data?.workflow_uuid}
                    loadWorkflowData={loadWorkflowData}
                    setProjectPhoto={setProjectPhoto}
                  />
                  <EditWorkflow
                    workflowData={data}
                    project_uuid={project_uuid}
                    workflowName={workFlowName}
                    renderList={handlerenderList}
                  />
                  <Button ml="2" size={"xs"} colorScheme="red">
                    <Icon
                      w={5}
                      h={5}
                      as={AiOutlineDelete}
                      onClick={() => {
                        isDeleteAlert(true);
                        setWorkflowToDelete(data?.workflow_uuid);
                      }}
                    />
                  </Button>
                </Box>
              </Flex>
            </Box>
          </GridItem>
        );
      });
    }
    return null;
  };

  const setProjectPhoto = (workflow_uuid) => {
    isLoading(true);
    let projTemplateForm = { ...project_details };
    projTemplateForm["cover_workflow_uuid"] = workflow_uuid;
    updateProjectTemplateDetails(projTemplateForm)
      .then((res) => {
        if (res.status) {
          props?.resetEditableData("renderProjData");
          toast(toastFunctionToaster(res.message, "success"));
        } else {
          toast(toastFunctionToaster(res.message, "error"));
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster(err?.message, "error"));
      })
      .finally(() => {
        isLoading(false);
      });
  };

  const deleteWorkflowData = () => {
    if (workflowToDelete && project_uuid) {
      isDeleteAlert(false);
      let objBody = {
        project_uuid: project_uuid,
        workflow_uuid: workflowToDelete,
      };
      isLoading(true);
      DeleteProjectWorkflow(objBody)
        .then((res) => {
          setFullProjectWorkflows([]);
          if (res?.data?.length > 0) {
            loadWorkflowData();
            setCurrentPage(currentPage);
            toast(toastFunctionToaster(res?.message, "success"));
          } else {
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          if (err) {
            toast(toastFunctionToaster("Something Went Wrong", "error"));
          }
        })
        .finally(() => {
          isLoading(false);
        });
    }
  };

  // Todo:Render List
  const handlerenderList = (data) => {
    if (data) {
      loadWorkflowData();
    }
  };

  // Todo:Render Folder Name
  const renderFolderName = () => {
    return (
      <>
        <GridItem
          bg={"#252628"}
          borderRadius={"5px"}
          p="10px"
          cursor={"pointer"}
          borderWidth={"0.5px"}
          borderColor={greyBgColor}
        >
          <Flex alignItems={"center"}>
            <AiFillFolder fontSize={"25px"} color="#fff" />
            <Flex flexWrap={"wrap"} ml="3">
              <Text color="#fff" w="100%" fontWeight={"600"}>
                Untitled Folder
              </Text>
              <Text color="#fff" w="100%">
                <Text as="span" color="green.200" fontWeight={"bold"}>
                  2
                </Text>{" "}
                Workflows
              </Text>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem
          bg={"#252628"}
          borderRadius={"5px"}
          p="10px"
          cursor={"pointer"}
          borderWidth={"0.5px"}
          borderColor={greyBgColor}
        >
          <Flex alignItems={"center"}>
            <AiFillFolder fontSize={"25px"} color="#fff" />
            <Flex flexWrap={"wrap"} ml="3">
              <Text color="#fff" w="100%" fontWeight={"600"}>
                My Workflow
              </Text>
              <Text color="#fff" w="100%">
                <Text as="span" color="green.200" fontWeight={"bold"}>
                  6
                </Text>{" "}
                Workflows
              </Text>
            </Flex>
          </Flex>
        </GridItem>
      </>
    );
  };

  return (
    <Box>
      <Flex
        borderBottomWidth={"0.5px"}
        borderColor={greyBgColor}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Flex alignItems={"center"} justifyContent={"space-between"} w="100%">
          <Heading
            color="#fff"
            fontWeight="400"
            fontSize="18px"
            borderRadius={"10px"}
            bg={"#151117"}
            p="2"
          >
            Project Name:
            <Text as="span" ml="3" color={defaultThemeColor} fontWeight={"600"}>
              {projectName}
            </Text>
          </Heading>
          {project_details?.project_type !== "Playground" && resourceName && (
            <Text
              color="#fff"
              fontWeight="400"
              fontSize="18px"
              borderRadius={"10px"}
              bg={"#151117"}
              p="2"
            >
              Project Resource:
              <Text
                as="span"
                ml="3"
                color={defaultThemeColor}
                fontWeight={"600"}
              >
                {resourceName}
              </Text>
            </Text>
          )}
          <Button
            size="md"
            colorScheme="blue"
            onClick={() => {
              setActiveTab(activeTab === 0 ? 1 : 0);
            }}
          >
            {activeTab === 0 ? "Liked Images" : "Workflow Designs"}
          </Button>
        </Flex>
      </Flex>
      <Tabs
        w="100%"
        borderBottom={"0px"}
        mx="auto"
        onChange={setActiveTab}
        index={activeTab}
      >
        <TabPanels>
          <TabPanel p="0" h="100%">
            <Box>
              <CreateWorkflow projectDetails={project_details}></CreateWorkflow>
            </Box>
            {loading ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                mt="10"
                h="50vh"
                w="100%"
              >
                <Spinner size="xl" color="#fff" />
              </Flex>
            ) : (
              <>
                <Box
                  h="calc(100vh - 350px)"
                  bg={"#151117"}
                  overflow="auto"
                  overflowX="hidden"
                  borderRadius={"10px"}
                  mt="3"
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#fff",
                      borderRadius: "24px",
                    },
                  }}
                >
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    p="10px"
                    borderBottomWidth={"0.5px"}
                    borderBottomColor={greyBgColor}
                  >
                    <Flex>
                      <Text color="#fff" fontSize="20px">
                        Workflow Designs
                      </Text>
                      <AddProjectFolder projectDetails={props} />
                    </Flex>
                    <Flex>
                      <Stack direction="row" color="#fff">
                        {wizardTitles.map((item, index) => (
                          <Button
                            size="sm"
                            colorScheme="purple"
                            onClick={(e) => {
                              setFilterValue(index + 1);
                            }}
                            isActive={filterValue === index + 1}
                          >
                            {item.label}
                          </Button>
                        ))}
                      </Stack>

                      <Button
                        colorScheme="yellow"
                        ml="2"
                        size="sm"
                        onClick={() => {
                          setFilterValue(0);
                        }}
                      >
                        Clear Filter
                      </Button>
                    </Flex>
                  </Flex>
                  <Grid
                    templateColumns="repeat(4, 1fr)"
                    gap={4}
                    p="10px"
                    pt="4"
                    borderBottomWidth={"0.5px"}
                    borderBottomColor={greyBgColor}
                  >
                    {renderFolderName()}
                  </Grid>
                  {fullProjectWorkflows?.length ? (
                    <Grid
                      templateColumns="repeat(4, 1fr)"
                      gap={4}
                      p="10px"
                      pt="4"
                    >
                      {renderProjectWorkflow()}
                    </Grid>
                  ) : (
                    <Text
                      fontSize={"16px"}
                      fontWeight={"600"}
                      color={defaultThemeColor}
                      ml="2"
                    >
                      No Workflows Found
                    </Text>
                  )}
                </Box>
                {fullProjectWorkflows?.length ? (
                  <Box mt="4">
                    <ResponsivePagination
                      current={currentPage}
                      total={totalPages}
                      onPageChange={(pageNumber) => {
                        setCurrentPage(pageNumber);
                      }}
                    />
                  </Box>
                ) : null}
              </>
            )}
          </TabPanel>
          <TabPanel p="0" h="100%">
            <LikedImage
              projectId={project_uuid}
              resourceId={resourceId}
              tabFlag={activeTab + 1}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Todo:Delete Workflow Popup */}
      <AlertDialog isOpen={deleteAlert}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Workflow
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? The workflow and all associated data will be
              permanently deleted from storage!!
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={() => isDeleteAlert(false)} size="sm">
                Cancel
              </Button>
              <Button
                ml={3}
                size="sm"
                colorScheme="red"
                onClick={() => deleteWorkflowData()}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

export default ProjectWorkflow;
