// React Chakra Imports
import React, { useEffect, useState } from "react";
import { BiImages, BiBed, BiCoffee, BiDish } from "react-icons/bi";
import {
  Box,
  Flex,
  Button,
  Text,
  Heading,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { defaultThemeColor, greyBgColor } from "../../../constants";
import { toastFunctionToaster } from "../../../utils/toastFunction";
import { getUserResourceUsageData } from "../../../services/businessServices";

function WidgetDashboard() {
  const [loading, isLoading] = useState(true);
  const [imgsGenerated, setImgsGenerated] = useState(null);
  const toast = useToast();

  useEffect(() => {
    fetchStatistics();
  }, []);

  // Fetch Statistics
  const fetchStatistics = () => {
    isLoading(true);
    getUserResourceUsageData({})
      .then((res) => {
        if (res?.data?.outputs) {
          setImgsGenerated(res?.data?.outputs?.total_records);
        } else {
          setImgsGenerated(null);
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        setImgsGenerated(null);
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      })
      .finally(() => {
        isLoading(false);
      });
  };

  return (
    <Box mb="5">
      <Flex justifyContent={"space-between"}>
        <Heading mb="5" color="#fff" fontWeight="600" fontSize="24px">
          Dashboard
        </Heading>
      </Flex>
      <Flex
        w="100%"
        alignItems={"center"}
        justifyContent={"space-between"}
        mt="2"
        p="20px"
        borderRadius={"10px"}
        bg={"#151117"}
      >
        <Box w="48%" p="2">
          <Box bg={defaultThemeColor} p="2" borderRadius={"5px"}>
            <Flex alignItems={"center"}>
              <Box p="5px" borderRadius={"50px"} mr="10px">
                <BiImages fontSize="40px" color={greyBgColor} />
              </Box>
              <Box>
                {loading ? (
                  <Spinner />
                ) : (
                  <Text fontWeight={"600"} fontSize={"24px"}>
                    {imgsGenerated}
                  </Text>
                )}
              </Box>
            </Flex>
          </Box>
          <Text mt="3" color={"#fff"} fontWeight={"600"} fontSize={"18px"}>
            Images Generated
          </Text>
        </Box>
        <Box w="12%" p="1">
          <Box bg={defaultThemeColor} p="2" borderRadius={"5px"}>
            <Flex alignItems={"center"}>
              <Box p="5px" borderRadius={"50px"} mr="10px">
                <BiCoffee fontSize="40px" color={greyBgColor} />
              </Box>
              <Box>
                <Text fontWeight={"600"} fontSize={"24px"}>
                  3
                </Text>
              </Box>
            </Flex>
          </Box>
          <Text mt="3" color={"#fff"} fontWeight={"600"} fontSize={"18px"}>
            Coffee Breaks
          </Text>
        </Box>
        <Box w="12%" p="1">
          <Box bg={defaultThemeColor} p="2" borderRadius={"5px"}>
            <Flex alignItems={"center"}>
              <Box p="5px" borderRadius={"50px"} mr="10px">
                <BiDish fontSize="40px" color={greyBgColor} />
              </Box>
              <Box>
                <Text fontWeight={"600"} fontSize={"24px"}>
                  3
                </Text>
              </Box>
            </Flex>
          </Box>
          <Text mt="3" color={"#fff"} fontWeight={"600"} fontSize={"18px"}>
            Snack Breaks
          </Text>
        </Box>
        <Box w="12%" p="1">
          <Box bg={defaultThemeColor} p="2" borderRadius={"5px"}>
            <Flex alignItems={"center"}>
              <Box p="5px" borderRadius={"50px"} mr="10px">
                <BiBed fontSize="40px" color={greyBgColor} />
              </Box>
              <Box>
                <Text fontWeight={"600"} fontSize={"24px"}>
                  3
                </Text>
              </Box>
            </Flex>
          </Box>
          <Text mt="3" color={"#fff"} fontWeight={"600"} fontSize={"18px"}>
            Nap Breaks
          </Text>
        </Box>
      </Flex>
    </Box>
  );
}

export default WidgetDashboard;
