// React Imports
import React, { useState } from "react";
import { Box, Text, Flex, Button, useToast } from "@chakra-ui/react";
import { AiOutlineStar, AiOutlineHeart } from "react-icons/ai";

// Component Import
import { defaultThemeColor, greyBgColor } from "../../../../../constants";

// Api Services Import
import { useSelector } from "react-redux";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import { LikeUpdateGeneratedImage } from "../../../../../services/projectTemplateService";

function LikeImage(props) {
  const toast = useToast();
  const [loading, isLoading] = useState(false);
  const [styleCount, setStyleCount] = useState(
    props?.likeRating?.style_count ? props?.likeRating?.style_count : 0
  );
  const [qualityCount, setQualityCount] = useState(
    props?.likeRating?.quality_count ? props?.likeRating?.quality_count : 0
  );
  const [lightingCount, setLightingCount] = useState(
    props?.likeRating?.lightening_count
      ? props?.likeRating?.lightening_count
      : 0
  );
  const [showCommentDiv, setShowCommentDiv] = useState(false);
  const workflowDetails = props?.workflowDetails;
  const workflowFlagData = useSelector((store) => store.workflowDesignHeader);

  // Todo:Render Liking Component
  const StarComponent = ({ count, setCount, defaultThemeColor }) => (
    <>
      {[...Array(5)].map((_, index) => (
        <AiOutlineStar
          key={index}
          onClick={() => setCount(index + 1)}
          color={count >= index + 1 ? defaultThemeColor : null}
          cursor="pointer"
          fontSize="20px"
        />
      ))}
    </>
  );

  // Todo:Save Like Api
  const saveLike = () => {
    let obj = {
      project_uuid: workflowDetails?.workflowObject?.project_uuid,
      workflow_uuid: workflowDetails?.workflowObject?.workflow_uuid,
      workflow_counter: props?.workflowCounter,
      image_id: props?.imageIndexValue,
      lightening_count: lightingCount,
      style_count: styleCount,
      quality_count: qualityCount,
    };
    isLoading(true);
    LikeUpdateGeneratedImage(obj)
      .then((res) => {
        isLoading(false);
        resetLikeFeature();
        if (res?.result) {
          toast(toastFunctionToaster(res?.message, "success"));
          props?.sendDataToCardFlip("true");
        } else {
          toast(toastFunctionToaster(res?.message, "error"));
          props?.sendDataToCardFlip("false");
        }
      })
      .catch((err) => {
        if (err) {
          isLoading(false);

          resetLikeFeature();
          toast(toastFunctionToaster("Something Went Wrong", "error"));
        }
      });
  };

  // Todo:Reset After save or close
  const resetLikeFeature = () => {
    setStyleCount(0);
    setQualityCount(0);
    setLightingCount(0);
    setShowCommentDiv(false);
  };

  return (
    <Box mx={props?.marginX ? props?.marginX : "2"} position={"relative"}>
      <AiOutlineHeart
        size={props?.fontSize ? props?.fontSize : "30px"}
        cursor="pointer"
        color={defaultThemeColor}
        onClick={() => setShowCommentDiv(!showCommentDiv)}
      />
      {showCommentDiv ? (
        <Box
          position={"absolute"}
          right={"0px"}
          top="33px"
          bg={greyBgColor}
          borderRadius={"10px"}
          color="#fff"
          p="2"
          zIndex={"9"}
        >
          <Box position={"relative"}>
            <Flex alignItems={"center"}>
              <Text fontSize={"16px"}>Lighting</Text>
              <Flex ml="2">
                <StarComponent
                  count={lightingCount}
                  setCount={setLightingCount}
                  defaultThemeColor={defaultThemeColor}
                />
              </Flex>
            </Flex>
            <Flex alignItems={"center"}>
              <Text fontSize={"16px"} w="60%">
                Style
              </Text>
              <Flex ml="2">
                <StarComponent
                  count={styleCount}
                  setCount={setStyleCount}
                  defaultThemeColor={defaultThemeColor}
                />
              </Flex>
            </Flex>
            <Flex alignItems={"center"}>
              <Text fontSize={"16px"} w="40%">
                Quality
              </Text>
              <Flex ml="2">
                <StarComponent
                  count={qualityCount}
                  setCount={setQualityCount}
                  defaultThemeColor={defaultThemeColor}
                />
              </Flex>
            </Flex>
            <Flex justifyContent="end" mt="2">
              <Button
                size="xs"
                colorScheme="green"
                onClick={() => {
                  saveLike();
                }}
                isDisabled={loading ? true : false}
                isLoading={loading ? true : false}
              >
                Save{" "}
              </Button>
              <Button
                size="xs"
                colorScheme="red"
                onClick={() => {
                  setShowCommentDiv(false);
                }}
                ml="2"
              >
                Close{" "}
              </Button>
            </Flex>
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}

export default LikeImage;
