// React Imports
import { AiOutlineCopy } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { BiDotsVerticalRounded } from "react-icons/bi";
import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Spinner,
  MenuButton,
  MenuList,
  Button,
  Menu,
  Image,
  Flex,
  Text,
  Tooltip,
  VStack,
  useToast,
} from "@chakra-ui/react";

// Component Imports
import PopupImage from "./PopupImage";
import SourceImage from "../SourceImage/SourceImage";
import PreviousGeneratedImage from "./PreviousGeneratedImage";
import DownloadImage from "../../DownloadImage/DownloadImage";
import { defaultThemeColor, greyBgColor } from "../../../../../../constants";
import {
  setClipBoardValue,
  setSelectedImage,
  setSelectedImageType,
} from "../../../../../../store/actions/workFlowAction";
import ImageXConfig from "./ImageXConfig";
import CopyImageToClipboard from "../../../../../Custom/CopyImageToClipboard";
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";

function CurrentImageConfiguration() {
  const divRef = useRef([]);
  const dispatch = useDispatch();
  const toast = useToast();
  const [image, setImage] = useState("");
  const [imageObjName, setImageObjName] = useState("");
  const [imageDetailsArray, setImageDetailsArray] = useState([]);
  const [imageIndexValue, setImageIndexValue] = useState(null);
  const [workflowCounter, setWorkflowCounter] = useState(null);
  const { copyImageToClipboard } = CopyImageToClipboard(); //Custom Clipboard Hook

  const workflowDetails = useSelector((store) => store?.workflowDesignHeader);
  const previousDataArr =
    workflowDetails?.workflowObject?.previousGeneratedData;
  // Todo:Fetch Selected Image Count and convert to array When Generated
  const loadingBoxes = Array.from(
    { length: workflowDetails?.workflowObject?.generatedImageCount },
    (_, index) => index + 1
  );

  const imageData = workflowDetails?.workflowImageData[0]?.images.filter(
    (item) => item.hasOwnProperty("url")
  );

  useEffect(() => {
    if (previousDataArr?.length === 0) {
      resetSelectedImage();
    }
  }, [previousDataArr]);

  useEffect(() => {
    if (workflowDetails?.workflowImageData?.length) {
      let cloneArr = [
        workflowDetails?.workflowImageData[0],
        ...workflowDetails?.workflowObject?.previousGeneratedData,
      ];
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "previousGeneratedData",
          value: cloneArr,
        },
      });
    }
  }, [workflowDetails?.workflowImageData]);

  const copyToClipboard = (data) => {
    if (data?.object_name?.length) {
      dispatch(setClipBoardValue(data?.object_name));
      copyImageToData(data?.object_name);
    }
  };

  // Todo:Custom Copy To Clipboard Hook
  const copyImageToData = async (imageName) => {
    try {
      await copyImageToClipboard(imageName);
      toast(toastFunctionToaster("Image copied to clipboard", "success"));
    } catch (error) {
      toast(toastFunctionToaster("Failed to copy image to clipboard", "error"));
    }
  };

  // Todo: Callback function to reset selected Image from Source Image
  const resetSelectedImage = () => {
    setImage("");
    setImageObjName("");
    setImageIndexValue(null);
    setWorkflowCounter(null);
    dispatch(setSelectedImageType(""));
  };

  // Callback function to receive data from PrevGen Component
  const receiveImageFromPrevComp = (data) => {
    setImage(data?.imageUrl);
    setImageObjName(data?.imageObjName);
    setImageIndexValue(data?.indexValue);
    setWorkflowCounter(data?.workflowCounter);
  };

  // Todo:Key Board Control Events For Previous Generated Image's
  const handleKeyDown = (event, index) => {
    if (event.key === "ArrowRight") {
      const newIndex = index + 1;
      if (newIndex < imageData.length) {
        updateImageDetails(newIndex);
      }
    } else if (event.key === "ArrowLeft") {
      const newIndex = index - 1;
      if (newIndex >= 0) {
        updateImageDetails(newIndex);
      }
    }
  };

  // Update details based on Index
  const updateImageDetails = (newIndex) => {
    setImage(imageData[newIndex]?.url);
    setImageObjName(imageData[newIndex]?.object_name);
    setImageIndexValue(newIndex);
    setWorkflowCounter(workflowDetails?.workflowImgCount);
    dispatch(setSelectedImageType("current"));
  };

  // Todo: Make Image Keydown Event
  useEffect(() => {
    if (
      divRef.current[imageIndexValue] &&
      workflowDetails?.selectedImageType === "current"
    ) {
      divRef.current[imageIndexValue].focus();
    }
  }, [imageIndexValue]);

  const renderSliderImage = imageData?.length
    ? imageData.map((data, index) => (
        <Box
          key={index}
          h="100%"
          cursor="pointer"
          borderWidth={image === data?.url ? "3px" : "0px"}
          borderColor={image === data?.url ? "green.500" : ""}
          position={"relative"}
          mr="1%"
          css={{
            "&:last-child": {
              marginRight: "0px",
            },
          }}
        >
          <Menu as={Button}>
            <MenuButton
              color="#fff"
              position={"absolute"}
              right="1"
              top="1"
              bg={greyBgColor}
              fontSize={"20px"}
              borderRadius={"10px"}
            >
              <BiDotsVerticalRounded />
            </MenuButton>
            <MenuList
              bg={greyBgColor}
              border="none"
              minW="100px !important"
              position="absolute"
              left="-90px"
            >
              <Flex justifyContent={"space-between"} px="2">
                <Tooltip label="Download Image">
                  <Box>
                    <DownloadImage
                      position={"relative"}
                      right={"0"}
                      imageUrl={data?.url?.length ? data?.url : null}
                    ></DownloadImage>
                  </Box>
                </Tooltip>
                <Tooltip label="Copy To Clipboard">
                  <Box>
                    <AiOutlineCopy
                      size="20px"
                      cursor="pointer"
                      color={defaultThemeColor}
                      onClick={() => {
                        copyToClipboard(data);
                      }}
                    />
                  </Box>
                </Tooltip>
                <Tooltip label="Open In Full Screen">
                  <Box>
                    <PopupImage
                      fontSize="20px"
                      position={"relative"}
                      imageUrl={data?.url}
                      imageData={imageData}
                      indexValue={index}
                    />
                  </Box>
                </Tooltip>
              </Flex>
              <ImageXConfig
                imgUrl={data}
                height={imageDetailsArray[index]?.height}
                width={imageDetailsArray[index]?.width}
              />
            </MenuList>
          </Menu>

          <Image
            h="100%"
            objectFit="cover"
            src={data?.url}
            w="100%"
            outline={"0"}
            alt={`Image ${index}`} // Add an alt attribute for accessibility
            onClick={() => {
              setImage(data?.url);
              setImageObjName(data?.object_name);
              setImageIndexValue(index);
              setWorkflowCounter(workflowDetails?.workflowImgCount);
              dispatch(setSelectedImageType("current"));
            }}
            tabIndex={0} //Todo:Make Ref Focused When Changed
            ref={(el) => (divRef.current[index] = el)}
            onKeyDown={(e) => {
              if (workflowDetails?.selectedImageType === "current") {
                handleKeyDown(e, index);
              }
            }}
            onLoad={(e) => {
              const height = e.target.naturalHeight;
              const width = e.target.naturalWidth;
              setImageDetailsArray((prevArray) => {
                const newArray = [...prevArray];
                newArray[index] = { height, width };
                return newArray;
              });
            }}
          />
          <Box
            color="#fff"
            position="absolute"
            top="0"
            bg={greyBgColor}
            z-index="9999"
            fontWeight={"600"}
            p="2"
          >
            {imageDetailsArray[index]?.width} x{" "}
            {imageDetailsArray[index]?.height}
          </Box>
        </Box>
      ))
    : null;

  // Todo:To Store Prompt Image store current selected image globally
  useEffect(() => {
    if (imageObjName?.length) {
      dispatch(setSelectedImage(imageObjName));
    } else {
      dispatch(setSelectedImage(""));
    }
  }, [imageObjName]);

  return (
    <>
      <Box h="65%" my="1">
        <SourceImage
          currentImage={image}
          resetImage={resetSelectedImage}
          workflowCounter={workflowCounter}
          imageIndexValue={imageIndexValue}
          imageObjectName={imageObjName}
        />
      </Box>
      {/* Previous Configuration Data */}
      <Box
        h="33%"
        my="2"
        overflow="auto"
        overflowX={"hidden"}
        css={{
          "&::-webkit-scrollbar": {
            width: "5px",
            height: "5px",
          },
          "&::-webkit-scrollbar-track": {
            width: "5px",
            height: "5px",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#fff",
            borderRadius: "24px",
          },
        }}
      >
        {workflowDetails?.workflowEngineLoader ? (
          <Flex h="100%" mt="2">
            {loadingBoxes.map((index) => (
              <Box
                h="100%"
                w="24%"
                mr="1%"
                css={{
                  "&:last-child": {
                    marginRight: "0px",
                  },
                }}
                key={index}
              >
                <VStack
                  w="100%"
                  h="100%"
                  alignItems="center"
                  justifyContent="center"
                  bg={greyBgColor}
                >
                  <Spinner size="xl" color="#fff" />
                  <Text as="span" color="#fff">
                    {workflowDetails?.workflowEngineQueue
                      ? `#${workflowDetails?.workflowEngineQueue} in Queue...`
                      : "Generating Image..."}
                  </Text>
                </VStack>
              </Box>
            ))}
          </Flex>
        ) : null}

        {imageData?.length ? (
          <Box
            display={"-webkit-box"}
            h="100%"
            width="-webkit-fill-available"
            overflow="auto"
            overflowY={"hidden"}
            css={{
              "&::-webkit-scrollbar": {
                width: "5px",
                height: "5px",
              },
              "&::-webkit-scrollbar-track": {
                width: "5px",
                height: "5px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#fff",
                borderRadius: "24px",
              },
            }}
          >
            {renderSliderImage}
          </Box>
        ) : null}

        {previousDataArr?.length ? (
          <Box mt="2">
            <Text p="2" color="#fff" fontSize={"16px"} fontWeight={600}>
              Previous Generated Images
            </Text>
            <PreviousGeneratedImage
              flipCardStatus={false}
              passImageData={receiveImageFromPrevComp}
            />
          </Box>
        ) : null}
      </Box>
    </>
  );
}

export default CurrentImageConfiguration;
