export const serviceUrls = (urlVar = null) => {
  return {
    loginApi: `/v1/api/user/login`,
    tokenApi: `/v1/api/token`,
    updateInfo: `/v1/api/user/update_info`,
    changePassword: `/v1/api/user/forgot_password`,
    updatePassword: `/v1/api/user/update_password`,
    updateDefaultPassword: `/v1/api/update_default_password`,
    logoutApi: `/v1/api/user/logout`,
    enableDisableUser: `/v1/api/user/enable-disable`,
    resetEmailPassword: `/v1/api/reset_password`,
    userInfo: `/v1/api/user/personal_info`,
    userHeadshot: `/v1/api/user/headshot`,
    // Systems
    engineInfo: `/v1/api/utils/engine_status`,
    // Admin Urls
    fetchBusinessesListUrl: `/v1/api/admin/businesses_list`,
    fetchBusinessHrManagers: `/v1/api/admin/business_users`,
    // bussiness urls
    addBusinessUser: `/v1/api/business/register_user`,
    addBusiness: `/v1/api/business/add_business`,
    updateBusiness: `/v1/api/business/update_business`,
    fetchBusinessDetails: `/v1/api/business/details`,
    updateBusinessLogo: `/v1/api/business/logo`,
    inviteBusinessUser: `/v1/api/business/invite_user`,
    pageinatedAllBusinessUserList: `/v1/api/business/users/listing`,
    enableDisableBusinessUser: `/v1/api/business/users/manage_status`,
    updateBusinessUser: `/v1/api/business/users/update`,
    fetchTeamMembers: `/v1/api/business/team_listing`,
    userResourceUsageData: `/v1/api/business/analytics/user_level`,
    // department url
    fetchDepartmentList: `/v1/api/business/department/departments`,
    addDepartmentDetails: `/v1/api/business/department/add_department`,
    updateDepartmentDetails: `/v1/api/business/department/update_department`,
    addDepartmentBusinessUsers: `/v1/api/business/department/create_link`,
    // Team Member urls
    createTeamMember: `/v1/api/team/create`,
    statusTeamMember: `/v1/api/team/manage_status`,
    fetchTeamMember: `/v1/api/team/get_active_member`,
    // Project url
    addProjectTemplate: `/v1/api/project/create`,
    getProjectList: `/v1/api/project/listing`,
    manageProjectStatus: `/v1/api/project/manage_status`,
    updateProjectContent: `/v1/api/project/update`,
    getProjectContent: `/v1/api/project/entity`,
    saveProjectObjects: `/v1/api/project/save_objects`,
    collectProjectObjects: `/v1/api/project/objects_listing`,
    projectStatistics: `v1/api/project/statistics`,
    getProjectActiveWorkflow: `/v1/api/project/workflow_ide`,
    collectProjectWorkflows: `/v1/api/project/workflows`,
    fetchRootDetails: `v1/api/project/workflow/root`,
    getHistoryWorkflowData: `/v1/api/project/workflow/tree`,
    collectSharedWorkflows: `/v1/api/project/workflow/sharedlist`,
    deleteProjectWorkflow: `/v1/api/project/workflow/remove`,
    DeleteProjectWorkflowTree: `/v1/api/project/workflow/remove_tree`,
    updateProjectWorkflow: `/v1/api/project/workflow/update`,
    projectWorkflowGenerate: `/v1/api/project/workflow/generate`,
    workflowGeneratedStatus: `/v1/api/project/workflow/status`,
    workflowGeneratedResult: `/v1/api/project/workflow/result`,
    shareProjectWorkflow: `/v1/api/project/workflow/share`,
    workflowCollectPrevResult: `/v1/api/project/workflow/resultprev`,
    getPlaygroundWorkflow: `/v1/api/project/workflow/playground`,
    setupRefImageUrl: `/v1/api/project/workflow/reference_image`,
    likeUpdateGeneratedImage: `v1/api/project/workflow/like`,
    likedImage: `v1/api/project/liked_images`,
    likedImageStatistics: `v1/api/project/workflow/like_stats`,
    workflowStatistics: `v1/api/project/workflow/statistics`,
    workflowActionStatistics: `v1/api/project/workflow/image_stats`,
    unlikeImage: `/v1/api/project/workflow/unlike`,
    deleteLabel: `/v1/api/resources/delete_labels`,
    updateLabel: `/v1/api/resources/update_labels`,
    // Resources url
    addResourceTemplate: `/v1/api/resources/create`,
    createBulkObjectUpload: `/v1/api/resources/create_bulk_object_upload`,
    getResourceList: `/v1/api/resources/listing`,
    manageResourceStatus: `/v1/api/resources/manage_status`,
    updateResourceContent: `/v1/api/resources/update`,
    getResourceContent: `/v1/api/resources/entity`,
    getObjectUploadUrl: `/v1/api/resources/upload_object`,
    listResourceObjects: `/v1/api/resources/list_objects`,
    listResourceFolders: `/v1/api/resources/list_folders`,
    listResourceBulkObjectUploads: `/v1/api/resources/list_bulk_object_uploads`,
    listFolderObjects: `/v1/api/resources/folder_objects`,
    labelerStartRequest: `/v1/api/resources/generate_labels`,
    countFolderObjects: `/v1/api/resources/folder_objects_count`,
    viewResourceObject: `/v1/api/resources/view_object`,
    getObjectMetadata: `/v1/api/resources/object_metadata`,
    updateObjectMetadata: `/v1/api/resources/update_metadata`,
    deleteResourceObject: `/v1/api/resources/delete_object`,
    searchImageResource: `/v1/api/search/search/text`,
    uploadBulkImages: `v1/api/resources/upload_objects`,
    resourceStatistics: `v1/api/resources/statistics`,

    // prompt services
    promptList: `/v1/api/prompt/listing`,
    addPromptData: `/v1/api/prompt/add`,
    updatePromptData: `/v1/api/prompt/update`,
    findPromptData: `/v1/api/prompt/find`,
    fetchPromptEnhance: `/v1/api/prompt/enhance`,
    promptDataStats: `/v1/api/prompt/statistics`,

    // image segmentation
    imageMask: `/v1/api/resources/object_masks`,
    imageLabels: `/v1/api/resources/object_labels`,
  };
};
