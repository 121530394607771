// Chakra Imports
import { FaPlus } from "react-icons/fa";
import { TiWorld } from "react-icons/ti";
import { FaRegEdit } from "react-icons/fa";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import {
  Button,
  Image,
  Text,
  Box,
  Spinner,
  Flex,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

// Component Imports
import {
  defaultGreyColor,
  defaultThemeColor,
  greyBgColor,
} from "../../../../constants/index";
import dummyLogo from "../../../../image/dummy-logo.jpg";
import { getEmailId } from "../../../../utils/localStorageIndex";
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import AddBusinessForm from "./AddBusinessForm";
import BusinessLogoPhoto from "./BusinessLogo"
import { fetchBusinessInformation } from "../../../../services/businessServices";
import { setBusinessInfoStatus } from "../../../../store/actions/topNotificationActions";

function ViewBusinessInfo() {
  const toast = useToast();
  const dispatch = useDispatch();
  const [loading, isLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [businessData, setBusinessData] = useState({});
  const [selectedDetailsType, onSelectedDetailsType] = useState("");

  useEffect(() => {
    fetchBusinessData();
  }, []);

  // Todo:Fetch Business Details
  const fetchBusinessData = () => {
    var apiData = { email: getEmailId() };
    fetchBusinessInformation(apiData)
      .then((res) => {
        if (res.result && res.data[0] !== null && res.data.length) {
          isLoading(false);
          if (res.data[0].business_uuid) {
            setBusinessData(res?.data[0]);
            dispatch(setBusinessInfoStatus(true));
            toast(toastFunctionToaster(res?.message, "success"));
          }
        } else {
          isLoading(false);
          dispatch(setBusinessInfoStatus(false));
          toast(toastFunctionToaster(res?.message, "error"));
        }
      })
      .catch((err) => {
        isLoading(false);
      });
  };

  // Todo:Update Business Modal And Details
  const handleBusinessDetails = (data) => {
    if (data) {
      fetchBusinessData();
      onClose();
    }
  };

  return (
    <Box>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" h="50vh">
          <Spinner size="xl" color={"#fff"} />
        </Flex>
      ) : Object.keys(businessData).length ? (
        <Box p="15px">
          <Box
            as="span"
            flex="1"
            textAlign="left"
            color="#fff"
            fontWeight={"600"}
            fontSize="20px"
          >
            Business Details
          </Box>
          <Flex w="100%" mt="3">
            <Box w="30%" mr="3" bg="#151117" borderRadius={"20px"} p="20px">
              <Flex justifyContent={"center"}>
                <Box h="120px" w="120px">
                  <Image
                    h="100%"
                    w="100%"
                    objectFit="contain"
                    alt="Business Logo"
                    src={
                      businessData.business_logo?.length
                        ? businessData.business_logo[0].url
                        : dummyLogo
                    }
                    borderRadius={"50%"}
                    bg="#fff"
                    p="2"
                  ></Image>
                </Box>
                <Box ml="4">
                  <FaRegEdit
                    color={defaultGreyColor}
                    fontSize={"20px"}
                    cursor={"pointer"}
                    onClick={() => {
                      onSelectedDetailsType("basic_info");
                      onOpen();
                    }}
                  />
                </Box>
              </Flex>
              <Box className="businessDetails" textAlign={"center"} mt="5">
                <Text fontSize={"22px"} color="#fff">
                  {businessData?.business_name
                    ? businessData?.business_name
                    : ""}
                </Text>
                <Flex alignItems={"center"} justifyContent={"center"} mt="2">
                  <Box>
                    <TiWorld color="#fff" fontSize={"20px"} />
                  </Box>
                  <Text fontSize={"16px"} color={defaultGreyColor} ml="2">
                    {businessData?.business_url
                      ? businessData?.business_url
                      : "-"}
                  </Text>
                </Flex>
                <Flex alignItems={"center"} justifyContent={"center"} mt="2">
                  <Box>
                    <AiOutlineMail color="#fff" fontSize={"20px"} />
                  </Box>
                  <Text fontSize={"16px"} color={defaultGreyColor} ml="2">
                    {businessData?.business_email
                      ? businessData?.business_email
                      : "-"}
                  </Text>
                </Flex>
                <Flex alignItems={"center"} justifyContent={"center"} mt="2">
                  <Box>
                    <AiOutlinePhone color="#fff" fontSize={"20px"} />
                  </Box>
                  <Text fontSize={"16px"} color={defaultGreyColor} ml="2">
                    {businessData?.phone ? businessData?.phone : "-"}
                  </Text>
                </Flex>
              </Box>
            </Box>
            <Box w="40%" mr="3" bg="#151117" borderRadius={"20px"} p="20px">
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                mt="2"
              >
                <Text fontSize={"22px"} color="#fff">
                  Business Address
                </Text>
                <Box>
                  <FaRegEdit
                    color={defaultGreyColor}
                    fontSize={"20px"}
                    cursor={"pointer"}
                    onClick={() => {
                      onSelectedDetailsType("address_info");
                      onOpen();
                    }}
                  />
                </Box>
              </Flex>
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                mt="4"
              >
                <Text fontSize={"16px"} color="#fff">
                  Address
                </Text>
                <Text fontSize={"16px"} color={defaultGreyColor} ml="2">
                  {businessData?.Address_line1
                    ? businessData?.Address_line1
                    : ""}{" "}
                  {businessData?.Address_line2
                    ? businessData?.Address_line2
                    : ""}
                </Text>
              </Flex>
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                mt="3"
              >
                <Text fontSize={"16px"} color="#fff">
                  Country
                </Text>
                <Text fontSize={"16px"} color={defaultGreyColor} ml="2">
                  {businessData?.country ? businessData?.country : "-"}
                </Text>
              </Flex>
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                mt="3"
              >
                <Text fontSize={"16px"} color="#fff">
                  State
                </Text>
                <Text fontSize={"16px"} color={defaultGreyColor} ml="2">
                  {businessData?.state ? businessData?.state : "-"}
                </Text>
              </Flex>
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                mt="3"
              >
                <Text fontSize={"16px"} color="#fff">
                  City
                </Text>
                <Text fontSize={"16px"} color={defaultGreyColor} ml="2">
                  {businessData?.city ? businessData?.city : "-"}
                </Text>
              </Flex>
              <Flex
                alignItems={"center"}
                justifyContent={"space-between"}
                mt="3"
              >
                <Text fontSize={"16px"} color="#fff">
                  Zipcode
                </Text>
                <Text fontSize={"16px"} color={defaultGreyColor} ml="2">
                  {businessData?.zip_code ? businessData?.zip_code : "-"}
                </Text>
              </Flex>
            </Box>
            <Box w="30%" bg="#151117" borderRadius={"20px"} p="20px">
              <BusinessLogoPhoto objectUploaded={fetchBusinessData}/>
            </Box>
          </Flex>
        </Box>
      ) : (
        <Box p="15px">
          <Flex>
            <Text
              as="span"
              flex="1"
              textAlign="left"
              color="#fff"
              fontWeight={"600"}
              fontSize="20px"
            >
              Business Details
            </Text>
            <Button
              bg="white"
              size="sm"
              leftIcon={<FaPlus />}
              onClick={() => {
                onSelectedDetailsType("basic_info");
                onOpen();
              }}
            >
              Add Business Details
            </Button>
          </Flex>
          <Box bg="#1c181e" mt="3" p="10px" borderRadius={"10px"}>
            <Text mb="5px" fontSize={16} color={defaultThemeColor}>
              You have't added your business details yet. Please add your
              business details here by selecting "Add Business Info".
            </Text>
          </Box>
        </Box>
      )}

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent bg={greyBgColor} borderRadius="20px">
          <ModalHeader color="#fff">Business Info</ModalHeader>
          <ModalCloseButton color="#d7d7d7" />
          <AddBusinessForm
            selectedDetailsType={selectedDetailsType}
            updateBusinessDetails={handleBusinessDetails}
          />
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ViewBusinessInfo;
