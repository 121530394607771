import React, { useEffect, useState } from 'react';
import { defaultThemeColor } from '../../../../constants/index';
import { getEmailId } from '../../../../utils/localStorageIndex';
import { Box, Td, Tr, Flex, Text, Spinner } from '@chakra-ui/react';
import { fetchBusinessesListPaginated } from '../../../../services/businessServices';
import ResponsivePagination from "react-responsive-pagination";

function BusinessList() {
  const [loading, isLoading] = useState(true);
  const [businessData, setBusinessData] = useState([]);
  const [noRecordFlag, setNoRecordFlag] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    let requestBody = {
      email: getEmailId(),
      records_per_page: 0,
      page_id: currentPage,
      records_length: 0,
    };
    fetchBusinessesListPaginated(requestBody)
      .then(res => {
        isLoading(false);
        if (res.data.length > 0) {
          setBusinessData(res.data);
          setTotalPages(res.data[0].total_pages);
          setNoRecordFlag(false);
        } else {
          setBusinessData([]);
          setNoRecordFlag(true);
        }
      })
      .catch(err => {
        isLoading(false);
        setNoRecordFlag(true);
      });
  }, []);

  function renderBusinessDataListMain(){
    if (businessData && businessData.length > 0){
      var renderBusinessDataList = businessData.map((data, index) => (
        <Box
          m="1"
          p="5px"
          bg="#1c181e"
          borderLeftWidth="5px"
          borderColor={defaultThemeColor}
          key={index}
        >
          <Flex alignItems="center">
            <Box w="30%" alignItems="top">
              <Text mb="5px" fontSize={16}>{data.Business_name ? data.Business_name : '-'}</Text>
              <Text mb="5px" fontSize={10}>Added By: {data.added_by ? data.added_by : '-'}</Text>          
            </Box>
            <Box ml="5rem">
              <Text mb="5px">{data.Address_line1 ? data.Address_line1 : '-'}</Text>
              <Text mb="5px"> {data.Address_line2 ? data.Address_line2 : '-'}</Text>
              <Text mb="5px"> {data.city ? data.city : '-'}, {data.state ? data.state : '-'}, {data.zip_code ? data.zip_code : '-'}, {data.country ? data.country : '-'}</Text>
              <Text mb="5px"> {data.phone ? data.phone : '-'}</Text>
            </Box>
          </Flex>
          <Flex alignItems="center" flexWrap="wrap" mt="1px" background={'#252130'}>
            {data?.admin_list !== null &&
            data?.admin_list !== undefined &&
            data?.admin_list.length ? (
              <>
                <Text mr="5px" p={2}>Accounts Administrators:</Text>
                {JSON.parse(data.admin_list).map((adminListData, index) => (
                  <Text
                    p={1}
                    mr="2px"
                    mb="2px"
                    bg={defaultThemeColor}
                    color="#fff"
                    borderWidth="0.5px"
                    borderRadius="5px"
                    key={adminListData.uuid}
                    fontSize="11px"
                  >
                    {adminListData.email}
                  </Text>
                ))}
              </>
            ) : null}
          </Flex>
        </Box>
      ));
      return renderBusinessDataList;
    } 
  }

  return (
    <>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" mt="10">
          <Spinner size="xl" />
        </Flex>
      ) : noRecordFlag ? (
        <Box
            m="1"
            p="5px"
            bg="#1c181e"
            borderLeftWidth="5px"
            borderColor={'red'}
          >
          <Text padding="10px" color="white" fontSize={16}>
            There are no business registered so far.
          </Text>
        </Box>
      ) : (
        <Box>
          <Box
            h="calc(100vh - 35vh)"
            overflow="auto"
            css={{
              '&::-webkit-scrollbar': {
                width: '4px',
              },
              '&::-webkit-scrollbar-track': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                background: '#fff',
                borderRadius: '24px',
              },
            }}
          >
            {renderBusinessDataListMain()}
          </Box>
          <Box mt="4">
            <ResponsivePagination
              current={currentPage}
              total={totalPages}
              onPageChange={(pageNumber) => {
                setCurrentPage(pageNumber);
              }}
            />
          </Box>
        </Box>
      )}
    </>
  );
}

export default BusinessList;
