// Chakra Imports
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Text,
  Flex,
  Radio,
  Tooltip,
  Slider,
  Textarea,
  Checkbox,
  SliderTrack,
  RadioGroup,
  SliderThumb,
  NumberInput,
  NumberInputField,
  SliderFilledTrack,
} from "@chakra-ui/react";

// Component Imports
import PromptSearch from "../BaseConfig/PromptSearch";
import PromptModal from "../../../../Prompt/PromptModal";
import titleConstants from "../../../../../../constants/TitleConstant";
import tooltipConstants from "../../../../../../constants/TooltipConstant";
import { defaultThemeColor, greyBgColor } from "../../../../../../constants";

function AdvanceConfig({
  workflowType,
  modelEntity,
  modelCode,
  type,
  selectedKeys,
  setSelectedKeys,
}) {
  const dispatch = useDispatch();
  const [batchType, setBatchType] = useState("");
  const workflowDetails = useSelector((store) => store?.workflowDesignHeader);
  const workflowDetailData = workflowDetails?.workflowObject;
  const [useImageSeedFlag, setUseImageSeedFlag] = useState(
    workflowDetailData?.imageSeed !== null
  );

  useEffect(() => {
    setUseImageSeedFlag(workflowDetailData?.imageSeed !== null);
  }, [workflowDetailData?.imageSeed]);

  useEffect(() => {
    setBatchType(type);
  }, [type]);

  // Todo:Set Prompt Details From Prompt Search
  const setPromptDetailsBySearch = (data) => {
    if (data?.length) {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: { key: "negativePrompt", value: data },
      });
    }
  };

  function setImageSeedAndUseFlag(flag) {
    setUseImageSeedFlag(flag);
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "imageSeed", value: flag ? 0 : null },
    });
  }

  // Function to handle checkbox change
  const handleCheckboxChange = (event, mix, max, step) => {
    const key = event.target.value;
    const isChecked = event.target.checked;

    setSelectedKeys((prevSelectedKeys) => {
      if (isChecked) {
        // Add key to the array if checked
        if (!prevSelectedKeys.some((obj) => obj.key === key)) {
          return [...prevSelectedKeys, { key, min: mix, max: max, step: step }];
        }
      } else {
        // Remove key from the array if unchecked
        return prevSelectedKeys.filter(
          (selectedKey) => selectedKey.key !== key
        );
      }
      return prevSelectedKeys; // Return unchanged array if no change is needed
    });
  };

  return (
    <Box
      h="calc(100% - 3%)"
      overflow="auto"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: { greyBgColor },
          borderRadius: "24px",
        },
      }}
    >
      {workflowDetailData?.modelEntity === "controlnet" ||
      workflowDetailData?.modelEntity === "inpaint_controlnet" ? (
        !batchType ? (
          <Box bg={greyBgColor} borderRadius={"10px"} mb="4">
            <Text
              fontSize="14px"
              color="#fff"
              p="2"
              borderBottom={"1px"}
              borderBottomColor={"#d7d7d7"}
              fontWeight={"600"}
            >
              {titleConstants?.guidanceTypeLabel}
            </Text>
            <Box p="2" color="#fff">
              <RadioGroup
                onChange={(v) => {
                  dispatch({
                    type: "SET_WORKFLOW_OBJECT",
                    payload: { key: "guidanceType", value: v },
                  });
                }}
                value={workflowDetailData?.guidanceType}
                isrequired="true"
                mt="1"
              >
                <Radio value="canny" mr="4" color="#fff">
                  Detailed Model
                </Radio>
                <Radio value="lineart" mr="4" color="#fff">
                  Soft Line Art
                </Radio>
                <Radio value="sketch" mr="4" color="#fff">
                  Rough Sketch
                </Radio>
                <Radio value="depth" color="#fff">
                  Massing Model
                </Radio>
              </RadioGroup>
            </Box>
          </Box>
        ) : null
      ) : null}

      {/* Advance Settings */}
      {workflowDetails?.stabilityValue ? null : (
        <Box bg={greyBgColor} borderRadius={"10px"} mb="4">
          {!batchType ? (
            <Text
              fontSize="14px"
              color="#fff"
              p="2"
              borderBottom={"1px"}
              borderBottomColor={"#d7d7d7"}
              fontWeight={"600"}
            >
              Advanced Parameters
            </Text>
          ) : null}

          <Box p="2">
            <Flex justifyContent={"space-between"}>
              <Tooltip
                placement="left-end"
                label={tooltipConstants?.speedQualityLabel}
              >
                <Text cursor={"pointer"} fontWeight={"600"} color="#fff">
                  {titleConstants?.speedQualityLabel}{" "}
                  {workflowDetailData?.inferenceSteps}
                </Text>
              </Tooltip>
              {batchType ? (
                <Checkbox
                  color="#fff"
                  onChange={(e) => {
                    handleCheckboxChange(e, 2, 12, 1);
                  }}
                  value={"inferenceSteps"}
                >
                  Auto
                </Checkbox>
              ) : null}
            </Flex>
            <Slider
              onChange={(v) => {
                dispatch({
                  type: "SET_WORKFLOW_OBJECT",
                  payload: { key: "inferenceSteps", value: v },
                });
              }}
              isDisabled={selectedKeys?.some(
                (obj) => obj.key === "inferenceSteps"
              )}
              min={2}
              max={12}
              step={1}
              defaultValue={4}
              value={workflowDetailData?.inferenceSteps}
            >
              <SliderTrack bg="#fff">
                <Box position="relative" right={10} />
                <SliderFilledTrack
                  bg={
                    workflowDetailData?.inferenceSteps > 8
                      ? "red.500"
                      : defaultThemeColor
                  }
                />
              </SliderTrack>
              <SliderThumb boxSize={3} />
            </Slider>
          </Box>
          <Box p="2">
            <Flex justifyContent={"space-between"}>
              <Tooltip
                placement="left-end"
                label={tooltipConstants?.promptStrengthLabel}
              >
                <Text cursor={"pointer"} fontWeight={"600"} color="#fff">
                  {titleConstants?.promptStrengthLabel}{" "}
                  {workflowDetailData?.guidanceScale}
                </Text>
              </Tooltip>
              {batchType ? (
                <Checkbox
                  color="#fff"
                  onChange={(e) => handleCheckboxChange(e, 1.0, 2.0, 0.1)}
                  value={"guidanceScale"}
                >
                  Auto
                </Checkbox>
              ) : null}
            </Flex>
            <Slider
              onChange={(v) => {
                dispatch({
                  type: "SET_WORKFLOW_OBJECT",
                  payload: { key: "guidanceScale", value: v },
                });
              }}
              min={1.0}
              max={2.0}
              step={0.1}
              defaultValue={1.2}
              value={workflowDetailData?.guidanceScale}
              isDisabled={selectedKeys?.some(
                (obj) => obj.key === "guidanceScale"
              )}
            >
              <SliderTrack bg="#fff">
                <Box position="relative" right={10} />
                <SliderFilledTrack bg={defaultThemeColor} />
              </SliderTrack>
              <SliderThumb boxSize={3} />
            </Slider>
          </Box>

          {/* Todo:Just Commented Image Sharpness Can be used in future so can uncommend */}
          {/* <Box p="2">
            <Flex justifyContent={"space-between"}>
              <Tooltip
                placement="left-end"
                label={tooltipConstants?.imageSharpnessLabel}
              >
                <Text cursor={"pointer"} fontWeight={"600"} color="#fff">
                  {titleConstants?.imageSharpnessLabel} {workflowDetailData?.imageSharpness}
                </Text>
              </Tooltip>
              {batchType ? (
                <Checkbox
                  color="#fff"
                  onChange={(e) => handleCheckboxChange(e, 0, 0.03, 0.001)}
                  value={"imageSharpness"}
                >
                  Auto
                </Checkbox>
              ) : null}
            </Flex>
            <Slider
              onChange={(v) => {
                dispatch({
                  type: "SET_WORKFLOW_OBJECT",
                  payload: { key: "imageSharpness", value: v },
                });
              }}
              isDisabled={selectedKeys?.some(
                (obj) => obj.key === "imageSharpness"
              )}
              min={0}
              max={0.03}
              step={0.001}
              defaultValue={0.002}
              value={workflowDetailData?.imageSharpness}
            >
              <SliderTrack bg="#fff">
                <Box position="relative" right={10} />
                <SliderFilledTrack bg={defaultThemeColor} />
              </SliderTrack>
              <SliderThumb boxSize={3} />
            </Slider>
          </Box> */}

          {workflowDetailData?.modelEntity === "text_to_image" ? (
            <>
              <Box p="2">
                <Flex justifyContent={"space-between"}>
                  <Tooltip
                    placement="left-end"
                    label={tooltipConstants?.imageWidthLabel}
                  >
                    <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                      Image Width {workflowDetailData?.imageWidth}
                    </Text>
                  </Tooltip>
                  {batchType ? (
                    <Checkbox
                      color="#fff"
                      onChange={(e) => handleCheckboxChange(e, 512, 1536, 64)}
                      value={"imageWidth"}
                    >
                      Auto
                    </Checkbox>
                  ) : null}
                </Flex>
                <Slider
                  onChange={(v) => {
                    dispatch({
                      type: "SET_WORKFLOW_OBJECT",
                      payload: { key: "imageWidth", value: v },
                    });
                  }}
                  isDisabled={selectedKeys?.some(
                    (obj) => obj.key === "imageWidth"
                  )}
                  min={512}
                  max={1536}
                  step={64}
                  defaultValue={1024}
                  value={workflowDetailData?.imageWidth}
                >
                  <SliderTrack bg="#fff">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack bg={defaultThemeColor} />
                  </SliderTrack>
                  <SliderThumb boxSize={3} />
                </Slider>
              </Box>
              <Box p="2">
                <Flex justifyContent={"space-between"}>
                  <Tooltip
                    placement="left-end"
                    label={tooltipConstants?.imageHeightLabel}
                  >
                    <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                      Image Height {workflowDetailData?.imageHeight}
                    </Text>
                  </Tooltip>
                  {batchType ? (
                    <Checkbox
                      color="#fff"
                      onChange={(e) => handleCheckboxChange(e, 512, 1536, 64)}
                      value={"imageHeight"}
                    >
                      Auto
                    </Checkbox>
                  ) : null}
                </Flex>
                <Slider
                  onChange={(v) => {
                    dispatch({
                      type: "SET_WORKFLOW_OBJECT",
                      payload: { key: "imageHeight", value: v },
                    });
                  }}
                  isDisabled={selectedKeys?.some(
                    (obj) => obj.key === "imageHeight"
                  )}
                  min={512}
                  max={1536}
                  step={64}
                  defaultValue={1024}
                  value={workflowDetailData?.imageHeight}
                >
                  <SliderTrack bg="#fff">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack bg={defaultThemeColor} />
                  </SliderTrack>
                  <SliderThumb boxSize={3} />
                </Slider>
              </Box>
            </>
          ) : null}

          {workflowDetailData?.modelEntity === "controlnet" ||
          workflowDetailData?.modelEntity === "inpaint_controlnet" ? (
            <Box p="2">
              <Flex justifyContent={"space-between"}>
                <Tooltip
                  placement="left-end"
                  label={tooltipConstants?.controlStrengthLabel}
                >
                  <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                    {titleConstants?.controlStrengthLabel}{" "}
                    {workflowDetailData?.conditionScale}
                  </Text>
                </Tooltip>
                {batchType ? (
                  <Checkbox
                    color="#fff"
                    onChange={(e) => handleCheckboxChange(e, 0, 1, 0.1)}
                    value={"conditionScale"}
                  >
                    Auto
                  </Checkbox>
                ) : null}
              </Flex>
              <Slider
                onChange={(v) => {
                  dispatch({
                    type: "SET_WORKFLOW_OBJECT",
                    payload: { key: "conditionScale", value: v },
                  });
                }}
                isDisabled={selectedKeys?.some(
                  (obj) => obj.key === "conditionScale"
                )}
                min={0.0}
                max={1.0}
                step={0.1}
                defaultValue={0.8}
                value={workflowDetailData?.conditionScale}
              >
                <SliderTrack bg="#fff">
                  <Box position="relative" right={10} />
                  <SliderFilledTrack bg={defaultThemeColor} />
                </SliderTrack>
                <SliderThumb boxSize={3} />
              </Slider>
            </Box>
          ) : null}
          {(workflowDetailData?.modelEntity === "inpaint_controlnet" ||
            workflowDetailData?.modelEntity === "diffedit") &&
          workflowDetailData?.modelCode !== 5 ? (
            <>
              <Box p="2">
                <Flex justifyContent={"space-between"}>
                  <Tooltip
                    placement="left-end"
                    label={tooltipConstants?.inpaintStrengthLabel}
                  >
                    <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                      {titleConstants?.inpaintStrengthLabel}{" "}
                      {workflowDetailData?.inpaintStrength}
                    </Text>
                  </Tooltip>
                  {batchType ? (
                    <Checkbox
                      color="#fff"
                      onChange={(e) => handleCheckboxChange(e, 0.1, 1, 0.1)}
                      value={"inpaintStrength"}
                    >
                      Auto
                    </Checkbox>
                  ) : null}
                </Flex>
                <Slider
                  onChange={(v) => {
                    dispatch({
                      type: "SET_WORKFLOW_OBJECT",
                      payload: { key: "inpaintStrength", value: v },
                    });
                  }}
                  isDisabled={selectedKeys?.some(
                    (obj) => obj.key === "inpaintStrength"
                  )}
                  min={0.1}
                  max={1.0}
                  step={0.1}
                  defaultValue={1.0}
                  value={workflowDetailData?.inpaintStrength}
                >
                  <SliderTrack bg="#fff">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack bg={defaultThemeColor} />
                  </SliderTrack>
                  <SliderThumb boxSize={3} />
                </Slider>
              </Box>
              <Box p="2">
                <Flex justifyContent={"space-between"}>
                  <Tooltip
                    placement="left-end"
                    label={tooltipConstants?.maskCropPaddingLabel}
                  >
                    <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                      {titleConstants?.maskCropPaddingLabel}{" "}
                      {workflowDetailData?.maskCropPadding}
                    </Text>
                  </Tooltip>
                  {batchType ? (
                    <Checkbox
                      color="#fff"
                      onChange={(e) => handleCheckboxChange(e, 0, 1, 0.1)}
                      value={"maskCropPadding"}
                    >
                      Auto
                    </Checkbox>
                  ) : null}
                </Flex>
                <Slider
                  onChange={(v) => {
                    dispatch({
                      type: "SET_WORKFLOW_OBJECT",
                      payload: { key: "maskCropPadding", value: v },
                    });
                  }}
                  isDisabled={selectedKeys?.some(
                    (obj) => obj.key === "maskCropPadding"
                  )}
                  min={0.0}
                  max={1.0}
                  step={0.1}
                  defaultValue={0.6}
                  value={workflowDetailData?.maskCropPadding}
                >
                  <SliderTrack bg="#fff">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack bg={defaultThemeColor} />
                  </SliderTrack>
                  <SliderThumb boxSize={3} />
                </Slider>
              </Box>
            </>
          ) : null}
          {workflowDetailData.reference_name?.some((v) => {
            return v?.length;
          }) || workflowDetailData.neg_reference_name?.length ? (
            <>
              <Box p="2">
                <Flex justifyContent={"space-between"}>
                  <Tooltip
                    placement="left-end"
                    label={tooltipConstants?.refAdainScaleLabel}
                  >
                    <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                      {titleConstants?.refAdainScaleLabel}{" "}
                      {workflowDetailData?.refAdainScale}
                    </Text>
                  </Tooltip>
                  {batchType ? (
                    <Checkbox color="#fff" defaultChecked>
                      Auto
                    </Checkbox>
                  ) : null}
                </Flex>
                <Slider
                  onChange={(v) => {
                    dispatch({
                      type: "SET_WORKFLOW_OBJECT",
                      payload: { key: "refAdainScale", value: v },
                    });
                  }}
                  isDisabled={selectedKeys?.some(
                    (obj) => obj.key === "refAdainScale"
                  )}
                  min={0.0}
                  max={1.0}
                  step={0.1}
                  defaultValue={0.5}
                  value={workflowDetailData?.refAdainScale}
                >
                  <SliderTrack bg="#fff">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack bg={defaultThemeColor} />
                  </SliderTrack>
                  <SliderThumb boxSize={3} />
                </Slider>
              </Box>
              <Box p="2">
                <Flex justifyContent={"space-between"}>
                  <Tooltip
                    placement="left-end"
                    label={tooltipConstants?.refAttnScaleLabel}
                  >
                    <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                      {titleConstants?.refAttnScaleLabel}{" "}
                      {workflowDetailData?.refAttnScale}
                    </Text>
                  </Tooltip>
                  {batchType ? (
                    <Checkbox color="#fff" defaultChecked>
                      Auto
                    </Checkbox>
                  ) : null}
                </Flex>
                <Slider
                  onChange={(v) => {
                    dispatch({
                      type: "SET_WORKFLOW_OBJECT",
                      payload: { key: "refAttnScale", value: v },
                    });
                  }}
                  isDisabled={selectedKeys?.some(
                    (obj) => obj.key === "refAttnScale"
                  )}
                  min={0.0}
                  max={1.0}
                  step={0.1}
                  defaultValue={0.5}
                  value={workflowDetailData?.refAttnScale}
                >
                  <SliderTrack bg="#fff">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack bg={defaultThemeColor} />
                  </SliderTrack>
                  <SliderThumb boxSize={3} />
                </Slider>
              </Box>
            </>
          ) : null}
        </Box>
      )}
      {/* Seed To Positive */}
      {!batchType ? (
        <Box bg={greyBgColor} borderRadius={"10px"} mb="4">
          <Box p="2">
            <Checkbox
              fontSize="14px"
              fontWeight={"600"}
              colorScheme="green"
              color="#fff"
              isChecked={useImageSeedFlag}
              onChange={(e) => setImageSeedAndUseFlag(!useImageSeedFlag)}
            >
              <Tooltip
                placement="left-end"
                label={tooltipConstants?.imageSeedLabel}
              >
                <Text fontSize={"14px"}>Set Image Seed</Text>
              </Tooltip>
            </Checkbox>
            <NumberInput
              name="image_seed"
              value={
                workflowDetailData?.imageSeed === null
                  ? 0
                  : workflowDetailData?.imageSeed
              }
              onChange={(s, n) => {
                dispatch({
                  type: "SET_WORKFLOW_OBJECT",
                  payload: { key: "imageSeed", value: n },
                });
              }}
              min={0}
              hidden={!useImageSeedFlag}
            >
              <NumberInputField
                borderRadius="10px"
                height={"50px"}
                fontSize="12px"
                borderWidth="1px"
                borderColor="#494552"
                placeholder="Enter Value Here"
                color="#fff"
                w="100%"
                _active={{ borderColor: "none" }}
                _focus={{
                  boxShadow: "none",
                  borderColor: "none",
                }}
                _placeholder={{ color: "#fff" }}
                mt="3"
              />
            </NumberInput>
          </Box>
        </Box>
      ) : null}
      {/* Negative Prompt Data */}
      {!batchType ? (
        <Box>
          <Flex alignItems={"center"}>
            <Text mr="2" fontSize="14px" color="#fff" fontWeight={"600"}>
              Negative Prompt
            </Text>
            <Flex justifyContent={"end"} alignItems={"center"}>
              <PromptModal promptTitleData={workflowDetailData?.negativePrompt} />
              <PromptSearch sendPromptDetails={setPromptDetailsBySearch} />
            </Flex>
          </Flex>
          <Box>
            <Textarea
              _focus={{
                boxShadow: "none",
              }}
              type="text"
              w="100%"
              my="3"
              rows="5"
              placeholder="Enter negative prompt here..."
              color="#fff"
              value={workflowDetailData?.negativePrompt}
              onChange={(e) => {
                dispatch({
                  type: "SET_WORKFLOW_OBJECT",
                  payload: { key: "negativePrompt", value: e.target.value },
                });
              }}
              bg={greyBgColor}
              borderRadius={"10px"}
              border="none"
            />
          </Box>
        </Box>
      ) : null}
    </Box>
  );
}

export default AdvanceConfig;
