// React Chakra Imports
import React, { useEffect, useState } from "react";
import { AiFillEdit } from "react-icons/ai";
import {
  Button,
  FormLabel,
  FormControl,
  Text,
  Input,
  Icon,
  useToast,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
} from "@chakra-ui/react";

// Component Imports
import { themeFontSizeMediumHeading } from "../../../constants";
import { toastFunctionToaster } from "../../../utils/toastFunction";

// Api Services
import { UpdateProjectWorkflow } from "../../../services/projectTemplateService";

function EditWorkflow(props) {
  const toast = useToast();
  const project_uuId = props?.project_uuid;
  const [loading, isLoading] = useState(false);
  const [workflowName, setWorkflowName] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const workflow_uuid = props?.workflowData?.workflow_uuid;
  const model_code = props?.workflowData?.model_code;
  const model_type = props?.workflowData?.model_type;
  const object_info = props?.workflowData?.info?.[0]?.object_name;

  useEffect(() => {
    setWorkflowName(props?.workflowName);
  }, [props]);

  //   Todo:Api to update worflow
  const updateWorkflowDetails = () => {
    isLoading(true);
    if (workflowName?.length === 0) {
      setErrorFlag(true);
      isLoading(false);
    } else {
      setErrorFlag(false);
      let obj = {
        project_uuid: project_uuId,
        workflow_uuid: workflow_uuid,
        workflow_name: workflowName,
        object_info: object_info,
        workflow_model_code: model_code,
        workflow_model_type: model_type,
      };
      UpdateProjectWorkflow(obj)
        .then((res) => {
          isLoading(false);
          if (res?.result) {
            onClose();
            toast(toastFunctionToaster(res?.message, "success"));
            if (props?.returnName) {
              props?.returnWorkflowName(workflowName);
            }
            props?.renderList("true");
          } else {
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          isLoading(false);
          // toast(toastFunctionToaster("Something Went Wrong", "error"));
        });
    }
  };

  // Todo:On Model Close Reset Data
  const onCloseModal = () => {
    onClose();
  };
  return (
    <>
      <Button
        size={"xs"}
        colorScheme="yellow"
        onClick={() => {
          onOpen();
        }}
      >
        <Icon w={4} h={4} as={AiFillEdit} />
      </Button>
      <AlertDialog
        isOpen={isOpen}
        onClose={() => {
          onCloseModal();
        }}
      >
        <AlertDialogOverlay>
          <AlertDialogContent
            bg={"#131415"}
            borderWidth="1px"
            borderColor={"#313131"}
          >
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              color="#fff"
              borderBottom={"1px"}
              borderBottomColor={"#313131"}
            >
              Edit Workflow details
            </AlertDialogHeader>
            <AlertDialogBody borderBottom={"1px"} borderBottomColor={"#313131"}>
              <FormControl isRequired mb={3}>
                <FormLabel fontSize={themeFontSizeMediumHeading} color="#fff">
                  Workflow Name
                </FormLabel>
                <Input
                  placeholder="Enter Workflow Name"
                  height={"50px"}
                  border="none"
                  fontSize="14px"
                  borderColor="#494552"
                  color="#fff"
                  bg="#1c181e"
                  size="sm"
                  value={workflowName}
                  onChange={(e) => {
                    setWorkflowName(e.target.value);
                  }}
                  name="project_name"
                />
                {errorFlag && workflowName?.length === 0 ? (
                  <Text fontSize="sm" color={"red"} mt="1">
                    Workflow Name is required
                  </Text>
                ) : null}
              </FormControl>
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={() => onCloseModal()} size="sm">
                Cancel
              </Button>
              <Button
                ml={3}
                size="sm"
                colorScheme="green"
                onClick={() => updateWorkflowDetails()}
                isDisabled={loading ? true : false}
                isLoading={loading ? true : false}
              >
                Save
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
}

export default EditWorkflow;
