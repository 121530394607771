// React and Chakra Imports
import React, { useEffect, useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";

// Component Imports
import HistoryConfig from "./History/HistoryConfig.js";
import AdvanceConfig from "./AdvanceConfig/AdvanceConfig";
import BaseConfiguration from "./BaseConfig/BaseConfig.js";
import { defaultThemeColor } from "../../../../../constants";

function ProjectWorkspace(props) {
  const [activeTab, setActiveTab] = useState(0);
  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  useEffect(() => {
    setActiveTab(props?.launchType === "relaunch" ? 0 : 0);
  }, [props?.launchType]);

  return (
    <Tabs
      w="100%"
      h="100%"
      borderBottom={"0px"}
      mx="auto"
      onChange={handleTabChange}
      index={activeTab}
    >
      <TabList border="0px" w="100%" h="8%">
        <Tab
          _selected={{
            color: defaultThemeColor,
            fontWeight: 600,
            borderBottomColor: defaultThemeColor,
          }}
          fontSize="16px"
          px="6"
          py="2"
          color="#fff"
        >
          Base
        </Tab>
        <Tab
          _selected={{
            color: defaultThemeColor,
            fontWeight: 600,
            borderBottomColor: defaultThemeColor,
          }}
          fontSize="16px"
          px="6"
          py="2"
          color="#fff"
        >
          Advanced
        </Tab>
        <Tab
          _selected={{
            color: defaultThemeColor,
            fontWeight: 600,
            borderBottomColor: defaultThemeColor,
          }}
          fontSize="16px"
          px="6"
          py="2"
          color="#fff"
        >
          History
        </Tab>
      </TabList>
      <TabPanels mt="4%" h="91%">
        <TabPanel p="0" h="100%">
          <BaseConfiguration
            workflowType={props?.currentWorkflowType}
            modelEntity={props?.currentModelEntity}
            modelCode={props?.currentModelCode}
          />
        </TabPanel>
        <TabPanel p="0" h="100%">
          <AdvanceConfig
            workflowType={props?.currentWorkflowType}
            modelEntity={props?.currentModelEntity}
            modelCode={props?.currentModelCode}
          />
        </TabPanel>
        <TabPanel p="0" h="100%">
          <HistoryConfig />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export default ProjectWorkspace;
