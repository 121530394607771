import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Flex,
  Progress,
  Icon,
  Text, Wrap,
  Select, Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb, Accordion, AccordionItem, AccordionButton,  AccordionPanel,  AccordionIcon,
  SliderMark,
  Grid, GridItem, Image, Tabs, TabList, TabPanels, Tab, TabPanel,
  Img,
  Button,
  Switch,
  Center,
  IconButton,
  SimpleGrid,
  HStack,
  VStack,
  Spinner,
  useToast,
  AlertDialog, AlertDialogBody, AlertDialogHeader, AlertDialogFooter, AlertDialogContent, AlertDialogOverlay,
  Badge,
  Textarea,
  Divider,
  WrapItem,
} from '@chakra-ui/react';
import {
  AiOutlineCodeSandbox, AiOutlineMinusCircle, AiOutlinePlusCircle, AiOutlineDelete,
} from 'react-icons/ai';
import { useNavigate, useLocation } from 'react-router-dom';
import { renderWorkflowTitle } from '../../../constants/Workflow';
import { getEmailId } from '../../../utils/localStorageIndex';
import {
    CollectSharedWorkflows,
} from '../../../services/projectTemplateService';
import 'react-responsive-pagination/themes/classic.css';
import ResponsivePagination from 'react-responsive-pagination';

function SharedWorkflows() {
    const navigate = useNavigate();
    const onlyFetchOnCurrentPageChangeRef = useRef(false);
    const { state } = useLocation();
    const toast = useToast();
    const email = getEmailId();
    const [loading, isLoading] = useState(false);
    const [currentProjectUuid, setCurrentProjectUuid] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [fullProjectWorkflows, setFullProjectWorkflows] = useState([]);

    useEffect(() => {
        if (!onlyFetchOnCurrentPageChangeRef.current)
        {
          onlyFetchOnCurrentPageChangeRef.current = true;
          return;
        }
        sharedWorkflowListing();
    }, [currentPage]);

    const sharedWorkflowListing = () => {
        if (email != null || email != undefined){
            let objBody = {
                email: email,
                records_per_page: 10,
                page_id: currentPage
            };
            isLoading(true);
            CollectSharedWorkflows(objBody)
            .then(res => {
                isLoading(false);
                if (res?.data?.length > 0) {
                    setFullProjectWorkflows(res.data[0].pagination_data);
                    setTotalPages(res.data[0].pagination_data[0].total_pages);
                } else {
                    setFullProjectWorkflows([]);
                    toast({
                        title: 'Shared workflow list is empty', status: 'error', isClosable: true, duration: 3000,
                    })
                }
            })
            .catch(err => {
                isLoading(false);
                setFullProjectWorkflows([]);
                toast({
                    title: 'Shared workflow list is empty', status: 'error', isClosable: true, duration: 3000,
                })
            });
        }
    }

    const allWorkflowsRendering = () => {
        if (fullProjectWorkflows && fullProjectWorkflows.length > 0){
            var wf = fullProjectWorkflows[0].records
            if (wf && wf.length > 0){
                var wfList = wf.map(data => (
                    <GridItem w={'100%'} bg={'gray.900'} rounded={'md'} borderWidth="0.5px" borderColor="#494552">
                        <Box w={'100%'} h={'40px'} p={2} borderBottomWidth="0.5px" borderColor="#494552">
                            <Center w={'100%'}>
                                <Text fontSize={'lg'}>
                                    {renderWorkflowTitle(data.model_code, data.model_type)}
                                </Text>
                            </Center>
                        </Box>
                        <Box w={'100%'} p={2} alignItems='center'
                            bg={'gray.700'}>
                                <Center w={'100%'}>
                                <Image src={data.info[0].url} height={200}></Image>
                            </Center>
                        </Box>
                        <VStack w={'100%'} p={2}>
                            <HStack w={'100%'}>
                                <Box w={'50%'}>
                                    <Text color={'gray.500'}>Started: <b>{data.started}</b></Text>
                                </Box>
                                <Box w={'50%'}>
                                    <Text color={'gray.500'}>Completed: <b>{data.updated}</b></Text>
                                </Box>
                            </HStack>
                            <HStack w={'100%'} p={2}>
                                <Box w={'50%'}>
                                    {data.shared_by != null
                                    ?<Text>Shared By: <b>{data.shared_by}</b></Text>:null}
                                </Box>
                                <Box w={'100%'}></Box>
                                <Box w={'50%'} alignItems={'end'}>
                                    {data.project_uuid != null
                                    ?<Button size={'xs'} bg={'gray.600'} alignSelf={'end'}
                                        onClick={
                                            () => navigate('/business/playground/create', {
                                                    state: {
                                                        launcher: 'relaunch',
                                                        modelDetails: {
                                                            modelCode: data.model_code,
                                                            modelEntity: data.model_type,
                                                        },
                                                        project_uuid: data.project_uuid,
                                                        workflow_uuid: data.workflow_uuid,
                                                        object: data.info,
                                                        workflow_state: 'existing',
                                                        isShared: true
                                                    },
                                                })
                                            }
                                        >
                                        Relaunch Workflow
                                    </Button>:null}
                                </Box>
                            </HStack>
                        </VStack>
                    </GridItem>
                ));
                return wfList;
            }
        }
    }


    return (
        <Box w="100%">
            <Flex
                w="100%"
                p="10px"
                color="white"
                borderBottomWidth="0.5px"
                borderColor="#494552"
                justifyContent="space-between"
                alignItems="center"
            >
                <Flex>
                <Icon
                    w={8}
                    h={8}
                    color={'#ee7733'}
                    as={AiOutlineCodeSandbox}
                    mr="10px"
                ></Icon>
                <Text fontSize={18}>Shared {'>'} Workflows </Text>
                </Flex>
                <Flex alignItems="center">
                {/* <Text mr="10px" color="#d7d7d7">
                    Last Updated: 12:29:47PM
                </Text> */}
                </Flex>
            </Flex>
            <Box w={'100%'} p={1}>
                <Flex
                    w={'100%'}
                    p="2"
                    borderWidth="0.5px"
                    borderColor="#494552"
                    borderRadius="10px"
                    color="white"
                    alignItems="center"
                    justifyContent="space-between"
                    >
                    <HStack w={'100%'}>
                        <Box w={'50px'}>
                            {fullProjectWorkflows && fullProjectWorkflows.length > 0
                            ?<Text pl={3} fontSize={'3xl'}>{fullProjectWorkflows[0].total_records}</Text>
                            :null}
                        </Box>
                        <Box w={'100%'} borderLeftWidth="0.5px"
                            pl={5}   borderColor="#494552">
                            <Text mb="5px" fontSize="16px">
                            Selected workflows for the given project
                            </Text>
                            <Text fontSize="12px">
                            All the workflows for this project will be listed here..
                            </Text>
                        </Box>
                        <Button w={'250px'} ml={2} onClick={() => sharedWorkflowListing()}
                            _hover={{'bg': 'gray.400'}}
                            size={'sm'} bg={'gray.600'} color={'gray.300'}>
                            Load Shared Workflows
                        </Button>
                    </HStack>
                </Flex>
                <Flex
                    mt={2}
                    w={'100%'}
                    p="2"
                    borderWidth="0.5px"
                    borderColor="#494552"
                    borderRadius="10px"
                    color="white"
                    // alignItems="center"
                    justifyContent="space-between"
                    h="calc(100vh - 280px)"
                    overflow="auto"
                    css={{
                        '&::-webkit-scrollbar': {
                        width: '4px',
                        },
                        '&::-webkit-scrollbar-track': {
                        width: '6px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                        background: '#fff',
                        borderRadius: '24px',
                        },
                    }}>
                    <Box w={'100%'}>
                        {loading
                        ?<Text>Loading workflows...</Text>:null}
                        <Grid w={'100%'} templateColumns='repeat(3, 1fr)' gap={6}>
                            {allWorkflowsRendering()}
                        </Grid>
                        {fullProjectWorkflows && fullProjectWorkflows.length > 0
                            && fullProjectWorkflows[0].total_records == 0
                        ?<Box mt={3} w="100%" borderWidth="0.5px" borderColor="#494552" rounded={'md'} p={10}>
                            <Text fontSize={14}>This project does not have any workflow associated..</Text>
                        </Box>:null}
                    </Box>
                </Flex>
                <Flex w={'100%'} alignItems="center" py={2}>
                    <Center w={'100%'}>
                    <ResponsivePagination
                        current={currentPage}
                        total={totalPages}
                        onPageChange={pageNumber => {setCurrentPage(pageNumber);}}
                    />
                    </Center>
                </Flex>
            </Box>
        </Box>
    );
}

export default SharedWorkflows;
