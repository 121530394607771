// React Imports
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiFillCloseSquare, AiFillPlusSquare } from "react-icons/ai";
import {
  Button,
  Box,
  VStack,
  Center,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Text,
  IconButton,
  MenuItem,
} from "@chakra-ui/react";

// Component Imports
import LikeImage from "../../LikeImage/LikeImage";
import { defaultThemeColor, greyBgColor } from "../../../../../../constants";
import ObjectUploader from "../../../../Playground/ObjectUploader";
import PopupImageModal from "../../../../Playground/PopupImageModal";
import ImageFromResource from "../../ProjectWrokspace/BaseConfig/ImageFromResource";
import { setupReferenceObject } from "../../../../../../services/projectTemplateService";
import CompareImage from "./CompareImage.tsx";
import { wizardTitles } from "../../../../../../constants/Workflow.js";
import { useNavigate } from "react-router";
import { resetWorkflowData } from "../../../../../../utils/resetWorkflowData.js";
import { setLineDrawFlag, setTabFlag } from "../../../../../../store/actions/workFlowAction.js";

function SourceImage({
  currentImage,
  resetImage,
  imageIndexValue,
  workflowCounter,
  imageObjectName,
}) {
  const imageRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const divRef = useRef(null);
  const [divWidth, setDivWidth] = useState(0);

  const workflowDetails = useSelector((store) => store.workflowDesignHeader);
  const workflowDetailsData = workflowDetails?.workflowObject;
  const sourceImage =
    workflowDetailsData?.modelEntity === "text_to_image"
      ? `https://dummyimage.com/${workflowDetailsData?.imageWidth}x${workflowDetailsData?.imageHeight}/fff/fff.png`
      : workflowDetailsData?.source_image?.length
      ? workflowDetailsData?.source_image
      : "";
  const createObject = { resource_uuid: workflowDetailsData?.resource_uuid };

  const updateResourceImageDetails = (data, object_role) => {
    let obj = {
      key: data?.payload?.object_name,
    };
    SupplementObjectHandler(obj, object_role);
  };

  const SupplementObjectHandler = (data, object_role) => {
    if (data !== null || data !== undefined) {
      let objBody = {object_info: data.key};
      setupReferenceObject(objBody)
        .then((res) => {
          if (res?.data[0]?.object[0]?.url.length) {
            if (object_role === "source_image") {
              dispatch({
                type: "SET_WORKFLOW_OBJECT",
                payload: {
                  key: "source_image",
                  value: res?.data[0]?.object[0]?.url,
                },
              });
              dispatch({
                type: "SET_WORKFLOW_OBJECT",
                payload: {
                  key: "object_name",
                  value: res?.data[0]?.object[0]?.object_name,
                },
              });
            }
          }
        })
        .catch((err) => {
          // Show an error here...
        });
    }
  };

  // Todo:Reset Redux Data
  // Differentiate playground and design
  const navigateToCreateView = (item) => {
    resetImage();
    resetWorkflowData(dispatch);
    navigate("/business/designProject", {
      state: {
        launcher: "create",
        project_uuid: workflowDetailsData?.project_uuid,
        resource_uuid: workflowDetailsData?.resource_uuid,
        parent_workflow_uuid: workflowDetailsData?.workflow_uuid,
        modelDetails: item,
        object_name: imageObjectName,
        object_url: currentImage,
      },
    });
  };

  useEffect(() => {
    // Update div width after the component mounts and whenever the window resizes
    const updateDivWidth = () => {
      if (divRef.current) {
        setDivWidth(divRef.current.getBoundingClientRect().width);
      }
    };

    updateDivWidth();

    window.addEventListener("resize", updateDivWidth);
    return () => {
      window.removeEventListener("resize", updateDivWidth);
    };
  }, []);

  useEffect(() => {
    if (divRef?.current) {
      setDivWidth(divRef?.current?.getBoundingClientRect()?.width);
    }
  }, [sourceImage]);

  return (
    <>
      {sourceImage?.length === 0 ? (
        <VStack
          w={"100%"}
          mt={2}
          rounded={"lg"}
          bg={greyBgColor}
          borderRadius={"10px"}
        >
          <Center w={"100%"} h={"100%"} color="#fff">
            <VStack w={"100%"} position="relative">
              <Box w={"100%"}>
                {workflowDetailsData?.modelEntity === "controlnet" &&
                workflowDetailsData?.modelCode === 3 ? (
                  <Box>
                    <Button
                      mt={2}
                      position={"absolute"}
                      left={"50%"}
                      top={"10%"}
                      transform={"translate(-50%, -50%)"}
                      zIndex={999}
                      size={"sm"}
                      colorScheme={"yellow"}
                      onClick={(e) => {
                        dispatch(setLineDrawFlag(true));
                        dispatch(setTabFlag(1));
                      }}
                    >
                      Line Draw
                    </Button>
                  </Box>
                ) : null}
                <ObjectUploader
                  createObject={createObject}
                  objectUploaded={(data) =>
                    SupplementObjectHandler(data, "source_image")
                  }
                  clipImage={workflowDetails?.copyToClip}
                  showCropButton={"true"}
                />
                <ImageFromResource
                  objectRole={"source_image"}
                  passResourceDetails={updateResourceImageDetails}
                  resourceId={workflowDetailsData?.resource_uuid}
                />
              </Box>
            </VStack>
          </Center>
        </VStack>
      ) : (
        <VStack
          w={"100%"}
          align={"center"}
          position="relative"
          h="100%"
          ref={divRef}
        >
          <PopupImageModal
            imageUrl={sourceImage}
            objectName={workflowDetailsData?.object_name}
            showIcons={!currentImage?.length}
          />

          {currentImage?.length ? (
            <>
              {workflowDetailsData?.modelEntity === "diffedit" ||
              workflowDetailsData?.modelEntity === "inpaint_controlnet" ||
              workflowDetailsData?.modelEntity === "controlnet" ? (
                <CompareImage
                  controlImage={
                    workflowDetailsData?.modelEntity === "inpaint_controlnet" &&
                    workflowDetails?.superImposeFlag &&
                    workflowDetailsData?.control_guidance_image
                      ? workflowDetailsData?.control_guidance_image
                      : null
                  }
                  controlImageBox={
                    workflowDetailsData?.modelEntity === "inpaint_controlnet" &&
                    workflowDetails?.superImposeFlag &&
                    workflowDetailsData?.control_guidance_image &&
                    workflowDetailsData?.controlBoundingBox &&
                    workflowDetailsData?.controlBoundingBox?.length === 4
                      ? workflowDetailsData?.controlBoundingBox
                      : null
                  }
                  ref={imageRef}
                  leftImage={sourceImage}
                  rightImage={currentImage}
                  parentDivWidth={divWidth}
                />
              ) : workflowDetailsData?.modelEntity === "text_to_image" ? (
                <Box
                  position="absolute"
                  top="0"
                  right="0"
                  w="100%"
                  h="90%"
                  bg={greyBgColor}
                  zIndex={"20"}
                >
                  <Image
                    src={currentImage}
                    w="100%"
                    h="100%"
                    objectFit={"contain"}
                  />
                </Box>
              ) : null}
              <Box
                position="absolute"
                top="0"
                right="0"
                bg={greyBgColor}
                border="none"
                width="96px"
                height="32px"
              >
                <Box position="absolute" top="0" right="16">
                  <Menu className="addToplayground">
                    <MenuButton
                      className="addToplayground"
                      as={IconButton}
                      icon={
                        <AiFillPlusSquare
                          color={defaultThemeColor}
                          cursor="pointer"
                        />
                      }
                      zIndex={"21"}
                      colorScheme="black"
                      m="0"
                      p="0"
                      borderWidth={"0px"}
                      fontSize={"30px"}
                      borderRadius={"0px"}
                      size="sm"
                    ></MenuButton>
                    <MenuList
                      zIndex={"21"}
                      bg={greyBgColor}
                      borderWidth={"0px"}
                    >
                      {wizardTitles.map((item, index) => {
                        return (
                          <MenuItem
                            cursor={"pointer"}
                            onClick={() => navigateToCreateView(item)}
                            key={item?.label + index}
                            borderBottomWidth={"0px"}
                            bg={greyBgColor}
                          >
                            <Text
                              textAlign={"center"}
                              fontWeight={"600"}
                              color="#fff"
                              mr="3"
                            >
                              {item?.label}
                            </Text>

                            <Text
                              textAlign={"center"}
                              fontWeight={"600"}
                              color="#fff"
                            >
                              {item?.subTitle}
                            </Text>
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Menu>
                </Box>
                <Box position="absolute" top="0" right="6" zIndex={"21"}>
                  <LikeImage
                    workflowDetails={workflowDetails}
                    imageIndexValue={imageIndexValue}
                    workflowCounter={workflowCounter}
                    sendDataToCardFlip={(value) => {}}
                  />
                </Box>
                <Box
                  position="absolute"
                  top="0"
                  right="0"
                  fontSize={"30px"}
                  zIndex={"21"}
                  onClick={() => {
                    resetImage();
                  }}
                >
                  <AiFillCloseSquare color="red" cursor="pointer" />
                </Box>
              </Box>
            </>
          ) : null}
        </VStack>
      )}
    </>
  );
}

export default SourceImage;
