// Chakra React Imports
import axios from "../../../../services/axios";
import React, { useState, useEffect } from "react";
import { Box, Button, useToast, Input, Heading } from "@chakra-ui/react";
import { defaultGreyColor, greyBgColor } from "../../../../constants";
import { toastFunctionToaster } from "../../../../utils/toastFunction";

import { updateBusinessLogoPhoto } from "../../../../services/businessServices"

function BusinessLogoImage({ objectUploaded }) {
  const toast = useToast();
  const [loading, isLoading] = useState(false);
  const [uploadFile, setUploadFile] = useState(null);

  const handleFileChange = (e) => {
    e.preventDefault();
    if (e.target.files?.[0]) {
      setUploadFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    handleSubmitNew();
  }, [uploadFile]);

  function postObjectData(s3Data, selectedFile) {
    isLoading(true);

    const { url, fields } = s3Data;
    const payload = new FormData();

    // add all the other fields
    Object.entries(fields).forEach(([key, val]) => {
      payload.append(key, val);
    });
    // payload.append("Content-Type", "image/jpeg");
    // This must be the last line in the payload otherwise 412 error may occur
    // this is the file blob, eg from <input type="file">
    payload.append("file", selectedFile);

    // No need to manually set content-type header, your browser knows what to do
    // const { data: result } = axios.post(url, payload);
    const { data: result } = axios
      .post(url, payload)
      .then((res) => {
        if (res?.data?.length > 0) {
          objectUploaded();
          toast(
            toastFunctionToaster("File uploaded successfully", "success")
          );
        } else {
          objectUploaded();
          toast(
            toastFunctionToaster("File uploaded successfully", "success")
          );
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      })
      .finally(() => {
        isLoading(false);
      });
  }

  const handleSubmitNew = async () => {
    if (uploadFile) {
      isLoading(true);
      let objData = {
        file_name: uploadFile.name,
        file_type: uploadFile.type,
      };
      const response = await updateBusinessLogoPhoto(objData);
      if (
        response &&
        response?.data &&
        response?.data?.length > 0 &&
        response.data[0]?.signedPackage?.signedUrl
      ) {
        const finalUrl = response.data[0]?.signedPackage?.signedUrl;
        isLoading(false);
        const s3UploadResult = await postObjectData(finalUrl, uploadFile);
      } else {
        isLoading(false);
        toast(toastFunctionToaster(response?.message, "error"));
      }
    }
  };

  return (
    <Box h="100%">
      <Heading
        alignContent="center"
        justifyContent="center"
        color="#d7d7d7"
        fontSize={"18px"}
        px="3"
        pb="2"
      >
        Business Logo
      </Heading>
      <Box
        borderWidth={"1px"}
        borderStyle={"dashed"}
        borderColor={defaultGreyColor}
        borderRadius={"10px"}
        position={"relative"}
        bg={greyBgColor}
        h="50%"
        cursor={"pointer"}
      >
        <Input
          p="0px"
          type="file"
          onChange={handleFileChange}
          accept=".jpg,.jpeg,.png"
          position="relative"
          opacity="0"
          h="100%"
          zIndex={"1"}
        ></Input>
        <Button
          mt={2}
          size={"sm"}
          color={"gray.600"}
          onClick={handleSubmitNew}
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          colorScheme="green"
          textColor={"#fff"}
          isDisabled={loading ? true : false}
          isLoading={loading ? true : false}
        >
          Upload Profile Image
        </Button>
      </Box>
    </Box>
  );
}

export default BusinessLogoImage;
