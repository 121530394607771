import React, { useState } from "react";
import {
  Button,
  FormLabel,
  FormControl,
  Text,
  Input,
  Icon,
  useToast,
  Modal,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { themeFontSizeMediumHeading } from "../../../../constants";

function AddProjectFolder(props) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, isLoading] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [errorFlag, setErrorFlag] = useState(false);

  const updateFolderName = () => {
    if (folderName?.length === 0) {
      setErrorFlag(true);
      isLoading(false);
    } else {
      setErrorFlag(false);
    }
  };

  // Todo:On Model Close Reset Data
  const onCloseModal = () => {
    onClose();
    setErrorFlag(false);
    setFolderName("");
  };

  return (
    <>
      <Button onClick={onOpen} ml="3" size="sm" colorScheme="green">
        Add Folder
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onCloseModal();
        }}
      >
        <ModalOverlay />
        <ModalContent bg={"#131415"}>
          <ModalHeader color="#fff">Folder Name</ModalHeader>
          <ModalCloseButton color="red" />
          <ModalBody>
            <FormControl isRequired mb={3}>
              <FormLabel fontSize={themeFontSizeMediumHeading} color="#fff">
                Enter Folder Name
              </FormLabel>
              <Input
                placeholder="Enter Folder Name..."
                height={"50px"}
                border="none"
                fontSize="14px"
                borderColor="#494552"
                color="#fff"
                bg="#1c181e"
                size="sm"
                value={folderName}
                onChange={(e) => {
                  setFolderName(e.target.value);
                }}
                name="project_name"
              />
              {errorFlag && folderName?.length === 0 ? (
                <Text fontSize="sm" color={"red"} mt="1">
                  Folder Name is required
                </Text>
              ) : null}
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              mr={3}
              size="sm"
              colorScheme="green"
              onClick={() => updateFolderName()}
            >
              Save
            </Button>
            <Button colorScheme="red" onClick={() => onCloseModal()} size="sm">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddProjectFolder;
