// Chakra Imports
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BiHide, BiShow } from "react-icons/bi";
import {
  Input,
  Button,
  FormControl,
  Box,
  Flex,
  Image,
  Text,
  InputGroup,
  InputRightElement,
  useToast,
  HStack,
} from "@chakra-ui/react";

// Component Imports
import Logo from "../../image/LandingPageImg/logo.png";
import { defaultThemeColor } from "../../constants";
import { login, getToken } from "../../services/sharedServices";
import LandingBgImage from "../../image/LandingPageImg/landingbg.png";
import ForgotPasswordPanel from "../ForgotPassword/ForgotPasswordPanel";
import {
  setTopNotificationMessage,
  setTopNotoficationStatusColor,
} from "../../store/actions/topNotificationActions";
import {
  saveSessionId,
  saveEmailId,
  saveAuthToken,
  savegroupId,
} from "../../utils/localStorageIndex";

function LoginPage() {
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [show, setShow] = useState(false);
  const [loading, isLoading] = useState(false);
  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
  });

  const onUpdateField = (e) => {
    const nextFormState = {
      ...loginForm,
      [e.target.name]: e.target.value,
    };
    setLoginForm(nextFormState);
  };

  const validateFieldForm = () => {
    let errors = {};
    let formIsValid = true;
    let fields = loginForm;
    let emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    if (!fields["email"]) {
      formIsValid = false;
      errors["email"] = "*Please enter your email.";
    } else if (!emailPattern.test(fields["email"])) {
      formIsValid = false;
      errors["email"] = "*Please enter a valid email address.";
    }
    if (!fields["password"]) {
      formIsValid = false;
      errors["password"] = "*Please enter your password.";
    }
    setError(errors);
    return formIsValid;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      try {
        const res = await login({
          ...loginForm, email: loginForm.email.toLowerCase()
        });
        isLoading(false);
        if (res?.result) {
          if (res?.data[0].is_dummy_password === false) {
            const emailId = res?.data[0].email;
            saveEmailId(emailId);

            // Move the token retrieval logic here and await the token
            const formData = new FormData();
            formData.append("grant_type", "");
            formData.append("username", loginForm.email.toLowerCase());
            formData.append("password", loginForm.password);
            formData.append("scope", "");
            formData.append("client_id", "");
            formData.append("client_secret", "");
            const tokenRes = await getToken(formData);
            if (
              tokenRes.access_token != null &&
              tokenRes.access_token.length > 0
            ) {
              saveAuthToken(tokenRes.access_token);
            }

            navigate("/business/projectslist");
            dispatch(setTopNotoficationStatusColor("green"));
            savegroupId(res?.data[0].group);
            saveSessionId(res?.data[0].user_uuid);
            dispatch(setTopNotificationMessage(`Welcome ${emailId}`));
            toast({
              title: "Logged In Successfully",
              status: "success",
              isClosable: true,
              duration: 3000,
            });
            navigate("/business/projectslist");
          } else if (res?.data[0].is_dummy_password === true) {
            const emailId = res?.data[0].email;
            toast({
              title:
                "You have logged in successfully using default password. Please change default password with your own password",
              status: "success",
              isClosable: true,
              duration: 3000,
            });
            navigate("/change_default_password", {
              state: {
                email: emailId,
              },
            });
          }
        } else {
          toast({
            title: res?.message,
            status: "error",
            isClosable: true,
            duration: 3000,
          });
        }
      } catch (err) {
        isLoading(false);
        if (err)
          toast({
            title: err?.response?.data?.detail?.message,
            status: "error",
            isClosable: true,
            duration: 3000,
          });
      }
    }
  };

  const handleClick = () => setShow(!show);

  const checkValidEmailId = () => {
    let fields = loginForm;
    let errors = {};
    let emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    if (!fields["email"]) {
      errors["email"] = "*Please enter email.";
    } else if (!emailPattern.test(fields["email"])) {
      errors["email"] = "*Please enter valid email address.";
    } else {
      errors = {};
    }
    setError(errors);
  };

  return (
    <Box bg="black" color="#fff">
      <Box
        bgImage={LandingBgImage}
        bgPosition="center"
        bgRepeat="no-repeat"
        bgSize="cover"
        h="100vh"
        p="10"
      >
        <Box className="top-content">
          <Flex justifyContent={"space-between"}>
            <Flex
              alignItems={"center"}
              cursor={"pointer"}
              onClick={() => {
                navigate("/");
              }}
            >
              <Image src={Logo}></Image>
              <Text ml="3" fontWeight={"600"} fontSize={"26px"}>
                Corbu
              </Text>
            </Flex>
            <Button
              color="#000"
              bg={defaultThemeColor}
              _hover={{ boxShadow: "none" }}
              _active={{ bg: "none" }}
              onClick={() => {
                navigate("/business/RegisterUser");
              }}
            >
              Register
            </Button>
          </Flex>
        </Box>

        <Flex justifyContent="end" mt="2%">
          <Box bg="#141718" borderRadius={"20px"} p="20px" w="40%">
            <Text fontSize={"24px"}>Log in to Corbu</Text>
            <Text fontSize={"14px"} color="grey" mt="2">
              Use your credentials to access your account
            </Text>

            <form onSubmit={handleSubmit} style={{ marginTop: "20px" }}>
              <FormControl id="email" mb="4">
                <Input
                  type="text"
                  value={loginForm.email}
                  name="email"
                  onChange={onUpdateField}
                  placeholder="Email Address"
                  size="md"
                  borderRadius="10px"
                  height={"45px"}
                  border="none"
                  bg="#232627"
                  fontSize="16px"
                  _placeholder={{ color: "#fff" }}
                  _focus={{ boxShadow: "none" }}
                />
                <Text color="red" mt="2">
                  {error?.email}
                </Text>
              </FormControl>

              <FormControl id="password">
                <InputGroup size="md">
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    value={loginForm.password}
                    name="password"
                    onChange={onUpdateField}
                    placeholder="Password"
                    borderRadius="10px"
                    height={"45px"}
                    border="none"
                    bg="#232627"
                    fontSize="16px"
                    _placeholder={{ color: "#fff" }}
                    _focus={{ boxShadow: "none" }}
                  />
                  <InputRightElement width="4.5rem">
                    <Button
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick}
                      bg="none"
                      _hover={{ bg: "none" }}
                    >
                      {show ? (
                        <BiHide size="sm" color="grey" />
                      ) : (
                        <BiShow size="sm" color="grey" />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>

                <Text color="red" mt="2">
                  {error?.password}
                </Text>
              </FormControl>
              <HStack w={"100%"} mt={5}>
                <Button
                  fontWeight="500"
                  fontSize="16px"
                  w="100%"
                  bg={defaultThemeColor}
                  borderRadius={"10px"}
                  _hover={{ boxShadow: "none" }}
                  _active={{ bg: "none" }}
                  color={"#000"}
                  type="submit"
                  isDisabled={loading ? true : false}
                  isLoading={loading ? true : false}
                >
                  Log In
                </Button>

                <ForgotPasswordPanel
                  checkEmailValid={checkValidEmailId}
                  emailId={loginForm.email}
                ></ForgotPasswordPanel>
              </HStack>
            </form>
            {/* <Box mt="3">
                <GoogleSignInButton selectedButton={"login"} />
              </Box> */}
            <Flex mt="4rem">
              Don't have an account?{" "}
              <Text
                ml="3"
                color={defaultThemeColor}
                cursor="pointer"
                onClick={() => {
                  navigate("/business/RegisterUser");
                }}
              >
                Register here
              </Text>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
}

export default LoginPage;
