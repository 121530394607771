// React Chakra Imports
import { FaRegEdit } from "react-icons/fa";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
import {
  Box,
  Text,
  Flex,
  Spinner,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

// Component Imports
import dummyLogo from "../../../image/dummy-logo.jpg";
import { defaultGreyColor, greyBgColor } from "../../../constants";
import { getEmailId } from "../../../utils/localStorageIndex";
import UpdateInformation from "../UpdateInfo/UpdateInformation";
import {
  setTopNotificationMessage,
  setTopNotoficationStatusColor,
} from "../../../store/actions/topNotificationActions";

// Api Services Imports
import { fetchPersonalInfo } from "../../../services/sharedServices";
import ChangePassword from "../ChangePassword/ChangePassword";
import UserProfileImage from "./UserProfileImage";

function UserInfo() {
  const emaiId = getEmailId();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, isLoading] = useState(true);
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    fetchUserDetails();
  }, []);

  const fetchUserDetails = () => {
    let objData = {
      email: emaiId,
    };
    fetchPersonalInfo(objData)
      .then((res) => {
        if (res.result) {
          setUserDetails(res.data[0]);

          isLoading(false);
          dispatch(setTopNotificationMessage(res?.message));
          dispatch(setTopNotoficationStatusColor("green"));
        } else {
          isLoading(false);
          dispatch(setTopNotificationMessage(res?.message));
          dispatch(setTopNotoficationStatusColor("red"));
        }
      })
      .catch((err) => {
        isLoading(false);
        if (err) {
          dispatch(setTopNotificationMessage(err?.message));
          dispatch(setTopNotoficationStatusColor("red"));
        }
      });
  };

  function renderFormattedString(strtemp) {
    var finalString = "-";
    if (strtemp.trim().length > 0) {
      finalString = strtemp;
    }
    return finalString;
  }

  // Todo:Update Business Modal And Details
  const handeUserDetails = (data) => {
    if (data) {
      fetchUserDetails();
      onClose();
    }
  };

  return (
    <>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" color="#fff" h="50vh">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Flex p="15px">
          <Box
            w="30%"
            mt="3"
            mr="3"
            bg="#151117"
            borderRadius={"20px"}
            p="20px"
          >
            <Flex justifyContent={"center"}>
              <Box h="120px" w="120px">
                <Image
                  h="100%"
                  w="100%"
                  objectFit="contain"
                  alt="User Headshot"
                  src={
                    userDetails.headshot?.length
                      ? userDetails.headshot[0].url
                      : dummyLogo
                  }
                  borderRadius={"50%"}
                  bg="#fff"
                  p="2"
                ></Image>
              </Box>
              <Box ml="4">
                <FaRegEdit
                  color={defaultGreyColor}
                  fontSize={"20px"}
                  cursor={"pointer"}
                  onClick={() => {
                    onOpen();
                  }}
                />
              </Box>
            </Flex>
            <Flex alignItems={"center"} justifyContent={"center"} mt="2">
              <Box>
                <FaRegEdit color="#fff" fontSize={"16px"} />
              </Box>
              <Text fontSize={"16px"} color={defaultGreyColor} ml="2">
                {userDetails.first_name !== null &&
                userDetails.first_name !== undefined
                  ? renderFormattedString(userDetails.first_name)
                  : "-"}
                <Text as="span" pl="10px">
                  {userDetails.last_name !== null &&
                  userDetails.last_name !== undefined
                    ? renderFormattedString(userDetails.last_name)
                    : "-"}
                </Text>
              </Text>
            </Flex>
            <Flex alignItems={"center"} justifyContent={"center"} mt="2">
              <Box>
                <AiOutlineMail color="#fff" fontSize={"20px"} />
              </Box>
              <Text fontSize={"16px"} color={defaultGreyColor} ml="2">
                {emaiId ? emaiId : "-"}
              </Text>
            </Flex>
            <Flex alignItems={"center"} justifyContent={"center"} mt="2">
              <Box>
                <AiOutlinePhone color="#fff" fontSize={"20px"} />
              </Box>
              <Text fontSize={"16px"} color={defaultGreyColor} ml="2">
                {userDetails.phone_number !== null &&
                userDetails.phone_number !== undefined
                  ? renderFormattedString(userDetails.phone_number)
                  : "-"}
              </Text>
            </Flex>
          </Box>
          <Box
            w="30%"
            mt="3"
            mr="3"
            bg="#151117"
            borderRadius={"20px"}
            p="20px"
          >
            <ChangePassword />
          </Box>
          <Box
            w="40%"
            mt="3"
            bg="#151117"
            borderRadius={"20px"}
            p="20px"
          >
            <UserProfileImage objectUploaded={fetchUserDetails}/>
          </Box>
          <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent bg={greyBgColor} borderRadius="20px">
              <ModalHeader color="#fff">User Info</ModalHeader>
              <ModalCloseButton color="#d7d7d7" />
              <UpdateInformation updateUserDetails={handeUserDetails} />
            </ModalContent>
          </Modal>
        </Flex>
      )}
    </>
  );
}

export default UserInfo;
