// Chakra Import
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { AiFillQuestionCircle } from "react-icons/ai";
import {
  Text,
  Box,
  Flex,
  Spinner,
  useToast,
  Image,
  Button,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  VStack,
  HStack,
  Tooltip,
  Radio,
  RadioGroup,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";

// Component Import
import CardFlip from "./CardFlip";
import {
  convertBasicRLEToRawData,
  convertCocolibRLEToBasicRLE,
} from "../../ImageWithMasksUtils";
import ResponsivePagination from "react-responsive-pagination";
import { getEmailId } from "../../../../../utils/localStorageIndex";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import { defaultThemeColor, greyBgColor } from "../../../../../constants";

// Api Import
import RenderImageUI from "./RenderImageUI";
import titleConstants from "../../../../../constants/TitleConstant";
import tooltipConstants from "../../../../../constants/TooltipConstant";
import { maskRegionColors } from "../../../../../constants/Workflow";
import { setTabFlag } from "../../../../../store/actions/workFlowAction";
import {
  CollectPreviousData,
  setupReferenceObject,
} from "../../../../../services/projectTemplateService";

function PreviousImageConfig(props) {
  const toast = useToast();
  const dispatch = useDispatch();
  const { projectId, workflowId, tabFlag } = props;
  const [loading, isLoading] = useState(false);
  const [prevImageData, setPrevImageData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ctxRefs = useRef({});
  const workflowDetails = useSelector((store) => store.workflowDesignHeader);

  const loadMaskFromRLEDatas = (data, context, fillColor) => {
    if (!data || !context) {
      return;
    }
    const rawImageCanvas = convertBasicRLEToRawData(
      convertCocolibRLEToBasicRLE(data.counts),
      Math.round(data.size[0]),
      Math.round(data.size[1]),
      fillColor
    );
    context.drawImage(
      rawImageCanvas,
      0,
      0,
      Math.round(context.canvas.width),
      Math.round(context.canvas.height)
    );
  };

  useEffect(() => {
    if (projectId && workflowId && tabFlag === 1) {
      renderPreviousImage();
    }
  }, [props?.tabFlag, currentPage]);

  const renderPreviousImage = () => {
    isLoading(true);
    let obj = {
      email: getEmailId(),
      project_uuid: projectId,
      workflow_uuid: workflowId,
      page_id: currentPage,
      records_per_page: 5,
    };
    CollectPreviousData(obj)
      .then((res) => {
        if (res?.data?.length) {
          setPrevImageData(res?.data[0]?.records);
          setTotalPages(res?.data[0]?.total_pages);
        } else {
          setPrevImageData([]);
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      })
      .finally(() => {
        isLoading(false);
      });
  };

  useEffect(() => {
    renderCardFlip();
  }, [prevImageData]);

  const renderCardFlip = () => {
    if (prevImageData?.length) {
      return prevImageData.map((data, index) => renderImages(data, index));
    } else {
      return (
        <Text
          fontWeight="600"
          fontSize="16px"
          p="2"
          bg={greyBgColor}
          m="2"
          color="#fff"
        >
          No Data Found....
        </Text>
      );
    }
  };

  // Todo:Merge Json and Image into an array and render the image
  const renderImages = (data, outerIndex) => {
    const rowJsonData = data?.images[1][0]?.json;
    let imageJsonData = [];
    data?.images?.forEach((subData) => {
      imageJsonData.push(subData[0]);
    });

    const groupedArrays = imageJsonData.reduce(
      (result, currentObject, index, array) => {
        if (index % 2 === 0) {
          result.push([...array.slice(index, index + 2)]);
        }
        return result;
      },
      []
    );

    // Todo:Received Data From Like Component > Card Flip Component
    const resetAfterLikeImage = (value) => {
      if (value === "true") {
        renderPreviousImage();
      }
    };

    // Todo:Clone All Functionality
    const cloneFunctionality = (jsonData) => {
      const storeJsonData = jsonData;
      if (jsonData.model_sub_name === "inpaint_controlnet") {
        cloneGuidanceImage(
          storeJsonData?.images[0]?.control_image,
          storeJsonData?.images[0]?.control_image_box
        );
      }
      storeConfigData(storeJsonData);
      cloneAllRegionData(
        storeJsonData?.images?.[0]?.regions,
        jsonData.model_sub_name
      );
      dispatch(setTabFlag(0));
    };

    return (
      <>
        <Box
          textAlign="left"
          color={defaultThemeColor}
          p="2"
          fontSize="15px"
          fontWeight="600"
          borderWidth="0.5px"
          borderColor={"#585858"}
          key={`Title ${outerIndex}`}
        >
          <Flex justifyContent={"space-between"} px="2">
            <Text>
              Workflow Results (ID # {data?.folder}):{" "}
              {eval(data.images.length / 2)} Images
            </Text>
            <Button
              size={"xs"}
              colorScheme="purple"
              onClick={() => {
                cloneFunctionality(JSON.parse(rowJsonData));
              }}
            >
              Copy To Current Design
            </Button>
          </Flex>
        </Box>
        <Box
          borderWidth={"0.5px"}
          borderColor={"#585858"}
          borderTop={"0px"}
          key={outerIndex}
          pt="3"
          mb="3"
        >
          <Box
            display={"-webkit-box"}
            overflow="auto"
            overflowY="hidden"
            css={{
              "&::-webkit-scrollbar": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-track": {
                width: "4px",
                height: "4px",
              },
              "&::-webkit-scrollbar-thumb": {
                background: "#fff",
                borderRadius: "10px",
              },
            }}
            cursor="pointer"
          >
            {groupedArrays?.map((value, index) => (
              <CardFlip
                key={outerIndex + index}
                frontImage={value}
                likeRating={data?.star_data ? data?.star_data : []}
                cardFlipStatus={true}
                indexValue={index}
                outerIndexValue={outerIndex}
                workflowDetails={workflowDetails}
                workflowCounter={data?.folder}
                sendDataToPrevImageComp={resetAfterLikeImage}
                imageJsonData={prevImageData}
              />
            ))}
          </Box>
          <Accordion
            allowMultiple
            onChange={(idx) => {
              const storeJsonData = JSON.parse(rowJsonData);
              storeJsonData?.images?.[0]?.regions?.forEach((region, index) => {
                loadMaskFromRLEDatas(
                  region?.mask,
                  ctxRefs.current[outerIndex * maskRegionColors.length + index],
                  "white"
                );
              });
            }}
          >
            <AccordionItem border={"0px"}>
              <AccordionButton justifyContent={"end"} mt="2">
                <Button size="xs" colorScheme="yellow">
                  More Info
                </Button>
              </AccordionButton>
              <AccordionPanel pb={4}>
                <Box>{renderProjectWorkflowData(outerIndex, rowJsonData)}</Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </>
    );
  };

  // Todo:Render additional workflow data
  const renderProjectWorkflowData = (outerIndex, value) => {
    const storeJsonData = JSON.parse(value);
    return (
      <Box>
        {storeJsonData?.images?.[0]?.image ? (
          <>
            <Flex
              justifyContent={"space-between"}
              p="10px"
              bg="#151117"
              borderTopLeftRadius={"10px"}
              borderTopRightRadius={"10px"}
              borderBottomWidth={"1px"}
              borderBottomColor={"#585858"}
            >
              <Text
                color={defaultThemeColor}
                fontSize={"16px"}
                fontWeight={"600"}
                w="10%"
              >
                Input Images
              </Text>
            </Flex>
            <Flex
              bg="#151117"
              borderBottomLeftRadius={"10px"}
              borderBottomRightRadius={"10px"}
            >
              <Box p="10px" borderRadius={"10px"}>
                <Text
                  color="#fff"
                  fontSize={"14px"}
                  fontWeight={"600"}
                  bg={greyBgColor}
                  borderTopLeftRadius={"10px"}
                  borderTopRightRadius={"10px"}
                  w="100%"
                  p="10px"
                >
                  Source Image
                </Text>
                <Box h="290px" position="relative">
                  <Image
                    h="100%"
                    w="100%"
                    objectFit={"cover"}
                    src={props?.workFlowImage}
                    borderBottomLeftRadius={"10px"}
                    borderBottomRightRadius={"10px"}
                  />
                </Box>
              </Box>
              {storeJsonData?.images?.[0]?.control_image ? (
                <Box p="10px" borderRadius={"10px"} ml="3">
                  <Flex
                    justifyContent={"space-between"}
                    w="100%"
                    p="10px"
                    bg={greyBgColor}
                    borderTopLeftRadius={"10px"}
                    borderTopRightRadius={"10px"}
                  >
                    <Text color="#fff" fontSize={"14px"} fontWeight={"600"}>
                      Guidance Image
                    </Text>
                    <Button
                      size="xs"
                      onClick={() => {
                        cloneGuidanceImage(
                          storeJsonData?.images[0]?.control_image,
                          storeJsonData?.images[0]?.control_image_box
                        );
                      }}
                      colorScheme="purple"
                    >
                      Clone
                    </Button>
                  </Flex>
                  <RenderImageUI
                    image={storeJsonData?.images[0]?.control_image}
                  />
                </Box>
              ) : null}
              {storeJsonData.model_sub_name === "inpaint_controlnet" &&
              storeJsonData?.images[0]?.control_image_box && (
                <Box p="10px" borderRadius={"10px"} ml="3">
                  <Text color={"white"} mr="10px" p="10px">
                    Guidance Overlay Left Boundary:{" "}
                    {storeJsonData?.images[0]?.control_image_box[0]}
                  </Text>
                  <Text color={"white"} mr="10px" p="10px">
                    Guidance Overlay Top Boundary:{" "}
                    {storeJsonData?.images[0]?.control_image_box[1]}
                  </Text>
                  <Text color={"white"} mr="10px" p="10px">
                    Guidance Overlay Right Boundary:{" "}
                    {storeJsonData?.images[0]?.control_image_box[2]}
                  </Text>
                  <Text color={"white"} mr="10px" p="10px">
                    Guidance Overlay Bottom Boundary:{" "}
                    {storeJsonData?.images[0]?.control_image_box[3]}
                  </Text>
                </Box>
              )}
            </Flex>
          </>
        ) : null}
        {storeJsonData?.images?.[0]?.regions?.length && (
          <Box
            mt="3"
            alignItems="center"
            w="100%"
            bg="#151117"
            borderRadius="10px"
          >
            <Flex
              justifyContent="space-between"
              borderBottomWidth="1px"
              borderBottomColor="#585858"
              p="10px"
              w="100%"
            >
              <Text
                color={defaultThemeColor}
                fontSize="16px"
                fontWeight="600"
                w="20%"
              >
                Base Configuration
              </Text>
              <Button
                size="xs"
                colorScheme="purple"
                onClick={() => {
                  cloneAllRegionData(
                    storeJsonData?.images?.[0]?.regions,
                    storeJsonData.model_sub_name
                  );
                }}
              >
                Copy To Current Design
              </Button>
            </Flex>
            <Box
              display={"-webkit-box"}
              overflow="auto"
              overflowY="hidden"
              css={{
                "&::-webkit-scrollbar": {
                  width: "4px",
                  height: "4px",
                },
                "&::-webkit-scrollbar-track": {
                  width: "4px",
                  height: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#fff",
                  borderRadius: "10px",
                },
              }}
            >
              {storeJsonData?.images?.[0]?.regions?.map((data, index, arr) => (
                <Box key={outerIndex + index} p="10px" borderRadius={"10px"}>
                  {renderRegionMask(
                    data,
                    outerIndex,
                    index,
                    (storeJsonData.model_sub_name === "controlnet" ||
                      storeJsonData.model_sub_name === "text_to_image") &&
                      index === arr.length - 1
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        )}
        <Box
          mt="3"
          alignItems={"center"}
          w="100%"
          bg="#151117"
          borderRadius={"10px"}
        >
          <Flex
            justifyContent={"space-between"}
            borderBottomWidth={"1px"}
            borderBottomColor={"#585858"}
            p="10px"
          >
            <Text
              color={defaultThemeColor}
              fontSize={"16px"}
              fontWeight={"600"}
              w="20%"
            >
              Advanced Configuration
            </Text>
            <Button
              size="xs"
              colorScheme="purple"
              onClick={() => {
                storeConfigData(storeJsonData);
              }}
            >
              Copy To Current Design
            </Button>
          </Flex>
          {renderImageDebugJson(storeJsonData)}
        </Box>
      </Box>
    );
  };

  const renderImageDebugJson = (jsonData, index) => {
    var jsonObj = jsonData;
    if (jsonObj?.model_sub_name !== "upscale") {
      return (
        <>
          <HStack>
            <Box w="50%" alignItems={"center"} key={"left"}>
              {(
                jsonObj?.model_sub_name === "controlnet" ||
                jsonObj?.model_sub_name === "inpaint_controlnet" 
              ) && (
                jsonObj?.controlnet_type !== "refine"
              ) && (
                <Box p="2">
                  <Flex justifyContent={"space-between"}>
                    <Text cursor={"pointer"} color="#fff" fontWeight={"600"}>
                      {titleConstants?.guidanceTypeLabel}
                    </Text>
                  </Flex>
                  <RadioGroup
                    value={jsonObj?.controlnet_type}
                    isrequired="true"
                    color="#fff"
                    isDisabled
                  >
                    <Radio value="canny" mr="4" color="#fff">
                      Detailed Model
                    </Radio>
                    <Radio value="lineart" mr="4" color="#fff">
                      Soft Line Art
                    </Radio>
                    <Radio value="sketch" mr="4" color="#fff">
                      Rough Sketch
                    </Radio>
                    <Radio value="depth" color="#fff">
                      Massing Model
                    </Radio>
                  </RadioGroup>
                </Box>
              )}
              <Box p="2">
                <Flex justifyContent={"space-between"}>
                  <Tooltip
                    placement="left-end"
                    label={tooltipConstants?.speedQualityLabel}
                  >
                    <Text cursor={"pointer"} fontWeight={"600"} color="#fff">
                      {titleConstants?.speedQualityLabel}{" "}
                      {jsonObj?.num_inference_steps}
                    </Text>
                  </Tooltip>
                </Flex>
                <Slider
                  isDisabled={true}
                  min={2}
                  max={12}
                  step={1}
                  defaultValue={4}
                  value={jsonObj?.num_inference_steps}
                >
                  <SliderTrack bg="#fff">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack
                      bg={
                        jsonObj?.num_inference_steps > 8
                          ? "red.500"
                          : defaultThemeColor
                      }
                    />
                  </SliderTrack>
                  <SliderThumb boxSize={3} />
                </Slider>
              </Box>
              <Box p="2">
                <Flex justifyContent={"space-between"}>
                  <Tooltip
                    placement="left-end"
                    label={tooltipConstants?.promptStrengthLabel}
                  >
                    <Text cursor={"pointer"} fontWeight={"600"} color="#fff">
                      {titleConstants?.promptStrengthLabel}{" "}
                      {jsonObj?.guidance_scale}
                    </Text>
                  </Tooltip>
                </Flex>
                <Slider
                  min={1.0}
                  max={2.0}
                  step={0.1}
                  defaultValue={1.2}
                  value={jsonObj?.guidance_scale}
                  isDisabled={true}
                >
                  <SliderTrack bg="#fff">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack bg={defaultThemeColor} />
                  </SliderTrack>
                  <SliderThumb boxSize={3} />
                </Slider>
              </Box>
              {/* Todo:Just Commented Image Sharpness Can be used in future so can uncommend */}
              {/* {jsonObj?.alpha_sharpness ? (
                <Text color={"white"} mr="10px" p="10px">
                  {titleConstants?.imageSharpnessLabel}: {jsonObj?.alpha_sharpness}
                </Text>
              ) : null} */}
              {jsonObj?.model_sub_name === "controlnet" ||
              jsonObj?.model_sub_name === "inpaint_controlnet" ? (
                <Box p="2">
                  <Flex justifyContent={"space-between"}>
                    <Tooltip
                      placement="left-end"
                      label={tooltipConstants?.controlStrengthLabel}
                    >
                      <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                        {titleConstants?.controlStrengthLabel}{" "}
                        {jsonObj?.controlnet_conditioning_scale}
                      </Text>
                    </Tooltip>
                  </Flex>
                  <Slider
                    isDisabled={true}
                    min={0.0}
                    max={1.0}
                    step={0.1}
                    defaultValue={0.8}
                    value={jsonObj?.controlnet_conditioning_scale}
                  >
                    <SliderTrack bg="#fff">
                      <Box position="relative" right={10} />
                      <SliderFilledTrack bg={defaultThemeColor} />
                    </SliderTrack>
                    <SliderThumb boxSize={3} />
                  </Slider>
                </Box>
              ) : null}
            </Box>
            <Box w="50%" alignItems={"center"} key={"right"}>
              {jsonObj?.model_sub_name === "text_to_image" && (
                <>
                  <Box p="2">
                    <Flex justifyContent={"space-between"}>
                      <Tooltip
                        placement="left-end"
                        label={tooltipConstants?.imageWidthLabel}
                      >
                        <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                          Image Width {jsonObj?.width}
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Slider
                      isDisabled={true}
                      min={512}
                      max={1536}
                      step={64}
                      defaultValue={1024}
                      value={jsonObj?.width}
                    >
                      <SliderTrack bg="#fff">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={defaultThemeColor} />
                      </SliderTrack>
                      <SliderThumb boxSize={3} />
                    </Slider>
                  </Box>
                  <Box p="2">
                    <Flex justifyContent={"space-between"}>
                      <Tooltip
                        placement="left-end"
                        label={tooltipConstants?.imageHeightLabel}
                      >
                        <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                          Image Height {jsonObj?.height}
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Slider
                      isDisabled={true}
                      min={512}
                      max={1536}
                      step={64}
                      defaultValue={1024}
                      value={jsonObj?.height}
                    >
                      <SliderTrack bg="#fff">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={defaultThemeColor} />
                      </SliderTrack>
                      <SliderThumb boxSize={3} />
                    </Slider>
                  </Box>
                </>
              )}
              {(
                jsonObj?.model_sub_name === "diffedit" ||
                jsonObj?.model_sub_name === "inpaint_controlnet"
              ) && (
                <>
                  <Box p="2">
                    <Flex justifyContent={"space-between"}>
                      <Tooltip
                        placement="left-end"
                        label={tooltipConstants?.inpaintStrengthLabel}
                      >
                        <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                          {titleConstants?.inpaintStrengthLabel}{" "}
                          {jsonObj?.inpaint_strength}
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Slider
                      isDisabled={true}
                      min={0.1}
                      max={1.0}
                      step={0.1}
                      defaultValue={1.0}
                      value={jsonObj?.inpaint_strength}
                    >
                      <SliderTrack bg="#fff">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={defaultThemeColor} />
                      </SliderTrack>
                      <SliderThumb boxSize={3} />
                    </Slider>
                  </Box>
                  <Box p="2">
                    <Flex justifyContent={"space-between"}>
                      <Tooltip
                        placement="left-end"
                        label={tooltipConstants?.maskCropPaddingLabel}
                      >
                        <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                          {titleConstants?.maskCropPaddingLabel}{" "}
                          {jsonObj?.mask_crop_padding}
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Slider
                      isDisabled={true}
                      min={0.0}
                      max={1.0}
                      step={0.1}
                      defaultValue={0.6}
                      value={jsonObj?.mask_crop_padding}
                    >
                      <SliderTrack bg="#fff">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={defaultThemeColor} />
                      </SliderTrack>
                      <SliderThumb boxSize={3} />
                    </Slider>
                  </Box>
                </>
              )}
              {jsonObj?.images[0]?.regions?.some(
                (data) => data?.ref_image || data?.negative_ref_image
              ) && (
                <>
                  <Box p="2">
                    <Flex justifyContent={"space-between"}>
                      <Tooltip
                        placement="left-end"
                        label={tooltipConstants?.refAdainScaleLabel}
                      >
                        <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                          {titleConstants?.refAdainScaleLabel}{" "}
                          {jsonObj?.ref_adain_conditioning_scale}
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Slider
                      isDisabled={true}
                      min={0.0}
                      max={1.0}
                      step={0.1}
                      defaultValue={0.5}
                      value={jsonObj?.ref_adain_conditioning_scale}
                    >
                      <SliderTrack bg="#fff">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={defaultThemeColor} />
                      </SliderTrack>
                      <SliderThumb boxSize={3} />
                    </Slider>
                  </Box>
                  <Box p="2">
                    <Flex justifyContent={"space-between"}>
                      <Tooltip
                        placement="left-end"
                        label={tooltipConstants?.refAttnScaleLabel}
                      >
                        <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                          {titleConstants?.refAttnScaleLabel}{" "}
                          {jsonObj?.ref_attn_conditioning_scale}
                        </Text>
                      </Tooltip>
                    </Flex>
                    <Slider
                      isDisabled={true}
                      min={0.0}
                      max={1.0}
                      step={0.1}
                      defaultValue={0.5}
                      value={jsonObj?.ref_attn_conditioning_scale}
                    >
                      <SliderTrack bg="#fff">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg={defaultThemeColor} />
                      </SliderTrack>
                      <SliderThumb boxSize={3} />
                    </Slider>
                  </Box>
                </>
              )}
              {jsonObj?.seed !== null && (
                <Box p="2">
                  <Flex justifyContent={"space-between"}>
                    <Tooltip
                      placement="left-end"
                      label={tooltipConstants?.imageSeedLabel}
                    >
                      <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                        {titleConstants?.imageSeedLabel}
                      </Text>
                    </Tooltip>
                    <Text
                      borderRadius="10px"
                      fontSize="12px"
                      color="#fff"
                      p="1"
                      bg={greyBgColor}
                    >
                      {jsonObj?.seed}
                    </Text>
                    <Button
                      size="xs"
                      colorScheme="purple"
                      onClick={() => {
                        dispatch({
                          type: "SET_WORKFLOW_OBJECT",
                          payload: {
                            key: "imageSeed",
                            value: jsonObj?.seed,
                          },
                        });
                        dispatch(setTabFlag(0));
                      }}
                    >
                      Copy to Current Design
                    </Button>
                  </Flex>
                </Box>
              )}
            </Box>
          </HStack>
          <Flex p="2">
            <Text
              fontSize={"14px"}
              fontWeight={"500"}
              textTransform={"capitalize"}
              color="#fff"
              mr="2"
            >
              Negative Prompt
            </Text>
            <Text fontSize={"12px"} color="#fff">
              {jsonObj?.images?.[0]?.regions?.[0]?.negative_prompt || "-"}
            </Text>
          </Flex>
        </>
      );
    } else {
      return (
        <Box w="100%" alignItems={"center"} key={index}>
          <Text color={"white"} mr="10px" p="10px">
            Upscale Factor: {jsonObj?.upscale_factor}
          </Text>
          <Text color={"white"} mr="10px" p="10px" textTransform={"capitalize"}>
            Tiled Upscaling: {jsonObj?.tiled_upscaling ? "Enabled" : "Disabled"}
          </Text>
          <Text color={"white"} mr="10px" p="10px" textTransform={"capitalize"}>
            Images Per Prompt: {jsonObj?.num_images_per_prompt}
          </Text>
        </Box>
      );
    }
  };

  // Todo:Render Multi-region data
  const renderRegionMask = (data, outerIndex, index, isBackground) => {
    return (
      <>
        <Box
          w="100%"
          bg={greyBgColor}
          border={"1px"}
          borderColor={
            isBackground
              ? defaultThemeColor
              : `${maskRegionColors[index]}.500`
          }
          borderTopLeftRadius={"10px"}
          borderTopRightRadius={"10px"}
        >
          <Flex
            bg={
              isBackground
                ? defaultThemeColor
                : `${maskRegionColors[index]}.500`
            }
            h="8"
            px="2"
            borderRadius={"0px"}
            color="#fff"
            borderTopLeftRadius={"10px"}
            borderTopRightRadius={"10px"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text
              fontSize={"14px"}
              textTransform={"capitalize"}
              fontWeight={"600"}
            >
              {isBackground ? "Background" : `${maskRegionColors[index]}`}
            </Text>
            <Button
              size="xs"
              colorScheme="purple"
              onClick={() => {
                cloneRegionData(data, outerIndex, index, isBackground);
              }}
            >
              Copy To Current Design
            </Button>
          </Flex>
        </Box>
        <Box
          w="100%"
          p="10px"
          border="1px"
          borderColor={
            isBackground
              ? defaultThemeColor
              : `${maskRegionColors[index]}.500`
          }
          borderTop={"0px"}
          borderBottomLeftRadius={"10px"}
          borderBottomRightRadius={"10px"}
        >
          {!isBackground && (
            <VStack justifyContent={"space-between"}>
              <Flex>
                <Text fontSize="14px" color="#fff" fontWeight={"600"} mr="2">
                  Region Mask
                </Text>
                <Tooltip label={"Region Mask"}>
                  <Box mt="3px">
                    <AiFillQuestionCircle
                      cursor={"pointer"}
                      color="yellow"
                      size="16 "
                    />
                  </Box>
                </Tooltip>
              </Flex>
              <canvas
                ref={(element) => {
                  if (element) {
                    ctxRefs.current[outerIndex * maskRegionColors.length + index] =
                      element.getContext("2d");
                  }
                }}
                style={{
                  width: "100%",
                  height: "290px",
                  objectFit: "scale-down",
                }}
              />
            </VStack>
          )}
          {data?.ref_image?.length > 0 && (
            <VStack justifyContent={"space-between"}>
              <Flex>
                <Text fontSize="14px" color="#fff" fontWeight={"600"} mr="2">
                  Reference Image
                </Text>
                <Tooltip label={"Reference Image"}>
                  <Box mt="3px">
                    <AiFillQuestionCircle
                      cursor={"pointer"}
                      color="yellow"
                      size="16 "
                    />
                  </Box>
                </Tooltip>
              </Flex>
              <RenderImageUI image={data?.ref_image} />
            </VStack>
          )}
          <VStack justifyContent={"space-between"}>
            <Flex>
              <Text mr="2" fontSize="14px" color="#fff" fontWeight={"600"}>
                Prompt
              </Text>
              <Tooltip label={"Prompt"}>
                <Box mt="3px">
                  <AiFillQuestionCircle
                    cursor={"pointer"}
                    color="yellow"
                    size="16 "
                  />
                </Box>
              </Tooltip>
            </Flex>
            <Text color="#fff" border={"none"} borderRadius={"10px"} fontSize={"12px"}>
              {data?.prompt || "-"}
            </Text>
          </VStack>
        </Box>
      </>
    );
  };

  // Todo:Clone Configurations
  const storeConfigData = (data) => {
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "guidanceScale", value: data?.guidance_scale },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "imageSharpness", value: data?.alpha_sharpness },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "inferenceSteps", value: data?.num_inference_steps },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "guidanceType", value: data?.controlnet_type },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "conditionScale",
        value: data?.controlnet_conditioning_scale,
      },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "inpaintStrength", value: data?.inpaint_strength },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "maskCropPadding", value: data?.mask_crop_padding },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "refAdainScale",
        value: data?.ref_adain_conditioning_scale,
      },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "refAttnScale",
        value: data?.ref_attn_conditioning_scale,
      },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "imageWidth", value: data?.width },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "imageHeight", value: data?.height },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "imageSeed", value: data?.seed },
    });
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "generatedImageCount",
        value: data?.num_images_per_prompt,
      },
    });
    dispatch(setTabFlag(0));
    toast(
      toastFunctionToaster(
        "Configuration Settings Copied Successfully",
        "success"
      )
    );
  };

  // Todo:Clone Guidance Image
  const cloneGuidanceImage = (image, box) => {
    const sliceValue = image.slice(image.indexOf(".com") + 5);
    let objImageInfo = {
      object_info: sliceValue,
    };
    setupReferenceObject(objImageInfo)
      .then((res) => {
        if (res?.result && res?.data[0]?.reference_status) {
          dispatch({
            type: "SET_WORKFLOW_OBJECT",
            payload: {
              key: "control_guidance_image",
              value: res?.data?.[0]?.object?.[0]?.url,
            },
          });
          dispatch({
            type: "SET_WORKFLOW_OBJECT",
            payload: {
              key: "control_guidance_image_name",
              value: sliceValue,
            },
          });
          dispatch({
            type: "SET_WORKFLOW_OBJECT",
            payload: {
              key: "controlBoundingBox",
              value: box && box?.length === 4 ? box : null,
            },
          });
          dispatch(setTabFlag(0));
          toast(
            toastFunctionToaster(
              "Guidance Image Copied Successfully",
              "success"
            )
          );
        } else {
          toast(toastFunctionToaster("Something Went Wrong", "error"));
        }
      })
      .catch((err) => {
        toast(toastFunctionToaster("Something Went Wrong", "error"));
      });
  };

  // Todo:Clone Region Data
  const cloneRegionData = (data, outerIndex, index, isBackground) => {
    index = isBackground ? maskRegionColors.length : index;
    const refImageUrl = data?.ref_image;
    const workflowDetail = workflowDetails?.workflowObject;
    const cloneRefImage = [...workflowDetail?.reference_image];
    const cloneRefName = [...workflowDetail?.reference_name];
    const clonePromptData = [...workflowDetail?.promptData];
    const cloneMaskData = [...workflowDetail?.maskData];

    if (refImageUrl) {
      const slicedUrl = refImageUrl?.slice(refImageUrl?.indexOf(".com") + 5);
      setupReferenceObject({ object_info: slicedUrl })
        .then((res) => {
          if (res?.result && res?.data[0]?.reference_status) {
            cloneRefImage[index] = res?.data?.[0]?.object?.[0]?.url;
            cloneRefName[index] = slicedUrl;
            clonePromptData[index] = data?.prompt;
            cloneMaskData[index] = data?.mask;
          } else {
            toast(toastFunctionToaster("Something Went Wrong", "error"));
            return;
          }
        })
        .catch((err) => {
          toast(toastFunctionToaster("Something Went Wrong", "error"));
          return;
        });
    } else {
      cloneRefImage[index] = "";
      cloneRefName[index] = "";
      clonePromptData[index] = data?.prompt;
      cloneMaskData[index] = data?.mask;
    }

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "maskData", value: cloneMaskData },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "reference_image", value: cloneRefImage },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "reference_name", value: cloneRefName },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "promptData", value: clonePromptData },
    });
    dispatch(setTabFlag(0));
    toast(toastFunctionToaster("Region Data Copied Successfully", "success"));
  };

  const cloneAllRegionData = async (promptData, modelEntity) => {
    const useBackground =
      modelEntity === "controlnet" || modelEntity === "text_to_image";
    let refImageArr = ["", "", "", "", ""];
    let refNameArr = ["", "", "", "", ""];
    let promptArr = ["", "", "", "", ""];
    let maskData = [null, null, null, null, null];

    for (let index = 0; index < promptData.length; index++) {
      const data = promptData[index];
      const refImageUrl = data?.ref_image;
      const newIndex =
        useBackground && index === promptData.length - 1
          ? maskRegionColors.length
          : index;
      if (refImageUrl) {
        try {
          const slicedUrl = refImageUrl?.slice(
            refImageUrl?.indexOf(".com") + 5
          );
          const res = await setupReferenceObject({ object_info: slicedUrl });
          if (res?.result && res?.data[0]?.reference_status) {
            refImageArr[newIndex] = res?.data?.[0]?.object?.[0]?.url;
            refNameArr[newIndex] = slicedUrl;
            promptArr[newIndex] = data?.prompt;
            maskData[newIndex] = data?.mask;
          } else {
            toast(toastFunctionToaster("Something Went Wrong", "error"));
            return;
          }
        } catch (err) {
          toast(toastFunctionToaster("Something Went Wrong", "error"));
          return;
        }
      } else {
        promptArr[newIndex] = data?.prompt;
        maskData[newIndex] = data?.mask;
      }
    }

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "maskData", value: maskData },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "reference_image", value: refImageArr },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "reference_name", value: refNameArr },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "promptData", value: promptArr },
    });

    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: { key: "negativePrompt", value: promptData[0]?.negative_prompt },
    });

    dispatch(setTabFlag(0));

    toast(toastFunctionToaster("Region Data Copied Successfully", "success"));
  };

  return (
    <Box>
      {loading ? (
        <Flex alignItems="center" justifyContent="center" mt="10" h="60vh">
          <Box textAlign={"center"}>
            <Spinner size="xl" color="#fff" />
            <Text color={defaultThemeColor} fontWeight={"600"} mt="2">
              Loading Previous Design Data...
            </Text>
          </Box>
        </Flex>
      ) : (
        <Box
          h="calc(100vh - 100px)"
          overflow="auto"
          overflowX="hidden"
          mt="3"
          css={{
            "&::-webkit-scrollbar": {
              width: "5px",
            },
            "&::-webkit-scrollbar-track": {
              width: "5px",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#fff",
              borderRadius: "24px",
            },
          }}
        >
          {renderCardFlip()}
          {prevImageData?.length ? (
            <Box mt="4">
              <ResponsivePagination
                current={currentPage}
                total={totalPages}
                onPageChange={(pageNumber) => {
                  setCurrentPage(pageNumber);
                }}
              />
            </Box>
          ) : null}
        </Box>
      )}
    </Box>
  );
}

export default PreviousImageConfig;
