import React from "react";
import { useSelector } from "react-redux";
// Chakra Imports
import { Box, Text, Flex } from "@chakra-ui/react";
// Component Import
import MenuDetails from "./MenuDetails";
import AdjustSideMenu from "../Dashboard/AdjustSideMenu";

function SideMenu() {
  const sideMenuStatus = useSelector((store) => store.notificationHeader);
  const { sideMenuFlag } = sideMenuStatus;
  return (
    <Box>
      <Flex p="10px" alignItems="center" justifyContent="space-between">
        {sideMenuFlag ? (
          <Text fontSize="2rem" fontWeight="400" mb="0" mr="4" ml="2">
            Corbu
          </Text>
        ) : null}
        <AdjustSideMenu />
      </Flex>
      <Box mb="4" mt="4" w="90%" mx="auto">
        <MenuDetails></MenuDetails>
      </Box>
    </Box>
  );
}

export default SideMenu;
