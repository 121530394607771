import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Flex,
  Text,
  Box,
  Button,
  Modal,
  Progress,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  useToast,
} from "@chakra-ui/react";
import {
  defaultGreyColor,
  defaultThemeColor,
  greyBgColor,
} from "../../../../../constants";
import AdvanceConfig from "./AdvanceConfig/AdvanceConfig";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";

function BatchGeneration(props) {
  const toast = useToast();
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [sliderValue, setSliderValue] = useState(2);
  const [sliderPercentage, setSliderPercentage] = useState(null);
  const [generationCompleted, setGenerationCompleted] = useState(0);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const loopFlagRef = useRef(false);
  const playGroundDetails = useSelector((store) => store.workflowDesignHeader);
  const playGroundData = playGroundDetails?.workflowObject;

  useEffect(() => {
    const percentage = (generationCompleted / sliderValue) * 100;
    setSliderPercentage(percentage);
  }, [generationCompleted, sliderValue]);

  const startGeneration = async () => {
    handleSelectedConfig(selectedKeys);
    await props?.startGeneration();
    setGenerationCompleted((i) => i + 1);
  };

  useEffect(() => {
    if (isOpen) {
      loopFlagRef.current = false;
      setGenerationCompleted(0);
      setSelectedKeys([]);
    }
  }, [isOpen]);

  useEffect(() => {
    if (generationCompleted === sliderValue) {
      loopFlagRef.current = false;
      setGenerationCompleted(0);
      setSelectedKeys([]);
    } else if (loopFlagRef.current && generationCompleted > 0) {
      startGeneration();
    }
  }, [generationCompleted]);

  function getRandomNumber(min, max, step) {
    return Math.floor(Math.random() * (1 + (max - min) / step)) * step + min;
  }

  const handleSelectedConfig = (selectedKeys) => {
    selectedKeys.map((data) => {
      const randomNumber = getRandomNumber(data?.min, data?.max, data?.step);
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: { key: data?.key, value: randomNumber },
      });
    });
  };

  const resetData = () => {
    if (loopFlagRef.current) {
      toast(toastFunctionToaster("Batch Generation Aborted", "success"));
    }
    loopFlagRef.current = false;
    setGenerationCompleted(0);
    setSelectedKeys([]);
    onClose();
  };

  return (
    <>
      <Button
        onClick={onOpen}
        colorScheme="yellow"
        size="xs"
        isLoading={playGroundDetails?.workflowEngineLoader}
      >
        {" "}
        Batch Generation
      </Button>

      <Modal
        isOpen={isOpen}
        onClose={() => {
          resetData();
        }}
        closeOnOverlayClick={!loopFlagRef.current}
        closeOnEsc={!loopFlagRef.current}
      >
        <ModalOverlay />
        <ModalContent bg={greyBgColor}>
          <ModalHeader color="#fff">Batch Generation</ModalHeader>
          <ModalBody borderTopWidth={loopFlagRef.current ? "0px" : "1px"} borderColor={defaultGreyColor}>
            {loopFlagRef.current ? null : (
              <AdvanceConfig
                workflowType={props?.currentWorkflowType}
                modelEntity={props?.currentModelEntity}
                modelCode={props?.currentModelCode}
                type="true"
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
              />
            )}
          </ModalBody>

          <ModalFooter
            justifyContent={"space-between"}
            alignItems={"end"}
            borderTopWidth={"1px"}
            borderColor={defaultGreyColor}
          >
            {loopFlagRef.current ? (
              <Box w="70%" mr="2">
                <Flex justifyContent={"space-between"}>
                  <Text color="#fff">Generation Progress</Text>
                  <Text color="green.500">
                    {generationCompleted} / {sliderValue}
                  </Text>
                </Flex>
                <Progress
                  value={sliderPercentage}
                  colorScheme="green"
                  size="md"
                />
              </Box>
            ) : (
              <Box w="90%">
                <Text color="#fff">Generation Count</Text>
                <Slider
                  w="80%"
                  mr="10"
                  ml="2"
                  defaultValue={sliderValue}
                  min={2}
                  max={50}
                  onChange={(val) => setSliderValue(val)}
                >
                  <SliderTrack bg="#fff">
                    <SliderFilledTrack bg={defaultThemeColor} />
                  </SliderTrack>
                  <SliderThumb boxSize={6}>
                    <Text
                      position="absolute"
                      top="0px"
                      left="50%"
                      transform="translateX(-50%)"
                      zIndex="2"
                      fontWeight="normal"
                      color="#000"
                    >
                      {sliderValue}
                    </Text>
                  </SliderThumb>
                </Slider>
              </Box>
            )}

            {generationCompleted === sliderValue ? null : (
              <Button
                colorScheme="green"
                size="sm"
                mr={3}
                onClick={() => {
                  loopFlagRef.current = true;
                  startGeneration();
                }}
                isDisabled={
                  !playGroundData?.source_image?.length ||
                  (playGroundData?.modelEntity === "inpaint_controlnet" &&
                    !playGroundData?.control_guidance_image_name?.length) ||
                  loopFlagRef.current
                }
                isLoading={loopFlagRef.current}
              >
                Start
              </Button>
            )}
            <Button
              colorScheme="red"
              size="sm"
              onClick={() => {
                resetData();
              }}
            >
              {loopFlagRef.current ? "Abort" : "Close"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default BatchGeneration;
