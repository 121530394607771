// Chakra Imports
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Radio,
  RadioGroup,
  Stack,
  Heading,
  Icon,
  Text,
  Image,
  Spinner,
  useToast,
  Tabs,
  TabPanel,
  TabPanels,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";

// Component Imports
import CreateWorkflow from "../ProjectManagement/ProjectWorkflow/CreateWorkflow";
import EditWorkflow from "../EditWorkflow/EditWorkflow";
import HistoryWorkflow from "../ProjectManagement/ProjectWorkflow/HistoryWorkflow";
import LikedImage from "../ProjectManagement/DesignProjects/LikedImage/LikedImage";
import { renderWorkflowTitle, wizardTitles } from "../../../constants/Workflow";
import ResponsivePagination from "react-responsive-pagination";
import { defaultThemeColor, greyBgColor } from "../../../constants";
import { resetWorkflowData } from "../../../utils/resetWorkflowData";
import { toastFunctionToaster } from "../../../utils/toastFunction";

// Api Imports
import {
  CollectProjectWorkflows,
  DeleteProjectWorkflow,
} from "../../../services/projectTemplateService";
import AddProjectFolder from "../ProjectManagement/ProjectWorkflow/AddProjectFolder";

function Playground() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toast = useToast();
  const [loading, isLoading] = useState(true);
  const [projectId, setProjectId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterValue, setFilterValue] = useState(0);
  const [activeTab, setActiveTab] = useState(0);
  const [deleteAlert, isDeleteAlert] = useState(false);
  const [workFlowName, setWorkflowName] = useState(null);
  const [workflowToDelete, setWorkflowToDelete] = useState(null);
  const [fullProjectWorkflows, setFullProjectWorkflows] = useState([]);

  // Todo:Call loadworkflow function again when page is changed
  useEffect(() => {
    loadWorkflowData();
    resetWorkflowData(dispatch);
  }, [currentPage, filterValue]);

  const loadWorkflowData = () => {
    isLoading(true);
    let objBody = {
      project_uuid: "playground",
      records_per_page: 10,
      page_id: currentPage,
      filter_child_workflows: filterValue === 0,
      searchKey: "",
      searchValue: "",
      filterEnabled: "",
    };
    if (filterValue > 0) {
      objBody = {
        ...objBody,
        workflow_model_code: wizardTitles[filterValue - 1].modelCode,
        workflow_model_type: wizardTitles[filterValue - 1].modelEntity,
      };
    }
    isLoading(true);
    CollectProjectWorkflows(objBody)
      .then((res) => {
        isLoading(false);
        if (res?.data?.length > 0) {
          setFullProjectWorkflows(res.data[0].pagination_data);
          setTotalPages(res.data[0].pagination_data[0].total_pages);
          setProjectId(res.data[0].project_uuid);
        } else {
          setFullProjectWorkflows([]);
        }
      })
      .catch((err) => {
        isLoading(false);
        setFullProjectWorkflows([]);
      });
  };

  // Todo:Set Variables Globally
  const routeToPlayground = (data) => {
    navigate("/business/playgroundDesign", {
      state: {
        launcher: "relaunch",
        project_uuid: "",
        workflow_uuid: data.workflow_uuid,
        modelDetails: {
          modelCode: data.model_code,
          modelEntity: data.model_type,
        },
        object_name: data.info[0].object_name,
        object_url: data.info[0].url,
        workflow_state: "existing",
        workflow_name: data?.name,
      },
    });
  };

  // Todo:Render List
  const handlerenderList = (data) => {
    if (data) {
      loadWorkflowData();
    }
  };

  const renderProjectWorkflow = () => {
    if (fullProjectWorkflows?.[0]?.records?.length) {
      return fullProjectWorkflows[0]?.records?.map((data, index) => {
        return (
          <GridItem
            bg={"#151117"}
            borderRadius={"10px"}
            cursor={"pointer"}
            borderWidth={"0.5px"}
            borderColor={greyBgColor}
            key={index}
          >
            <Box>
              <Image
                src={data.info[0].url}
                height={"150px"}
                w={"100%"}
                objectFit={"cover"}
                borderTopRightRadius={"10px"}
                borderTopLeftRadius={"10px"}
                onClick={() => routeToPlayground(data)}
              ></Image>
            </Box>
            <Box>
              <Box p="10px" borderTopWidth={"0.5px"} borderColor={greyBgColor}>
                <Text
                  color="#fff"
                  fontWeight={"600"}
                  mb="1"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                  w="150px"
                  textAlign={"left"}
                >
                  {data?.name ? data?.name : "Untitled Workflow"}
                </Text>
                <Text color={defaultThemeColor} fontWeight={"600"} mb="1">
                  {renderWorkflowTitle(data.model_code, data.model_type)}
                </Text>

                <Text color="#fff">
                  Created:{" "}
                  <Text as="span" ml="4" color={defaultThemeColor}>
                    {data?.started}
                  </Text>
                </Text>
                <Text color="#fff">
                  Updated:{" "}
                  <Text as="span" ml="3" color="green.200">
                    {data?.updated}
                  </Text>
                </Text>
              </Box>
              <Flex
                p="10px"
                borderTopWidth={"0.5px"}
                borderColor={greyBgColor}
                textAlign={"center"}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Button
                  size={"xs"}
                  alignSelf={"end"}
                  colorScheme="green"
                  onClick={() => routeToPlayground(data)}
                >
                  Select Workflow
                </Button>
                <Flex
                  onClick={() => {
                    setWorkflowName(data?.name);
                  }}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  w="30%"
                >
                  <HistoryWorkflow
                    projectId={projectId}
                    workflowId={data?.workflow_uuid}
                    loadWorkflowData={loadWorkflowData}
                  />
                  <EditWorkflow
                    workflowData={data}
                    project_uuid={projectId}
                    workflowName={workFlowName}
                    renderList={handlerenderList}
                  />
                  <Button size={"xs"} colorScheme="red" ml="2">
                    <Icon
                      w={5}
                      h={5}
                      as={AiOutlineDelete}
                      onClick={() => {
                        isDeleteAlert(true);
                        setWorkflowToDelete(data?.workflow_uuid);
                      }}
                    />
                  </Button>
                </Flex>
              </Flex>
            </Box>
          </GridItem>
        );
      });
    } else {
      return (
        <Text fontSize="16px" color={defaultThemeColor}>
          No Workflows Found
        </Text>
      );
    }
  };

  const deleteWorkflowData = () => {
    if (workflowToDelete && projectId) {
      isDeleteAlert(false);
      let objBody = {
        project_uuid: projectId,
        workflow_uuid: workflowToDelete,
      };
      isLoading(true);
      DeleteProjectWorkflow(objBody)
        .then((res) => {
          setFullProjectWorkflows([]);
          if (res?.data?.length > 0) {
            loadWorkflowData();
            setCurrentPage(currentPage);
            isDeleteAlert(false);
            toast(toastFunctionToaster(res?.message, "success"));
          } else {
            isDeleteAlert(false);
            toast(toastFunctionToaster(res?.message, "error"));
          }
        })
        .catch((err) => {
          if (err) {
            isDeleteAlert(false);
            toast(toastFunctionToaster("Something Went Wrong", "error"));
          }
          isLoading(false);
        });
    }
  };

  return (
    <Box>
      <Flex
        borderBottomWidth={"0.5px"}
        borderColor={greyBgColor}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Flex alignItems={"center"} justifyContent={"space-between"} w="100%">
          <Heading color="#fff" fontWeight="600" fontSize="24px">
            Playground Project
          </Heading>
          <Flex>
            <Button
              size="md"
              colorScheme="blue"
              onClick={() => {
                setActiveTab(activeTab === 0 ? 1 : 0);
              }}
              isLoading={loading && !projectId}
            >
              {activeTab === 0 ? "Liked Images" : "Workflow Designs"}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Tabs
        w="100%"
        borderBottom={"0px"}
        mx="auto"
        onChange={setActiveTab}
        index={activeTab}
      >
        <TabPanels>
          <TabPanel p="0" h="100%">
            <Box>
              <CreateWorkflow projectDetails={null}></CreateWorkflow>
            </Box>
            {loading ? (
              <Flex
                alignItems="center"
                justifyContent="center"
                mt="10"
                h="50vh"
                w="100%"
              >
                <Spinner size="xl" color="#fff" />
              </Flex>
            ) : (
              <>
                <Box
                  h="calc(100vh - 350px)"
                  bg={"#151117"}
                  overflow="auto"
                  overflowX="hidden"
                  borderRadius={"10px"}
                  mt="3"
                  css={{
                    "&::-webkit-scrollbar": {
                      width: "4px",
                    },
                    "&::-webkit-scrollbar-track": {
                      width: "6px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      background: "#fff",
                      borderRadius: "24px",
                    },
                  }}
                >
                  <Flex
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    p="10px"
                    borderBottomWidth={"0.5px"}
                    borderBottomColor={greyBgColor}
                  >
                    <Text color="#fff" fontSize="20px">
                      Workflow Designs
                    </Text>
                    <Flex>
                      <Stack direction="row" color="#fff">
                        {wizardTitles.map((item, index) => (
                          <Button
                            size="sm"
                            colorScheme="purple"
                            onClick={(e) => {
                              setFilterValue(index + 1);
                            }}
                            isActive={filterValue === index + 1}
                          >
                            {item.label}
                          </Button>
                        ))}
                      </Stack>
                      <Button
                        colorScheme="yellow"
                        ml="2"
                        size="sm"
                        onClick={() => {
                          setFilterValue(0);
                        }}
                      >
                        Clear Filter
                      </Button>
                    </Flex>
                  </Flex>
                  <Grid
                    templateColumns="repeat(4, 1fr)"
                    gap={4}
                    p="10px"
                    pt="4"
                  >
                    {renderProjectWorkflow()}
                  </Grid>
                </Box>
                {fullProjectWorkflows?.length ? (
                  <Box mt="4">
                    <ResponsivePagination
                      current={currentPage}
                      total={totalPages}
                      onPageChange={(pageNumber) => {
                        setCurrentPage(pageNumber);
                      }}
                    />
                  </Box>
                ) : null}
              </>
            )}
          </TabPanel>
          <TabPanel p="0" h="100%">
            <LikedImage
              projectId={projectId}
              tabFlag={activeTab + 1}
              type={"playground"}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* Todo:Delete Workflow Popup */}
      <AlertDialog isOpen={deleteAlert}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Workflow
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure? The workflow and all associated data will be
              permanently deleted from storage!!
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={() => isDeleteAlert(false)} size="sm">
                Cancel
              </Button>
              <Button
                ml={3}
                size="sm"
                colorScheme="red"
                onClick={() => deleteWorkflowData()}
              >
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
}

export default Playground;
