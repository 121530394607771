// React Chakra Imports
import { useDispatch } from "react-redux";
import { CgAsterisk } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Input, Button, Text, Box, Flex } from "@chakra-ui/react";

// Component Imports
import { errorColor } from "../../../constants/index";
import { defaultThemeColor } from "../../../constants/index";
import { getEmailId } from "../../../utils/localStorageIndex";
import {
  setTopNotificationMessage,
  setTopNotoficationStatusColor,
} from "../../../store/actions/topNotificationActions";

// Api Services Imports
import {
  updateUserInfo,
  fetchPersonalInfo,
} from "../../../services/sharedServices";

function UpdateInformation(props) {
  const emaiId = getEmailId();
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const [updateInfoObj, setUpdateInfoObj] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    email: emaiId,
  });

  useEffect(() => {
    let objData = {
      email: emaiId,
    };
    fetchPersonalInfo(objData)
      .then((res) => {
        if (res.result) {
          setUpdateInfoObj(res.data[0]);
          isLoading(false);
        } else {
          isLoading(false);
        }
      })
      .catch((err) => {
        isLoading(false);
        if (err) {
        }
      });
  }, []);

  // on change update form value fields
  const onUpdateField = (e) => {
    const nextFormState = {
      ...updateInfoObj,
      [e.target.name]: e.target.value,
    };
    setUpdateInfoObj(nextFormState);
  };

  // form validation function
  const validateFieldForm = () => {
    let fields = updateInfoObj;
    let errors = {};
    let formIsValid = true;

    if (!fields["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "*First Name is required.";
    }
    if (!fields["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "*Last Name is required.";
    }

    if (!fields["phone_number"]) {
      formIsValid = false;
      errors["phone_number"] = "*Phone Number is required.";
    }
    setError(errors);
    return formIsValid;
  };

  const submitUserData = (event) => {
    event.preventDefault();
    if (validateFieldForm()) {
      updateInfoObj.email = emaiId;
      isLoading(true);
      updateUserInfo(updateInfoObj)
        .then((res) => {
          if (res.result) {
            isLoading(false);
            dispatch(setTopNotificationMessage(res?.message));
            dispatch(setTopNotoficationStatusColor("green"));
            props?.updateUserDetails(true);
          } else {
            isLoading(false);
            dispatch(setTopNotificationMessage(res?.message));
            dispatch(setTopNotoficationStatusColor("red"));
          }
        })
        .catch((err) => {
          isLoading(false);
          if (err) {
            dispatch(setTopNotificationMessage(err?.message));
            dispatch(setTopNotoficationStatusColor("red"));
          }
        });
    }
  };

  return (
    <Box p="20px" pt="10px">
      <Box mb="10px">
        <Flex>
          <Text mb="8px" color="#fff">
            First Name
          </Text>
          <CgAsterisk color={errorColor} />
        </Flex>
        <Input
          size="sm"
          borderRadius="10px"
          height={"50px"}
          border="none"
          fontSize="12px"
          borderColor="#494552"
          bg="#1c181e"
          color="#fff"
          value={updateInfoObj.first_name}
          name="first_name"
          onChange={onUpdateField}
          placeholder="Enter First Name"
        />
        <Text fontSize="sm" color={errorColor} mt="1">
          {error?.first_name}
        </Text>
      </Box>
      <Box mb="10px">
        <Flex>
          <Text mb="8px" color="#fff">
            Last Name
          </Text>
          <CgAsterisk color={errorColor} />
        </Flex>
        <Input
          size="sm"
          borderRadius="10px"
          height={"50px"}
          border="none"
          fontSize="12px"
          borderColor="#494552"
          bg="#1c181e"
          color="#fff"
          value={updateInfoObj.last_name}
          name="last_name"
          onChange={onUpdateField}
          placeholder="Enter Last Name"
        />
        <Text fontSize="sm" color={errorColor} mt="1">
          {error?.last_name}
        </Text>
      </Box>
      <Box mb="10px">
        <Flex>
          <Text mb="8px" color="#fff">
            Phone Number
          </Text>
          <CgAsterisk color={errorColor} />
        </Flex>
        <Input
          size="sm"
          borderRadius="10px"
          height={"50px"}
          border="none"
          fontSize="12px"
          borderColor="#494552"
          bg="#1c181e"
          color="#fff"
          value={updateInfoObj.phone_number}
          name="phone_number"
          onChange={onUpdateField}
          placeholder="Enter Phone Number"
        />
        <Text fontSize="sm" color={errorColor} mt="1">
          {error?.phone_number}
        </Text>
      </Box>

      <Box mb="10px">
        <Button
          size="md"
          variant="outline"
          fontWeight="500"
          fontSize="16px"
          w="100%"
          type="button"
          isDisabled={loading ? true : false}
          isLoading={loading ? true : false}
          bg={defaultThemeColor}
          border={"none"}
          onClick={(e) => {
            submitUserData(e);
          }}
        >
          Save{" "}
        </Button>
      </Box>
    </Box>
  );
}

export default UpdateInformation;
