// Chaka Imports
import React, { useState, useEffect } from "react";
import { Radio, RadioGroup } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineDelete, AiFillQuestionCircle } from "react-icons/ai";
import {
  Stack,
  Box,
  Button,
  Flex,
  Text,
  Textarea,
  Tooltip,
  VStack,
  Center,
  Icon,
  Image,
  Checkbox,
  useToast,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from "@chakra-ui/react";

// Component Imports
import AspectRatio from "./AspectRatio";
import PromptSearch from "./PromptSearch";
import ImageFromResource from "./ImageFromResource";
import PromptModal from "../../../../Prompt/PromptModal";
import PromptEnhance from "../../../../Prompt/PromptEnhance";
import ObjectUploader from "../../../../Playground/ObjectUploader";
import { maskRegionColors } from "../../../../../../constants/Workflow";
import { getEmailId } from "../../../../../../utils/localStorageIndex";
import tooltipConstants from "../../../../../../constants/TooltipConstant";
import { defaultThemeColor, greyBgColor } from "../../../../../../constants";
import { toastFunctionToaster } from "../../../../../../utils/toastFunction";
import {
  setImposeFlagData,
  setLineDrawFlag,
  setStabilityValue,
  setTabFlag,
} from "../../../../../../store/actions/workFlowAction";

// Api Imports
import { setupReferenceObject } from "../../../../../../services/projectTemplateService";

function BaseConfiguration({ workflowType, modelEntity, modelCode }) {
  const toast = useToast();
  const email = getEmailId();
  const dispatch = useDispatch();

  const workflowDetails = useSelector((store) => store.workflowDesignHeader);
  const workFlowDetailsData = workflowDetails?.workflowObject;
  const [currentMaskIndex, setCurrentMaskIndex] = useState(
    modelEntity === "controlnet" || modelEntity === "text_to_image"
      ? maskRegionColors.length
      : 0
  );
  const promptData = workFlowDetailsData?.promptData;
  const controlGuidedImage = workFlowDetailsData?.control_guidance_image;
  const referenceImage = workFlowDetailsData?.reference_image;
  const referenceObjName = workFlowDetailsData?.reference_name;
  const createObject = { resource_uuid: workFlowDetailsData?.resource_uuid };
  const [stability, setStability] = useState("false");

  const handleMaskSelect = (index) => {
    setCurrentMaskIndex(index);
  };

  useEffect(() => {
    setCurrentMaskIndex(
      modelEntity === "controlnet" || modelEntity === "text_to_image"
        ? maskRegionColors.length
        : 0
    );
  }, [workFlowDetailsData?.workflow_uuid]);

  // Todo:Update stability on store when updated
  useEffect(() => {
    dispatch(setStabilityValue(stability === "true" ? true : false));
  }, [stability]);

  // Upload Image hoc function
  const SupplementObjectHandler = (data, object_role) => {
    try {
      if (data !== null || data !== undefined) {
        let objBody = {
          email: email,
          object_info: data.key,
        };
        setupReferenceObject(objBody)
          .then((res) => {
            if (res?.data[0]?.object[0]?.url.length) {
              if (object_role === "reference") {
                dispatch({
                  type: "SET_WORKFLOW_OBJECT",
                  payload: {
                    key: "reference_image",
                    value: [
                      ...referenceImage.slice(0, currentMaskIndex),
                      res?.data[0]?.object[0]?.url,
                      ...referenceImage.slice(currentMaskIndex + 1),
                    ],
                  },
                });
                dispatch({
                  type: "SET_WORKFLOW_OBJECT",
                  payload: {
                    key: "reference_name",
                    value: [
                      ...referenceObjName.slice(0, currentMaskIndex),
                      res?.data[0]?.object[0]?.object_name,
                      ...referenceObjName.slice(currentMaskIndex + 1),
                    ],
                  },
                });
              }
              if (object_role === "controlGuidedImage") {
                dispatch({
                  type: "SET_WORKFLOW_OBJECT",
                  payload: {
                    key: "control_guidance_image",
                    value: res?.data[0]?.object[0]?.url,
                  },
                });
                dispatch({
                  type: "SET_WORKFLOW_OBJECT",
                  payload: {
                    key: "control_guidance_image_name",
                    value: res?.data[0]?.object[0]?.object_name,
                  },
                });
              }
            } else {
              toast(toastFunctionToaster(res?.message, "error"));
            }
          })
          .catch((err) => {
            // Show an error here...
          });
      }
    } catch (err) {
      // Handle the error here...
    }
  };

  // Remove Reference Image
  const WorkflowRemoveObject = (value) => {
    if (value === "controlGuidedImage") {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: { key: "control_guidance_image", value: "" },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: { key: "control_guidance_image_name", value: "" },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: { key: "controlBoundingBox", value: null },
      });
    }
    if (value === "reference") {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "reference_image",
          value: [
            ...referenceImage.slice(0, currentMaskIndex),
            "",
            ...referenceImage.slice(currentMaskIndex + 1),
          ],
        },
      });
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "reference_name",
          value: [
            ...referenceObjName.slice(0, currentMaskIndex),
            "",
            ...referenceObjName.slice(currentMaskIndex + 1),
          ],
        },
      });
    }
  };

  const handleImageLoad = (e) => {
    const { naturalWidth, naturalHeight } = e.target;
    dispatch({
      type: "SET_WORKFLOW_OBJECT",
      payload: {
        key: "controlAspectRatio",
        value: naturalWidth / naturalHeight,
      },
    });
  };

  // Set Image Resource Value
  const updateResourceImageDetails = (data, object_role) => {
    let obj = {
      key: data?.payload?.object_name,
    };
    SupplementObjectHandler(obj, object_role);
  };

  // Todo:Set Prompt Details From Prompt Search
  const setPromptDetailsBySearch = (data) => {
    if (data?.length) {
      dispatch({
        type: "SET_WORKFLOW_OBJECT",
        payload: {
          key: "promptData",
          value: [
            ...promptData.slice(0, currentMaskIndex),
            data,
            ...promptData.slice(currentMaskIndex + 1),
          ],
        },
      });
    }
  };

  return (
    <Box
      h="calc(100% - 3%)"
      overflow="auto"
      css={{
        "&::-webkit-scrollbar": {
          width: "4px",
        },
        "&::-webkit-scrollbar-track": {
          width: "6px",
        },
        "&::-webkit-scrollbar-thumb": {
          background: { greyBgColor },
          borderRadius: "24px",
        },
      }}
    >
      {/* Control Guided Image */}
      {workFlowDetailsData?.modelEntity === "inpaint_controlnet" ? (
        <Box mb="4">
          <Flex justifyContent={"space-between"}>
            <Flex alignItems={"center"}>
              <Text fontSize="14px" color="#fff" fontWeight={"600"} mr="2">
                Guidance Image
              </Text>
              <Tooltip label={"Guidance Image"}>
                <Box>
                  <AiFillQuestionCircle
                    cursor={"pointer"}
                    color="yellow"
                    size="16 "
                  />
                </Box>
              </Tooltip>
            </Flex>
            {workFlowDetailsData?.modelEntity === "inpaint_controlnet" &&
            controlGuidedImage?.length ? (
              <Box>
                <Checkbox
                  fontSize="14px"
                  colorScheme="green"
                  color="#fff"
                  isChecked={workflowDetails?.superImposeFlag}
                  onChange={(e) => {
                    dispatch(
                      setImposeFlagData(!workflowDetails?.superImposeFlag)
                    );
                  }}
                >
                  Superimpose Sketch
                </Checkbox>
              </Box>
            ) : null}
          </Flex>
          <Box>
            <Box w={"100%"} p={1}>
              {controlGuidedImage?.length === 0 ? (
                <VStack
                  w={"100%"}
                  mt={2}
                  rounded={"lg"}
                  bg={greyBgColor}
                  borderRadius={"10px"}
                >
                  <Center w={"100%"} h={"100%"} color="#fff">
                    <VStack w={"100%"} position="relative">
                      <Box w={"100%"}>
                        {workFlowDetailsData?.source_image?.length ? (
                          <>
                            <Box>
                              <Button
                                mt={2}
                                position={"absolute"}
                                left={"50%"}
                                top={"15%"}
                                transform={"translate(-50%, -50%)"}
                                zIndex={999}
                                size={"xs"}
                                colorScheme={"yellow"}
                                onClick={(e) => {
                                  dispatch(setLineDrawFlag(true));
                                  dispatch(setTabFlag(3));
                                }}
                              >
                                Line Draw
                              </Button>
                            </Box>
                            <Box>
                              <Button
                                mt={2}
                                position={"absolute"}
                                left={"50%"}
                                top={"32%"}
                                transform={"translate(-50%, -50%)"}
                                zIndex={999}
                                size={"xs"}
                                colorScheme={"yellow"}
                                onClick={(e) => {
                                  SupplementObjectHandler(
                                    {
                                      key: workFlowDetailsData?.object_name,
                                    },
                                    "controlGuidedImage"
                                  );
                                }}
                              >
                                Use Source Image
                              </Button>
                            </Box>
                          </>
                        ) : null}
                        <ObjectUploader
                          createObject={createObject}
                          objectUploaded={(data) =>
                            SupplementObjectHandler(data, "controlGuidedImage")
                          }
                          size={
                            workFlowDetailsData?.source_image?.length
                              ? "xs"
                              : "sm"
                          }
                          clipImage={workflowDetails?.copyToClip}
                        />
                        <ImageFromResource
                          objectRole={"controlGuidedImage"}
                          passResourceDetails={updateResourceImageDetails}
                          resourceId={workFlowDetailsData?.resource_uuid}
                          size={
                            workFlowDetailsData?.source_image?.length
                              ? "xs"
                              : "sm"
                          }
                        />
                      </Box>
                    </VStack>
                  </Center>
                </VStack>
              ) : (
                <VStack w={"100%"} align={"start"} position="relative" mt="2">
                  <Button
                    size={"xs"}
                    colorScheme="red"
                    onClick={() => WorkflowRemoveObject("controlGuidedImage")}
                    position="absolute"
                    right="0"
                    top="0"
                  >
                    <Icon w={5} h={5} as={AiOutlineDelete} />
                  </Button>
                  <Image
                    objectFit={"contain"}
                    onLoad={handleImageLoad}
                    src={controlGuidedImage}
                    width="100%"
                    height={"200px"}
                    borderRadius={"10px"}
                    bg={greyBgColor}
                  ></Image>
                </VStack>
              )}
            </Box>
            <Text
              ml="1"
              fontSize="10px"
              borderRadius="10px"
              color={defaultThemeColor}
            >
              Best results with image between 500px-1500px
            </Text>
          </Box>
        </Box>
      ) : null}
      {/* Image editing in sketch in render and erase and replace */}
      {workFlowDetailsData?.modelEntity === "inpaint_controlnet" ||
      workFlowDetailsData?.modelEntity === "diffedit"
        ? workFlowDetailsData?.modelCode === 5 && (
            <Box bg={greyBgColor} borderRadius={"10px"} mb="4">
              <Box p="2">
                <Flex justifyContent={"space-between"}>
                  <Tooltip
                    placement="left-end"
                    label={tooltipConstants?.inpaintStrengthLabel}
                  >
                    <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                      Creativity {workFlowDetailsData?.inpaintStrength}
                    </Text>
                  </Tooltip>
                </Flex>
                <Slider
                  onChange={(v) => {
                    dispatch({
                      type: "SET_WORKFLOW_OBJECT",
                      payload: { key: "inpaintStrength", value: v },
                    });
                  }}
                  min={0.05}
                  max={0.95}
                  step={0.05}
                  defaultValue={0.35}
                  value={workFlowDetailsData?.inpaintStrength}
                >
                  <SliderTrack bg="#fff">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack bg={defaultThemeColor} />
                  </SliderTrack>
                  <SliderThumb boxSize={3} />
                </Slider>
              </Box>
              <Box p="2">
                <Flex justifyContent={"space-between"}>
                  <Tooltip
                    placement="left-end"
                    label={tooltipConstants?.controlStrengthLabel}
                  >
                    <Text fontWeight={"600"} color="#fff" cursor={"pointer"}>
                      Resemblance {workFlowDetailsData?.conditionScale}
                    </Text>
                  </Tooltip>
                </Flex>
                <Slider
                  onChange={(v) => {
                    dispatch({
                      type: "SET_WORKFLOW_OBJECT",
                      payload: { key: "conditionScale", value: v },
                    });
                  }}
                  min={0.2}
                  max={1.6}
                  step={0.1}
                  defaultValue={0.6}
                  value={workFlowDetailsData?.conditionScale}
                >
                  <SliderTrack bg="#fff">
                    <Box position="relative" right={10} />
                    <SliderFilledTrack bg={defaultThemeColor} />
                  </SliderTrack>
                  <SliderThumb boxSize={3} />
                </Slider>
              </Box>
            </Box>
          )
        : null}
      {/* Aspect Ratio Component */}
      {workFlowDetailsData?.modelEntity === "text_to_image" ? (
        <>
          <Box bg={greyBgColor} borderRadius={"10px"} mb="4">
            <Text
              fontSize="14px"
              color="#fff"
              p="2"
              borderBottom={"1px"}
              borderBottomColor={"#d7d7d7"}
              fontWeight={"600"}
            >
              Image Generation Engine
            </Text>
            <RadioGroup
              onChange={(e) => {
                setStability(e);
                if (e === "true") {
                  handleMaskSelect(maskRegionColors.length);
                }
              }}
              value={stability}
              isrequired="true"
              p="2"
            >
              <Stack direction="row" color="#fff">
                <Radio value="false" color="#fff">
                  Corbu AI Engine
                </Radio>
                <Radio value="true" color="#fff">
                  Stability AI SD3
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>
          <Box mb="4">
            <AspectRatio useStabilityEngine={stability} />
          </Box>
        </>
      ) : null}
      <Box
        w="100%"
        bg={greyBgColor}
        border={"1px"}
        borderColor={
          currentMaskIndex === maskRegionColors.length
            ? defaultThemeColor
            : `${maskRegionColors[currentMaskIndex]}.500`
        }
        borderTopLeftRadius={"10px"}
        borderTopRightRadius={"10px"}
      >
        <Flex alignItems={"center"}>
          {workFlowDetailsData?.modelEntity === "controlnet" ||
          workFlowDetailsData?.modelEntity === "text_to_image" ? (
            <Button
              size={"sm"}
              bg={
                currentMaskIndex === maskRegionColors.length
                  ? defaultThemeColor
                  : greyBgColor
              }
              borderRadius={"0px"}
              color="#fff"
              _hover={{
                bg: defaultThemeColor,
                borderTopLeftRadius: "10px",
              }}
              onClick={() => {
                handleMaskSelect(maskRegionColors.length);
              }}
              borderTopLeftRadius={"10px"}
            >
              <Text
                fontSize={"14px"}
                textTransform={"capitalize"}
                fontWeight={"600"}
              >
                Background
              </Text>
            </Button>
          ) : null}
          {maskRegionColors.map((color, index) => (
            <Button
              key={color}
              size={"sm"}
              bg={currentMaskIndex === index ? `${color}.500` : greyBgColor}
              borderRadius={"0px"}
              color="#fff"
              _hover={{
                bg: `${color}.500`,
                borderTopLeftRadius:
                  workFlowDetailsData?.modelEntity !== "controlnet" &&
                  workFlowDetailsData?.modelEntity !== "text_to_image" &&
                  color === "red"
                    ? "10px"
                    : "0px",
              }}
              onClick={() => {
                handleMaskSelect(index);
              }}
              borderTopLeftRadius={
                workFlowDetailsData?.modelEntity !== "controlnet" &&
                workFlowDetailsData?.modelEntity !== "text_to_image" &&
                color === "red"
                  ? "10px"
                  : "0px"
              }
            >
              <Text
                fontSize={"14px"}
                textTransform={"capitalize"}
                fontWeight={"600"}
              >
                {color}
              </Text>
            </Button>
          ))}
          <Tooltip label={"Region Masking"}>
            <Box mt="3px">
              <AiFillQuestionCircle
                cursor={"pointer"}
                color="yellow"
                size="16 "
              />
            </Box>
          </Tooltip>
        </Flex>
      </Box>
      {/* Reference Image */}
      <Box
        p="10px"
        border="1px"
        borderColor={
          currentMaskIndex === maskRegionColors.length
            ? defaultThemeColor
            : `${maskRegionColors[currentMaskIndex]}.500`
        }
        borderTop={"0px"}
        borderBottomLeftRadius={"10px"}
        borderBottomRightRadius={"10px"}
      >
        <Box mb="4">
          <Flex justifyContent={"space-between"}>
            <Flex>
              <Text fontSize="14px" color="#fff" fontWeight={"600"} mr="2">
                Reference Image
              </Text>
              <Tooltip label={"Reference Image"}>
                <Box mt="3px">
                  <AiFillQuestionCircle
                    cursor={"pointer"}
                    color="yellow"
                    size="16 "
                  />
                </Box>
              </Tooltip>
            </Flex>
            {workFlowDetailsData?.source_image?.length &&
              (workFlowDetailsData?.modelEntity === "inpaint_controlnet" ||
                workFlowDetailsData?.modelEntity === "diffedit") && (
                <Box mb="4">
                  <Flex justifyContent={"space-between"}>
                    <Checkbox
                      colorScheme="blue"
                      color="#fff"
                      isChecked={
                        workFlowDetailsData?.reference_name[
                          currentMaskIndex
                        ] === workFlowDetailsData?.object_name
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          SupplementObjectHandler(
                            {
                              key: workFlowDetailsData?.object_name,
                            },
                            "reference"
                          );
                        } else {
                          WorkflowRemoveObject("reference");
                        }
                      }}
                    >
                      <Text fontSize="14px" color="#fff" fontWeight={"600"}>
                        Remove Object Mode
                      </Text>
                    </Checkbox>
                  </Flex>
                </Box>
              )}
          </Flex>
          <Box>
            <Box w={"100%"} p={1}>
              {referenceImage[currentMaskIndex]?.length === 0 ? (
                <VStack
                  w={"100%"}
                  mt={2}
                  rounded={"lg"}
                  bg={greyBgColor}
                  borderRadius={"10px"}
                >
                  <Center w={"100%"} h={"100%"} color="#fff">
                    <VStack w={"100%"} position="relative">
                      <Box w={"100%"}>
                        <ObjectUploader
                          createObject={createObject}
                          objectUploaded={(data) =>
                            SupplementObjectHandler(data, "reference")
                          }
                          clipImage={workflowDetails?.copyToClip}
                        />
                        <ImageFromResource
                          objectRole={"reference"}
                          passResourceDetails={updateResourceImageDetails}
                          resourceId={workFlowDetailsData?.resource_uuid}
                        />
                      </Box>
                    </VStack>
                  </Center>
                </VStack>
              ) : (
                <VStack w={"100%"} align={"start"} position="relative" mt="2">
                  <Button
                    size={"xs"}
                    colorScheme="red"
                    onClick={() => WorkflowRemoveObject("reference")}
                    position="absolute"
                    right="2"
                    top="2"
                  >
                    <Icon w={5} h={5} as={AiOutlineDelete} />
                  </Button>
                  <Image
                    objectFit={"contain"}
                    src={referenceImage[currentMaskIndex]}
                    width="100%"
                    height={"200px"}
                    borderRadius={"10px"}
                  ></Image>
                </VStack>
              )}
            </Box>
            <Text
              ml="1"
              fontSize="10px"
              borderRadius="10px"
              color={defaultThemeColor}
            >
              Best results with image between 500px-1500px
            </Text>
          </Box>
        </Box>
        {/* Prompt */}
        <Box>
          <Flex alignItems={"center"}>
            <Text mr="2" fontSize="14px" color="#fff" fontWeight={"600"}>
              Prompt
            </Text>
            <Flex justifyContent={"end"} alignItems={"center"}>
              <PromptEnhance
                promptTitleData={promptData[currentMaskIndex]}
                sendPromptDetails={setPromptDetailsBySearch}
              />
              <PromptModal promptTitleData={promptData[currentMaskIndex]} />
              <PromptSearch sendPromptDetails={setPromptDetailsBySearch} />
            </Flex>
          </Flex>

          <Textarea
            _focus={{
              boxShadow: "none",
            }}
            bg={greyBgColor}
            borderRadius={"10px"}
            type="text"
            w="100%"
            my="3"
            rows="5"
            placeholder="Enter prompt details here..."
            border={"none"}
            color="#fff"
            value={promptData[currentMaskIndex]}
            onChange={(e) => {
              dispatch({
                type: "SET_WORKFLOW_OBJECT",
                payload: {
                  key: "promptData",
                  value: [
                    ...promptData.slice(0, currentMaskIndex),
                    e.target.value,
                    ...promptData.slice(currentMaskIndex + 1),
                  ],
                },
              });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default BaseConfiguration;
