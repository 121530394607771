// React Imports
import { CgAsterisk } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Flex,
  Input,
  Text,
  Button,
  Select,
  useToast,
} from "@chakra-ui/react";

// Component Imports
import { stateList } from "../../../../constants/State";
import { countryList } from "../../../../constants/Country";
import { getEmailId } from "../../../../utils/localStorageIndex";
import { toastFunctionToaster } from "../../../../utils/toastFunction";
import {
  errorColor,
  themeFontSizeMediumHeading,
  defaultThemeColor,
} from "../../../../constants";
import {
  setTopNotificationMessage,
  setTopNotoficationStatusColor,
  setBusinessInfoStatus,
} from "../../../../store/actions/topNotificationActions";

// Service Api Imports
import {
  addBusinessData,
  updateBusinessData,
  fetchBusinessInformation,
} from "../../../../services/businessServices";

function AddBusinessForm(props) {
  const toast = useToast();
  const dispatch = useDispatch();
  const topTitleStatus = useSelector((store) => store.notificationHeader);
  const { businessInfoStatus } = topTitleStatus;
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const defaultStateChangeValue = "United States";
  const [stateValue, setStateValue] = useState(false);
  const [updateAction, setUpdateAction] = useState(false);
  const [selectedType, setSelectedType] = useState("");

  const [businessInfo, setBusinessInfo] = useState({
    business_name: "",
    business_url: "",
    address_line1: "",
    address_line2: "",
    city: "",
    state: "",
    country: "",
    zip_code: 0,
    phone: "",
    business_email: "",
    email: getEmailId(),
  });

  //   Todo:Fetch Business Details
  useEffect(() => {
    if (businessInfoStatus) {
      fetchBusinessDetails();
    }
  }, [businessInfoStatus]);

  useEffect(() => {
    if (props?.selectedDetailsType) setSelectedType(props?.selectedDetailsType);
  }, [props?.selectedDetailsType]);

  const fetchBusinessDetails = () => {
    var apiData = { email: getEmailId() };
    fetchBusinessInformation(apiData)
      .then((res) => {
        if (res.result && res.data[0] !== null && res.data.length) {
          let responseData = {
            business_uuid: res.data[0].business_uuid
              ? res.data[0].business_uuid
              : null,
            business_name: res.data[0].business_name,
            business_url: res.data[0].business_url,
            address_line1: res.data[0].address_line1,
            address_line2: res.data[0].address_line2,
            city: res.data[0].city,
            state: res.data[0].state,
            country: res.data[0].country,
            zip_code: res.data[0].zip_code,
            phone: res.data[0].phone,
            business_email: res.data[0].business_email,
            email: getEmailId(),
          };
          if (
            res.data[0].country !== undefined &&
            res.data[0].country &&
            res.data[0].country === defaultStateChangeValue
          ) {
            setStateValue(true);
          }
          res.data[0].business_uuid
            ? setUpdateAction(true)
            : setUpdateAction(false);
          isLoading(false);
          dispatch(setTopNotificationMessage(res?.message));
          dispatch(setTopNotoficationStatusColor("green"));
          setBusinessInfo(responseData);
          // setBusinessInfo(res.data[0]);
        } else {
          isLoading(false);
          dispatch(setTopNotificationMessage(res?.message));
          dispatch(setBusinessInfoStatus(false));
          dispatch(setTopNotoficationStatusColor("red"));
        }
      })
      .catch((err) => {
        isLoading(false);
        if (err) {
          dispatch(setTopNotificationMessage(err?.message));
          dispatch(setTopNotoficationStatusColor("red"));
        }
      });
  };

  //   Todo:Set Form Values
  const onUpdateField = (e) => {
    const nextFormState = {
      ...businessInfo,
      [e.target.name]: e.target.value,
    };

    setBusinessInfo(nextFormState);
  };

  const validateFieldForm = () => {
    let fields = businessInfo;
    let errors = {};
    let formIsValid = true;
    if (!fields["business_name"]) {
      formIsValid = false;
      errors["business_name"] = "*Business Name is required.";
    }
    setError(errors);
    return formIsValid;
  };

  //   Todo:Render Country list Data
  const renderCountryListData = countryList?.map((countryData, index) => (
    <option
      key={countryData.code}
      value={countryData.name}
      style={{ color: "black" }}
    >
      {countryData.name}
    </option>
  ));

  //   Todo:Render State list Data
  const renderStateListData = stateList?.map((state, index) => (
    <option key={state.code} value={state.name} style={{ color: "black" }}>
      {state.name}
    </option>
  ));

  const resetStateValue = (e) => {
    if (e.target.value === defaultStateChangeValue) {
      setStateValue(true);
    } else {
      setStateValue(false);
    }
  };

  const submitBusinessData = (event) => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      if (businessInfo && businessInfo.business_uuid !== null && updateAction) {
        updateBusinessData(businessInfo)
          .then((res) => {
            isLoading(false);
            if (res.result) {
              dispatch(setTopNotificationMessage(res?.message));
              dispatch(setTopNotoficationStatusColor("green"));
              props?.updateBusinessDetails(true);
              toast(toastFunctionToaster(res?.message, "success"));
            } else {
              dispatch(setTopNotificationMessage(res?.message));
              dispatch(setTopNotoficationStatusColor("red"));
              toast(toastFunctionToaster(res?.message, "error"));
            }
          })
          .catch((err) => {
            isLoading(false);
            if (err)
              dispatch(setTopNotificationMessage(err?.response?.data?.detail));
            dispatch(setTopNotoficationStatusColor("red"));
            toast(
              toastFunctionToaster(err?.response?.data?.detail[0]?.msg, "error")
            );
          });
      } else {
        addBusinessData(businessInfo)
          .then((res) => {
            isLoading(false);
            if (res.result) {
              dispatch(setTopNotificationMessage(res?.message));
              dispatch(setTopNotoficationStatusColor("green"));
              props?.updateBusinessDetails(true);
              // navigate("/business/information/BusinessDetails");
            } else {
              dispatch(setTopNotificationMessage(res?.message));
              dispatch(setTopNotoficationStatusColor("red"));
            }
          })
          .catch((err) => {
            isLoading(false);
            if (err)
              dispatch(setTopNotificationMessage(err?.response?.data?.detail));
            dispatch(setTopNotoficationStatusColor("red"));
          });
      }
    }
  };

  return (
    <Box p="20px" pt="10px">
      {selectedType === "basic_info" ? (
        <Box className="business_info">
          <Box mb="10px">
            <Flex>
              <Text mb="8px" color="#fff">
                Business Name:
              </Text>
              <CgAsterisk color={errorColor} />
            </Flex>
            <Input
              placeholder="Enter Business Name"
              size="sm"
              borderRadius="10px"
              height={"50px"}
              border="none"
              color="#fff"
              fontSize={themeFontSizeMediumHeading}
              borderColor="#494552"
              bg="#1c181e"
              value={businessInfo.business_name}
              name="business_name"
              onChange={onUpdateField}
              _placeholder={{ color: "white" }}
            />
            <Text fontSize="sm" color={errorColor} mt="1">
              {error?.business_name}
            </Text>
          </Box>
          <Box mb="10px">
            <Text mb="8px" color="#fff">
              Business URL:
            </Text>
            <Input
              placeholder="Enter Business URL"
              size="sm"
              borderRadius="10px"
              height={"50px"}
              border="none"
              color="#fff"
              fontSize={themeFontSizeMediumHeading}
              borderColor="#494552"
              bg="#1c181e"
              value={businessInfo.business_url}
              name="business_url"
              onChange={onUpdateField}
              _placeholder={{ color: "white" }}
            />
          </Box>
          <Box mb="10px">
            <Text mb="8px" color="#fff">
              Business Email:
            </Text>
            <Input
              placeholder="Enter Business Email"
              size="sm"
              borderRadius="10px"
              height={"50px"}
              border="none"
              color="#fff"
              fontSize={themeFontSizeMediumHeading}
              borderColor="#494552"
              bg="#1c181e"
              value={businessInfo.business_email}
              name="business_email"
              onChange={onUpdateField}
              _placeholder={{ color: "white" }}
            />
          </Box>
          <Box mb="10px">
            <Text mb="8px" color="#fff">
              {" "}
              Phone Number:
            </Text>
            <Input
              type="text"
              placeholder="Enter Phone Number"
              size="sm"
              borderRadius="10px"
              height={"50px"}
              border="none"
              color="#fff"
              fontSize={themeFontSizeMediumHeading}
              borderColor="#494552"
              bg="#1c181e"
              value={businessInfo.phone}
              name="phone"
              onChange={onUpdateField}
              _placeholder={{ color: "white" }}
            />
          </Box>
        </Box>
      ) : null}
      {selectedType === "address_info" ? (
        <Box className="business_add">
          <Box mb="10px">
            <Text mb="8px" color="#fff">
              {" "}
              Address Line 1:
            </Text>
            <Input
              placeholder="Enter Address Line 1"
              size="sm"
              borderRadius="10px"
              height={"50px"}
              border="none"
              color="#fff"
              fontSize={themeFontSizeMediumHeading}
              borderColor="#494552"
              bg="#1c181e"
              value={businessInfo.address_line1}
              name="address_line1"
              onChange={onUpdateField}
              _placeholder={{ color: "white" }}
            />
          </Box>
          <Box mb="10px">
            <Text mb="8px" color="#fff">
              {" "}
              Address Line 2:
            </Text>
            <Input
              placeholder="Enter Address Line 2"
              size="sm"
              borderRadius="10px"
              height={"50px"}
              border="none"
              color="#fff"
              fontSize={themeFontSizeMediumHeading}
              borderColor="#494552"
              bg="#1c181e"
              value={businessInfo.address_line2}
              name="address_line2"
              onChange={onUpdateField}
              _placeholder={{ color: "white" }}
            />
          </Box>

          <Box mb="10px">
            <Text mb="8px" color="#fff">
              {" "}
              Country:
            </Text>
            <Select
              value={businessInfo.country}
              name="country"
              placeholder="Select Country"
              size="sm"
              borderRadius="10px"
              height={"50px"}
              color="#fff"
              border={"none"}
              bg="#1c181e"
              fontSize={themeFontSizeMediumHeading}
              mr="10px"
              onChange={(e) => {
                onUpdateField(e);
                resetStateValue(e);
              }}
            >
              {renderCountryListData}
            </Select>
          </Box>
          <Box mb="10px">
            <Text mb="8px" color="#fff">
              {" "}
              State:
            </Text>
            {stateValue ? (
              <Select
                value={businessInfo.state}
                name="state"
                placeholder="Select State"
                size="sm"
                borderRadius="10px"
                height={"50px"}
                border="none"
                color="#fff"
                bg="#1c181e"
                fontSize={themeFontSizeMediumHeading}
                mr="10px"
                onChange={onUpdateField}
              >
                {renderStateListData}
              </Select>
            ) : (
              <Input
                placeholder="Enter State"
                size="sm"
                borderRadius="10px"
                height={"50px"}
                border="none"
                color="#fff"
                fontSize={themeFontSizeMediumHeading}
                borderColor="#494552"
                bg="#1c181e"
                value={businessInfo.state}
                name="state"
                onChange={onUpdateField}
                _placeholder={{ color: "white" }}
              />
            )}
          </Box>
          <Box mb="10px">
            <Text mb="8px" color="#fff">
              {" "}
              City:
            </Text>
            <Input
              placeholder="Enter City"
              size="sm"
              borderRadius="10px"
              height={"50px"}
              border="none"
              color="#fff"
              fontSize={themeFontSizeMediumHeading}
              borderColor="#494552"
              bg="#1c181e"
              value={businessInfo.city}
              name="city"
              onChange={onUpdateField}
              _placeholder={{ color: "white" }}
            />
          </Box>
          <Box mb="10px">
            <Text mb="8px" color="#fff">
              {" "}
              Zip Code:
            </Text>
            <Input
              type="number"
              placeholder="Enter Zip Code"
              size="sm"
              borderRadius="10px"
              height={"50px"}
              border="none"
              color="#fff"
              fontSize={themeFontSizeMediumHeading}
              borderColor="#494552"
              bg="#1c181e"
              value={businessInfo.zip_code}
              name="zip_code"
              onChange={onUpdateField}
              _placeholder={{ color: "white" }}
            />
          </Box>
        </Box>
      ) : null}
      <Button
        size="md"
        variant="outline"
        fontWeight="500"
        fontSize="16px"
        w="100%"
        type="button"
        isDisabled={loading ? true : false}
        isLoading={loading ? true : false}
        bg={defaultThemeColor}
        border={"none"}
        onClick={(e) => {
          submitBusinessData(e);
        }}
      >
        {businessInfoStatus ? "Update" : "Add"} Business
      </Button>
    </Box>
  );
}

export default AddBusinessForm;
