import axios from "./axios";
import { serviceUrls } from "./serviceUrls";

export const fetchProjListingServices = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getProjectList, data, config);
};

export const createProjectTemplate = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().addProjectTemplate, data, config);
};

export const updateProjectTemplateDetails = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateProjectContent, data, config);
};

export const updateProjectEntityStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().manageProjectStatus, data, config);
};

export const getProjectEntity = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getProjectContent, data, config);
};

export const CollectProjectObjects = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().collectProjectObjects, data, config);
};

export const CollectProjectWorkflows = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().collectProjectWorkflows,
    data,
    config
  );
};

export const CollectSharedWorkflows = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().collectSharedWorkflows, data, config);
};

export const SaveObjectsToProject = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().saveProjectObjects, data, config);
};

export const getProjectActiveWorkflow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().getProjectActiveWorkflow,
    data,
    config
  );
};

export const getShareGivenWorkflow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().shareProjectWorkflow, data, config);
};

export const getTeamMembersList = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().fetchTeamMembers, data, config);
};

export const getPlaygroundWorkflowInfo = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().getPlaygroundWorkflow, data, config);
};

export const setupReferenceObject = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().setupRefImageUrl, data, config);
};

export const DeleteProjectWorkflow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().deleteProjectWorkflow, data, config);
};

export const DeleteProjectWorkflowTree = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().DeleteProjectWorkflowTree,
    data,
    config
  );
};

export const UpdateProjectWorkflow = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateProjectWorkflow, data, config);
};

export const ProjectWorkflowGenerate = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().projectWorkflowGenerate,
    data,
    config
  );
};

export const CollectGenerateStatus = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().workflowGeneratedStatus,
    data,
    config
  );
};

export const CollectGeneratedData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().workflowGeneratedResult,
    data,
    config
  );
};

export const CollectPreviousData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().workflowCollectPrevResult,
    data,
    config
  );
};

export const LikeUpdateGeneratedImage = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().likeUpdateGeneratedImage,
    data,
    config
  );
};

export const fetchLikedImage = (data, config = null, axiosInstance = axios) => {
  return axiosInstance.post(serviceUrls().likedImage, data, config);
};

export const projectStatistics = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().projectStatistics, data, config);
};

export const workflowStatistics = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().workflowStatistics, data, config);
};

export const workflowActionStatistics = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(
    serviceUrls().workflowActionStatistics,
    data,
    config
  );
};

export const likedImageStatistics = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().likedImageStatistics, data, config);
};

export const unLikeSelectedImage = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().unlikeImage, data, config);
};

export const deleteResourceLabelData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().deleteLabel, data, config);
};

export const updateResourceLabelData = (
  data,
  config = null,
  axiosInstance = axios
) => {
  return axiosInstance.post(serviceUrls().updateLabel, data, config);
};
