// Chakra Imports
import LazyLoad from "react-lazyload";
import React, { useEffect, useState } from "react";
import { AiOutlineCopy } from "react-icons/ai";
import { useDispatch } from "react-redux";
import {
  Box,
  Image,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Tooltip,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

// Api Imports
import { setupReferenceObject } from "../../../../../services/projectTemplateService";
import { defaultThemeColor, greyBgColor } from "../../../../../constants";
import { setClipBoardValue } from "../../../../../store/actions/workFlowAction";
import { toastFunctionToaster } from "../../../../../utils/toastFunction";
import CopyImageToClipboard from "../../../../Custom/CopyImageToClipboard";

function RenderImageUI({ image }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const dispatch = useDispatch();
  const [imageUrl, setImageUrl] = useState("");
  const [imageDimensions, setImageDimensions] = useState({});
  const [modalWidth, setModalWidth] = useState(0);
  const { copyImageToClipboard } = CopyImageToClipboard(); //Custom Clipboard Function

  useEffect(() => {
    renderImageData();
  }, []);

  const renderImageData = () => {
    const slicedUrl = image.slice(image.indexOf(".com") + 5);
    let objImageInfo = {
      object_info: slicedUrl,
    };
    setupReferenceObject(objImageInfo)
      .then((res) => {
        if (res?.result && res?.data[0]?.reference_status) {
          setImageUrl(res?.data?.[0]?.object?.[0]?.url);
        } else {
          setImageUrl("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const copyToClipboard = () => {
    const slicedUrl = image.slice(image.indexOf(".com") + 5);
    if (slicedUrl?.length) {
      dispatch(setClipBoardValue(slicedUrl));
      copyImageToData(slicedUrl);
    }
  };

  // Todo:Custom Copy To Clipboard Hook
  const copyImageToData = async (imageName) => {
    try {
      await copyImageToClipboard(imageName);
      toast(toastFunctionToaster("Image copied to clipboard", "success"));
    } catch (error) {
      toast(toastFunctionToaster("Failed to copy image to clipboard", "error"));
    }
  };

  return (
    <>
      <Box h="290px" position="relative">
        {imageDimensions?.width && (
          <Box
            color="#fff"
            position={"absolute"}
            right="1"
            top="1"
            bg={greyBgColor}
            fontSize={"20px"}
            borderRadius={"10px"}
            zIndex={1}
            p="1"
          >
            <Tooltip label="Copy To Clipboard">
              <Box>
                <AiOutlineCopy
                  size="20px"
                  cursor="pointer"
                  color={defaultThemeColor}
                  onClick={() => {
                    copyToClipboard();
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        )}
        <Image
          h="100%"
          w="100%"
          cursor="pointer"
          objectFit="cover"
          src={imageUrl}
          borderBottomLeftRadius="10px"
          borderBottomRightRadius="10px"
          onLoad={(e) => {
            const height = e.target.naturalHeight;
            const width = e.target.naturalWidth;
            setImageDimensions({ height, width });
          }}
          onClick={onOpen}
        />
        {imageDimensions?.width && (
          <Box
            color="#fff"
            position="absolute"
            top="0"
            bg={greyBgColor}
            fontWeight="600"
            p="2"
          >
            {imageDimensions?.width} x{" "}
            {imageDimensions?.height}
          </Box>
        )}
      </Box>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="outside"
      >
        <ModalOverlay />
        <ModalContent maxW="80%" width={modalWidth} mt="5">
          <ModalCloseButton
            color="red.500"
            fontWeight="600"
            bg="#fff"
            top="0"
            right="0"
            zIndex="999"
          />
          <Box position={"relative"}>
            <LazyLoad height={"100%"} width={"100%"}>
              <Image
                src={imageUrl}
                alt="Image"
                objectFit="contain"
                height="95vh"
                bg="#0b090c"
                width="100%"
                onLoad={(e) => {
                  setModalWidth(e.target.naturalWidth);
                }}
              />
            </LazyLoad>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
}

export default RenderImageUI;
