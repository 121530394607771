// React Imports
import React, { useEffect, useState } from "react";
import { CgAsterisk } from "react-icons/cg";
import { Input, Button, Text, Box, useToast, Flex } from "@chakra-ui/react";

// Component Imports
import { errorColor } from "../../../../constants/index";
import { defaultThemeColor } from "../../../../constants/index";

// Api Imports
import {
  inviteBusinessUserDetails,
  updateBusinessUserDetails,
} from "../../../../services/businessServices";

function InviteBusinessUser(props) {
  const toast = useToast();
  const [error, setError] = useState({});
  const [loading, isLoading] = useState(false);
  const [businessUserId, setBusinessUserId] = useState(null);
  const [businessAdminForm, setBusinessAdminForm] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    user_email: "",
  });
  const [retypeBusinessAdminEmail, setRetypeBusinessAdminEmail] = useState("");
  const [retypeBusinessAdminEmailErr, setRetypeBusinessAdminEmailErr] =
    useState("");

  // Todo:Update Business Details
  useEffect(() => {
    if (props?.businessDetails !== null) {
      const businessData = props?.businessDetails;
      let obj = {
        first_name: businessData?.first_name,
        last_name: businessData?.last_name,
        phone_number: businessData?.phone_number,
        user_email: businessData?.email,
      };
      setBusinessUserId(businessData?.user_uuid);
      setBusinessAdminForm(obj);
      setRetypeBusinessAdminEmail(businessData?.email);
    }
  }, [props?.businessDetails]);

  const onUpdateField = (e) => {
    const nextFormState = {
      ...businessAdminForm,
      [e.target.name]: e.target.value,
    };
    setBusinessAdminForm(nextFormState);
  };

  const validateFieldForm = () => {
    let fields = businessAdminForm;
    let errors = {};
    let formIsValid = true;
    let emailPattern = new RegExp(
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    );
    if (!fields["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "*First Name is required.";
    }
    if (!fields["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "*Last Name is required.";
    }
    if (!fields["phone_number"]) {
      formIsValid = false;
      errors["phone_number"] = "*Phone Number is required.";
    }
    if (!businessUserId?.length) {
      if (!fields["user_email"]) {
        formIsValid = false;
        errors["user_email"] = "*User Email is required.";
      } else if (!emailPattern.test(fields["user_email"])) {
        formIsValid = false;
        errors["user_email"] = "*Please enter valid email address.";
      }
      if (!retypeBusinessAdminEmail) {
        formIsValid = false;
        setRetypeBusinessAdminEmailErr("*Email Cannot Be Empty.");
      } else if (fields["user_email"] !== retypeBusinessAdminEmail) {
        formIsValid = false;
        setRetypeBusinessAdminEmailErr("*Email didn't match.");
      }
    }
    setError(errors);
    return formIsValid;
  };

  const submitUserData = (event) => {
    event.preventDefault();
    if (validateFieldForm()) {
      isLoading(true);
      if (businessUserId?.length) {
        businessAdminForm.user_uuid = businessUserId;
        updateBusinessUserDetails(businessAdminForm)
          .then((res) => {
            setRetypeBusinessAdminEmailErr("");
            if (res.result) {
              isLoading(false);
              toast({
                title: res.message,
                status: "success",
                isClosable: true,
                duration: 3000,
              });
              props?.passSuccessFlag("true");
              // navigate("/business/bizmanagement/BizUsersListMgmt");
            } else {
              isLoading(false);
              toast({
                title: res.message,
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
          })
          .catch((err) => {
            isLoading(false);
          });
      } else {
        inviteBusinessUserDetails(businessAdminForm)
          .then((res) => {
            setRetypeBusinessAdminEmailErr("");
            if (res.result) {
              isLoading(false);
              toast({
                title: res.message,
                status: "success",
                isClosable: true,
                duration: 3000,
              });
              props?.passSuccessFlag("true");
              // navigate("/business/bizmanagement/BizUsersListMgmt");
            } else {
              isLoading(false);
              toast({
                title: res.message,
                status: "error",
                isClosable: true,
                duration: 3000,
              });
            }
          })
          .catch((err) => {
            isLoading(false);
          });
      }
    }
  };

  return (
    <Box p="20px" pt="10px">
      <Box mb="10px">
        <Flex>
          <Text mb="8px" color="#fff">
            First Name:
          </Text>
          <CgAsterisk color={errorColor} />
        </Flex>
        <Input
          placeholder="Enter First Name"
          size="sm"
          borderRadius="10px"
          height={"50px"}
          border="none"
          fontSize="12px"
          borderColor="#494552"
          bg="#1c181e"
          value={businessAdminForm.first_name}
          name="first_name"
          onChange={onUpdateField}
          _placeholder={{ color: "white" }}
          color="#fff"
          _hover={{ boxShadow: "none" }}
        />
        <Text fontSize="sm" color={errorColor} mt="1">
          {error?.first_name}
        </Text>
      </Box>
      <Box mb="10px">
        <Flex>
          <Text mb="8px" color="#fff">
            Last Name
          </Text>
          <CgAsterisk color={errorColor} />
        </Flex>
        <Input
          placeholder="Enter Last Name"
          size="sm"
          borderRadius="10px"
          height={"50px"}
          border="none"
          fontSize="12px"
          borderColor="#494552"
          bg="#1c181e"
          value={businessAdminForm.last_name}
          name="last_name"
          onChange={onUpdateField}
          _placeholder={{ color: "white" }}
          color="#fff"
          _hover={{ boxShadow: "none" }}
        />
        <Text fontSize="sm" color={errorColor} mt="1">
          {error?.last_name}
        </Text>
      </Box>
      <Box mb="10px">
        <Flex>
          <Text mb="8px" color="#fff">
            Phone Number
          </Text>
          <CgAsterisk color={errorColor} />
        </Flex>
        <Input
          placeholder="Enter Phone Number"
          size="sm"
          borderRadius="10px"
          height={"50px"}
          border="none"
          fontSize="12px"
          borderColor="#494552"
          bg="#1c181e"
          value={businessAdminForm.phone_number}
          name="phone_number"
          onChange={onUpdateField}
          _placeholder={{ color: "white" }}
          color="#fff"
          _hover={{ boxShadow: "none" }}
        />
        <Text fontSize="sm" color={errorColor} mt="1">
          {error?.phone_number}
        </Text>
      </Box>
      {businessUserId?.length ? null : (
        <>
          <Box mb="10px">
            <Flex>
              <Text mb="8px" color="#fff">
                Business User Email:
              </Text>
              <CgAsterisk color={errorColor} />
            </Flex>
            <Input
              placeholder="Enter User Email"
              size="sm"
              borderRadius="10px"
              height={"50px"}
              border="none"
              fontSize="12px"
              borderColor="#494552"
              bg="#1c181e"
              value={businessAdminForm.user_email}
              name="user_email"
              onChange={onUpdateField}
              _placeholder={{ color: "white" }}
              color="#fff"
              _hover={{ boxShadow: "none" }}
            />
            <Text fontSize="sm" color={errorColor} mt="1">
              {error?.user_email}
            </Text>
          </Box>
          <Box mb="10px">
            <Flex>
              <Text mb="8px" color="#fff">
                Verify User Email:
              </Text>
              <CgAsterisk color={errorColor} />
            </Flex>
            <Input
              placeholder="Re-Enter User Email"
              size="sm"
              borderRadius="10px"
              height={"50px"}
              border="none"
              fontSize="12px"
              borderColor="#494552"
              bg="#1c181e"
              value={retypeBusinessAdminEmail}
              name="retypeBusinessAdminEmail"
              onChange={(e) => {
                setRetypeBusinessAdminEmail(e.target.value);
              }}
              _placeholder={{ color: "white" }}
              color="#fff"
              _hover={{ boxShadow: "none" }}
            />
            <Text fontSize="sm" color={errorColor} mt="1">
              {retypeBusinessAdminEmailErr}
            </Text>
          </Box>
        </>
      )}
      <Button
        size="md"
        variant="outline"
        fontWeight="500"
        fontSize="16px"
        w="100%"
        onClick={(e) => {
          submitUserData(e);
        }}
        type="button"
        isDisabled={loading ? true : false}
        isLoading={loading ? true : false}
        bg={defaultThemeColor}
        border={"none"}
      >
        {businessUserId?.length ? "Update" : "Add"}
      </Button>
    </Box>
  );
}

export default InviteBusinessUser;
